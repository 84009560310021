import { all, call, fork, put, take, takeLeading } from "redux-saga/effects";
import { getType } from "typesafe-actions";

import { AnyAction } from "../actions";
import {
  doCompleteRegistration,
  doResolveTenantInviteDetails,
  doSetRegistrationStatus,
} from "../actions/registration";
import { RegistrationService } from "../lib/api/registration";
import { Invite, TenantInvite } from "../types/registration";
import { ITenant } from "../types/tenants";
import { RootState } from "./";

export enum RegistrationStatus {
  UNKNOWN = 0,
  CHECKING_INVITE = 1,
  VALID_INVITE = 2,
  REGISTERING = 3,
  REGISTERED = 4,

  INVALID_INVITE = 98,
  REGISTRATION_FAILED = 99,
}

interface RegistrationState {
  readonly status: RegistrationStatus;
  readonly tenantDetails?: ITenant;
  readonly invite?: Invite;
  readonly error?: Error;
}

const initialState: RegistrationState = {
  status: RegistrationStatus.UNKNOWN,
};

export const registrationReducer = (
  state: RegistrationState = initialState,
  action: AnyAction
): RegistrationState => {
  switch (action.type) {
    case getType(doSetRegistrationStatus):
      return Object.assign({}, state, { status: action.payload });

    case getType(doResolveTenantInviteDetails.success):
      return Object.assign({}, state, {
        status: RegistrationStatus.VALID_INVITE,
        tenantDetails: action.payload.tenant,
        invite: action.payload.invite,
      });

    case getType(doResolveTenantInviteDetails.failure):
      return {
        status: RegistrationStatus.INVALID_INVITE,
        error: action.payload,
      };

    case getType(doCompleteRegistration.failure):
      return {
        status: RegistrationStatus.REGISTRATION_FAILED,
        error: action.payload,
      };

    default:
      return state;
  }
};

export const selectRegistrationStatus = (state: RootState) =>
  state.registration.status;
export const selectInviteError = (state: RootState) => state.registration.error;
export const selectRegistrationTenantDetails = (state: RootState) =>
  state.registration.tenantDetails || undefined;
export const selectInvite = (state: RootState) => state.registration.invite;

function* resolveTenantInviteSaga(registrationAPI: RegistrationService) {
  function* handleResolveTenantInviteSaga(
    req: ReturnType<typeof doResolveTenantInviteDetails.request>
  ) {
    yield put(doSetRegistrationStatus(RegistrationStatus.CHECKING_INVITE));

    try {
      const res: TenantInvite = yield call(
        registrationAPI.resolveTenantInviteDetails,
        req.payload
      );
      yield put(doResolveTenantInviteDetails.success(res));
    } catch (err) {
      yield put(doResolveTenantInviteDetails.failure(err));
    }
  }

  function* watchResolveTenantInviteSaga() {
    yield takeLeading(
      getType(doResolveTenantInviteDetails.request),
      handleResolveTenantInviteSaga
    );
  }

  yield fork(watchResolveTenantInviteSaga);
}

function* completeRegistrationSaga(registrationAPI: RegistrationService) {
  function* handleCompleteRegistration(
    req: ReturnType<typeof doCompleteRegistration.request>
  ) {
    yield put(doSetRegistrationStatus(RegistrationStatus.REGISTERING));

    try {
      const res = yield call(registrationAPI.completeRegistration, req.payload);
      yield put(doCompleteRegistration.success(res));
    } catch (err) {
      yield put(doCompleteRegistration.failure(err));
    }
  }

  function* watchCompleteRegistration() {
    yield takeLeading(
      getType(doCompleteRegistration.request),
      handleCompleteRegistration
    );
  }

  function* watchCompleteRegistrationStatus() {
    while (true) {
      yield take(getType(doCompleteRegistration.success));
      yield put(doSetRegistrationStatus(RegistrationStatus.REGISTERED));
    }
  }

  yield all([
    fork(watchCompleteRegistration),
    fork(watchCompleteRegistrationStatus),
  ]);
}

export function* registrationSaga(registrationAPI: RegistrationService) {
  yield all([
    fork(resolveTenantInviteSaga, registrationAPI),
    fork(completeRegistrationSaga, registrationAPI),
  ]);
}
