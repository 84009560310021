import { List, Map } from "immutable";
import log from "loglevel";
import { all, call, fork, put, takeLatest } from "redux-saga/effects";
import { createSelector } from "reselect";
import { getType } from "typesafe-actions";

import { AnyAction } from "../actions";
import {
  doDownloadInvoice,
  doLoadAccountsListForUnit,
} from "../actions/account";
import { PortalService } from "../lib/api/portal";
import { IAccountEntry } from "../types/account";
import { RootState } from "./";

interface IAccountState {
  readonly unitAccounts?: Map<string, List<IAccountEntry>>;
  readonly loading: boolean;
  readonly error?: Error;
}

const initialState: IAccountState = {
  loading: false,
};

const mergeAccountEntries = (
  state: IAccountState,
  accounts: Array<IAccountEntry>
) => {
  let entriesMap = Map<string, List<IAccountEntry>>();

  accounts.forEach((accountEntry) => {
    entriesMap = entriesMap.set(
      accountEntry.unitCode,
      entriesMap
        .get(accountEntry.unitCode, List<IAccountEntry>())
        .push(accountEntry)
    );
  });

  return Object.assign({}, state, {
    error: undefined,
    loading: false,
    unitAccounts: state.unitAccounts
      ? state.unitAccounts.merge(entriesMap)
      : entriesMap,
  });
};

export const account = (
  state: IAccountState = initialState,
  action: AnyAction
) => {
  switch (action.type) {
    case getType(doLoadAccountsListForUnit.failure):
      return { error: action.payload, loading: false };
    case getType(doLoadAccountsListForUnit.request):
      return { loading: true };
    case getType(doLoadAccountsListForUnit.success):
      return mergeAccountEntries(state, action.payload);
    default:
      return state;
  }
};

export const selectAccountEntries = (state: RootState) =>
  state.account.unitAccounts;
export const selectAccountEntriesForUnit = (unitCode: string) =>
  createSelector(selectAccountEntries, (accountEntries) =>
    accountEntries
      ? accountEntries.get(unitCode, List<IAccountEntry>())
      : List<IAccountEntry>()
  );

function* downloadInvoiceSaga(portalAPI: PortalService) {
  function* handleDownloadInvoice(req: ReturnType<typeof doDownloadInvoice>) {
    try {
      const invoiceDetails = yield call(portalAPI.getInvoice, req.payload);
      if (invoiceDetails.downloadURL) {
        window.location.assign(invoiceDetails.downloadURL);
      }
    } catch (err) {
      log.error("While downloading invoice: " + err);
    }
  }

  function* watchDownloadInvoiceRequest() {
    yield takeLatest(getType(doDownloadInvoice), handleDownloadInvoice);
  }

  yield fork(watchDownloadInvoiceRequest);
}

function* loadAccountEntriesSaga(portalAPI: PortalService) {
  function* handleLoadAccountEntriesForUnitRequest(
    req: ReturnType<typeof doLoadAccountsListForUnit.request>
  ) {
    try {
      const res: Array<IAccountEntry> = yield call(
        portalAPI.listAccountsEntriesForUnit,
        req.payload
      );
      yield put(doLoadAccountsListForUnit.success(res));
    } catch (err) {
      yield put(doLoadAccountsListForUnit.failure(err));
    }
  }

  function* watchLoadAccountEntriesForUnit() {
    yield takeLatest(
      getType(doLoadAccountsListForUnit.request),
      handleLoadAccountEntriesForUnitRequest
    );
  }

  yield fork(watchLoadAccountEntriesForUnit);
}

export function* accountsSaga(portalAPI: PortalService) {
  yield all([
    fork(downloadInvoiceSaga, portalAPI),
    fork(loadAccountEntriesSaga, portalAPI),
  ]);
}
