import { Map } from "immutable";
import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { getType } from "typesafe-actions";

import { AnyAction } from "../actions";
import { doGetProperty } from "../actions/property";
import { doGetUnit } from "../actions/units";
import { PortalService } from "../lib/api/portal";
import { IProperty } from "../types/property";
import { RootState } from "./";
import { selectUnitByCode } from "./units";

interface IPropertiesState {
  readonly properties: Map<string, IProperty>;
  readonly loading: boolean;
  readonly error?: Error;
}

const initialState: IPropertiesState = {
  loading: false,
  properties: Map(),
};

export const propertiesReducer = (
  state: IPropertiesState = initialState,
  action: AnyAction
): IPropertiesState => {
  switch (action.type) {
    case getType(doGetProperty.failure):
      return Object.assign({}, state, {
        error: action.payload,
        loading: false,
      });
    case getType(doGetProperty.request):
      return Object.assign({}, state, { loading: true });
    case getType(doGetProperty.success):
      const property = action.payload;
      return Object.assign({}, state, {
        loading: false,
        properties: state.properties.set(property.code, property),
      });

    default:
      return state;
  }
};

function* getPropertySaga(portalAPI: PortalService) {
  function* handleGetPropertyRequest(
    req: ReturnType<typeof doGetProperty.request>
  ) {
    try {
      const res: IProperty = yield call(portalAPI.getProperty, req.payload);
      yield put(doGetProperty.success(res));
    } catch (err) {
      yield put(doGetProperty.failure(err));
    }
  }

  function* watchGetProperty() {
    yield takeEvery(getType(doGetProperty.request), handleGetPropertyRequest);
  }

  yield fork(watchGetProperty);
}

function* getPropertyOnUnitLoadSaga(portalAPI: PortalService) {
  function* handleDispatchLoadPropertyRequest(
    req: ReturnType<typeof doGetUnit.success>
  ) {
    yield put(doGetProperty.request(req.payload.propertyCode));
  }

  function* watchUnitLoads() {
    yield takeEvery(
      getType(doGetUnit.success),
      handleDispatchLoadPropertyRequest
    );
  }

  yield fork(watchUnitLoads);
}

export function* propertiesSaga(portalAPI: PortalService) {
  yield all([
    fork(getPropertySaga, portalAPI),
    fork(getPropertyOnUnitLoadSaga, portalAPI),
  ]);
}

export const selectPropertyByCode = (state: RootState, code: string) =>
  state.properties.properties
    ? state.properties.properties.get(code)
    : undefined;

export const selectPropertyByUnitCode = (
  state: RootState,
  unitCode: string
) => {
  const unit = selectUnitByCode(state, unitCode);
  if (!unit) {
    return undefined;
  }
  return selectPropertyByCode(state, unit.propertyCode);
};
