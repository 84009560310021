import { Button, CircularProgress, FormControlLabel } from "@material-ui/core";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle";
import { Checkbox, TextField } from "final-form-material-ui";
import React from "react";
import { Field, Form } from "react-final-form";
import { useDispatch, useSelector } from "react-redux";
import { Link as RouterLink } from "react-router-dom";

import { doLogin } from "../actions/auth";
import { selectIsLoggingIn, selectLoginError } from "../reducers/auth";
import { useCommonLoginLayoutStyles } from "./styles/login-page-layout.style";

export const LoginForm: React.FunctionComponent = () => {
  const loginError = useSelector(selectLoginError);

  type FormContext = {
    username: string;
    password: string;
    remember: boolean;
  };

  const initialValues = {
    username: "",
    password: "",
    remember: false,
  };

  const classes = useCommonLoginLayoutStyles();
  const isLoggingIn = useSelector(selectIsLoggingIn);

  const dispatch = useDispatch();

  const handleSubmit = (values: FormContext) => {
    dispatch(
      doLogin.request({
        username: values.username,
        password: values.password,
        persistent: !!values.remember,
      })
    );
  };

  return (
    <Form
      initialValues={initialValues}
      onSubmit={handleSubmit}
      render={({ handleSubmit, pristine, invalid }) => (
        <form onSubmit={handleSubmit} className={classes.form}>
          <Field
            name="username"
            component={TextField}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="username"
            label="Username"
            autoComplete="email"
            autoFocus
          />
          <Field
            name="password"
            component={TextField}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="password"
            label="Password"
            type="password"
            autoComplete="current-password"
          />
          <FormControlLabel
            control={
              <Field
                name="remember"
                color="primary"
                type="checkbox"
                component={Checkbox}
              />
            }
            label="Remember me"
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submitButton}
            disabled={isLoggingIn || pristine || invalid}
          >
            {isLoggingIn ? (
              <Container className={classes.submitButtonContainer}>
                <CircularProgress
                  className={classes.submitButtonSpinner}
                  size={20}
                />
                Signing in
              </Container>
            ) : (
              "Sign In"
            )}
          </Button>
          {loginError && (
            <Alert severity="error">
              <AlertTitle>{loginError.message}</AlertTitle>
              We were unable to log you in due to the above error.
            </Alert>
          )}
          <Grid container>
            <Grid item xs>
              <Link component={RouterLink} to="/forgot" variant="body2">
                Forgot password?
              </Link>
            </Grid>
          </Grid>
        </form>
      )}
    />
  );
};

export default LoginForm;
