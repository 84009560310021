import { Typography } from "@material-ui/core";
import { makeStyles, Theme } from "@material-ui/core/styles";
import React from "react";

import { usePortalAPI } from "../../lib/hooks";
import { ContactMessageDetails } from "../../types/contact";
import ContentBox from "../../widgets/content-box";
import { UnitPageLayout } from "../../widgets/unit-pages/unit-page-layout";
import { Props } from "./container";
import { ContactForm } from "./form";

const useClasses = makeStyles((theme: Theme) => ({
  formText: {
    marginBottom: theme.spacing(4),
  },
}));

export const ContactPage: React.FC<Props> = ({
  isSaving,
  loadTenantDetails,
  sendMessage,
  session,
  tenant,
  unit,
}) => {
  const classes = useClasses();
  const portalAPI = usePortalAPI();

  React.useEffect(() => {
    loadTenantDetails();
  }, [loadTenantDetails]);

  const [editor, setEditor] = React.useState(false);

  React.useEffect(() => {
    if (!tenant) {
      return;
    }

    const fetchTenantRoles = async () => {
      try {
        const roles = await portalAPI.getTenantRoles(tenant.code);
        setEditor(roles.editor);
      } catch (err) {
        setEditor(false);
      }
    };
    fetchTenantRoles();
  }, [portalAPI, setEditor, tenant]);

  if (!unit || !tenant) {
    return null;
  }

  const handleSubmit = (details: ContactMessageDetails) => {
    const msg = Object.assign(
      {
        unitCode: unit.code,
      },
      details
    );
    sendMessage(msg);
  };

  const useTenantDetailsByDefault = editor && !session?.adminUser;

  const initialData = {
    initialName: useTenantDetailsByDefault ? tenant.name : undefined,
    // Use the email address from the session where provided (this is the
    // address that is stored in the profile) but where that is not available,
    // use the address from the tenant profile.
    initialFromAddress:
      session?.emailAddress ||
      (useTenantDetailsByDefault ? tenant.contactDetails.emailMain : undefined),
    initialPhone: useTenantDetailsByDefault
      ? tenant.contactDetails.phoneDay
      : undefined,
  };

  return (
    <UnitPageLayout title="Contact Esskay" unitCode={unit.code}>
      <ContentBox title="Contact Information">
        <Typography className={classes.formText}>
          If you wish to send an email to your property manager, please complete
          the following form.
        </Typography>
        <ContactForm
          isSaving={isSaving}
          onSubmit={handleSubmit}
          {...initialData}
        />
      </ContentBox>
    </UnitPageLayout>
  );
};
