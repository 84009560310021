import Alert from "@material-ui/lab/Alert";
import queryString from "query-string";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import {
  doCompleteRegistration,
  doResolveTenantInviteDetails,
} from "../../actions/registration";
import {
  CompleteRegistrationRequest,
  InviteAuthDetails,
} from "../../lib/api/registration";
import { useRegistrationAPI } from "../../lib/hooks";
import LoginPageLayout, {
  LoginPageContainer,
} from "../../pages/login-page-layout";
import {
  RegistrationStatus,
  selectInvite,
  selectInviteError,
  selectRegistrationStatus,
  selectRegistrationTenantDetails,
} from "../../reducers/registration";
import { returnFirst } from "../../utils/query-string";
import { Props as RegisterFormProps, RegisterForm } from "./form";
import { CheckingInvite } from "./stateless";

type Props = {
  inviteID?: string;
};

export enum CheckUsernameStatus {
  UNKNOWN = 0,
  AVAILABLE = 1,
  UNAVAILABLE = 2,
  PENDING = 3,
}

const LoginPageError: React.FunctionComponent<{ message: string }> = ({
  message,
}) => {
  return <Alert severity="error">{message}</Alert>;
};

const GENERIC_LINK_ERROR = `Invalid invite link. If you believe this is in error, please contact Esskay
  Management Services for further advice.`;

const SelectorRegisterForm: React.FunctionComponent<
  InviteAuthDetails & Partial<RegisterFormProps>
> = ({ inviteID, inviteToken, ...props }) => {
  const dispatch = useDispatch();
  const registrationAPI = useRegistrationAPI();
  const authDetails = { inviteID, inviteToken };
  const tenantDetails = useSelector(selectRegistrationTenantDetails);
  const inviteDetails = useSelector(selectInvite);

  const handleFormSubmit = (values: Partial<CompleteRegistrationRequest>) => {
    dispatch(
      doCompleteRegistration.request(
        Object.assign(
          {},
          { authDetails },
          {
            emailAddress: values.emailAddress || "",
            username: values.username || "",
            password: values.password || "",
          }
        )
      )
    );
  };

  const handleCheckUsername = async function (proposedUsername: string) {
    try {
      await registrationAPI.checkUsername(authDetails, proposedUsername);
      return CheckUsernameStatus.AVAILABLE;
    } catch (err) {
      if (err.response && err.response.status === 409) {
        return CheckUsernameStatus.UNAVAILABLE;
      }
      throw err;
    }
  };

  return (
    <RegisterForm
      handleCheckUsername={handleCheckUsername}
      onSubmit={handleFormSubmit}
      invite={inviteDetails}
      tenant={tenantDetails}
      {...props}
    />
  );
};

const getInviteToken = (search: string) => {
  const tokens = queryString.parse(search).invite_token;
  if (!tokens) {
    return null;
  }

  return returnFirst(tokens);
};

// Selector determines the appropriate registration view to display after
// verifying the user's credentials are in order.
const Selector: React.FunctionComponent<Props> = ({ inviteID }) => {
  const dispatch = useDispatch();
  const status = useSelector(selectRegistrationStatus);
  const inviteError = useSelector(selectInviteError);
  const location = useLocation();
  const inviteToken = getInviteToken(location.search);

  useEffect(() => {
    if (!inviteID || !inviteToken) {
      return;
    }

    dispatch(doResolveTenantInviteDetails.request({ inviteID, inviteToken }));
  }, [inviteID, inviteToken, dispatch]);

  if (!inviteToken) {
    return <LoginPageError message={GENERIC_LINK_ERROR} />;
  }

  if (!inviteID) {
    return <LoginPageError message={GENERIC_LINK_ERROR} />;
  } else if (inviteError) {
    return <LoginPageError message={inviteError.message} />;
  }

  switch (status) {
    case RegistrationStatus.UNKNOWN:
    // fallthrough
    case RegistrationStatus.CHECKING_INVITE:
      return <CheckingInvite />;
    case RegistrationStatus.VALID_INVITE:
    case RegistrationStatus.REGISTERING:
    case RegistrationStatus.REGISTERED:
      const authDetails = { inviteID, inviteToken };
      return <SelectorRegisterForm status={status} {...authDetails} />;
    default:
      return null;
  }
};

export const RegistrationPage: React.FunctionComponent<Props> = (props) => {
  return (
    <LoginPageLayout headerText="Setup your profile">
      <LoginPageContainer
        headerText="Set up your profile"
        titleProps={{
          variant: "h3",
        }}
      >
        <Selector {...props} />
      </LoginPageContainer>
    </LoginPageLayout>
  );
};
