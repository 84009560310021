import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles, Theme } from "@material-ui/core/styles";
import CheckIcon from "@material-ui/icons/Check";
import React, { useEffect, useState } from "react";

const useSavingSpinnerStyles = makeStyles((theme: Theme) => ({
  decorationRoot: {
    display: "inline-block",
    verticalAlign: "middle",
    margin: `0 ${theme.spacing(1)}px`,
  },

  progressRoot: {
    verticalAlign: "middle",
  },
}));

interface SavingText {
  savingText: string;
  savedText: string;
}

type SavingProps = {
  isSaving: boolean;
  text?: SavingText;
};

export const SavingSpinner: React.FunctionComponent<SavingProps> = ({
  isSaving,
  text = { savingText: "Saving", savedText: "Saved" },
}) => {
  const classes = useSavingSpinnerStyles();
  const [wasSaving, setWasSaving] = useState(false);

  useEffect(() => {
    if (!isSaving && wasSaving) {
      setTimeout(() => {
        setWasSaving(false);
      }, 3000);
    } else if (isSaving !== wasSaving) {
      setWasSaving(isSaving);
    }
  }, [isSaving, wasSaving]);

  let decoration;
  if (isSaving) {
    decoration = (
      <>
        {`${text.savingText} `}
        <CircularProgress size={25} classes={{ root: classes.progressRoot }} />
      </>
    );
  } else if (wasSaving) {
    decoration = (
      <>
        {`${text.savedText} `}
        <CheckIcon classes={{ root: classes.progressRoot }} />
      </>
    );
  } else {
    return null;
  }

  return <div className={classes.decorationRoot}>{decoration}</div>;
};
