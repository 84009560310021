import Button from "@material-ui/core/Button";
import { makeStyles, Theme } from "@material-ui/core/styles";
import React from "react";

import PDFIcon from "../../assets/img/pdf-small.png";
import { Props } from "./container";

interface InvoiceButtonProps {
  id: string;
  navigateDownloadInvoice: Props["navigateDownloadInvoice"];
}

const useClasses = makeStyles((theme: Theme) => ({
  root: {
    minWidth: "inherit",
  },
}));

export const InvoiceButton: React.FunctionComponent<InvoiceButtonProps> = ({
  id,
  navigateDownloadInvoice,
}) => {
  const classes = useClasses();
  const handleClick = (
    evt: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    evt.preventDefault();
    navigateDownloadInvoice(id);
  };

  return (
    <Button
      classes={{
        root: classes.root,
      }}
      onClick={handleClick}
    >
      <img src={PDFIcon} alt="Load invoice - PDF icon" />
    </Button>
  );
};
