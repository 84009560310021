import { connect } from "react-redux";
import { Dispatch } from "redux";

import {
  doLoadTenantDetails,
  doUpdateContactDetails,
  doUpdateEmailDetails,
} from "../../actions/tenants";
import { RootState } from "../../reducers";
import { selectTenant, selectTenantUpdates } from "../../reducers/tenants";
import {
  ITenantContactDetailsUpdate,
  WithTenantCode,
} from "../../types/tenants";
import { IUnit } from "../../types/units";
import { ProfilePage } from "./page";

interface IOwnProps {
  unit: IUnit;
}

export type ITenantContactPreferences = {
  sendEmail: boolean;
  emailReceipts: boolean;
  emailCC?: Array<string>;
};

export type ITenantContactPreferencesUpdate = WithTenantCode<ITenantContactPreferences>;

const mapDispatchToProps = (dispatch: Dispatch, ownProps: IOwnProps) => {
  const { unit } = ownProps;

  const loadTenantDetails = () =>
    unit ? dispatch(doLoadTenantDetails.request(unit.tenantCode)) : undefined;

  return {
    loadTenantDetails,
    updateContactDetails: (details: ITenantContactDetailsUpdate) =>
      dispatch(doUpdateContactDetails.request(details)),
    updateContactPreferences: (details: ITenantContactPreferencesUpdate) =>
      dispatch(doUpdateEmailDetails.request(details)),
  };
};

const mapStateToProps = (state: RootState, ownProps: IOwnProps) => {
  const { unit } = ownProps;

  const tenant = unit ? selectTenant(unit.tenantCode)(state) : undefined;
  const savingDetails = selectTenantUpdates(state);

  return {
    tenant,
    updatingContactDetails: savingDetails.contactDetailsUpdating,
    updatingContactPreferences: savingDetails.emailsUpdating,
  };
};

export type Props = IOwnProps &
  ReturnType<typeof mapDispatchToProps> &
  ReturnType<typeof mapStateToProps>;

export const ProfileContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ProfilePage);
