import { ExtractMatchingObject } from "./util";

export type FilterableValue = string | number;
export type FilterableKeys<T> = keyof ExtractMatchingObject<T, FilterableValue>;

const normaliseStringForSearch = (s: string) =>
  s.replace(/[^A-Za-z0-9]/g, "").toLowerCase();
const normaliseValueForSearch = (s: FilterableValue): FilterableValue => {
  if (typeof s === "string") {
    return normaliseStringForSearch(s);
  }
  return s;
};

const filterValue = (s: FilterableValue, term: string) => {
  if (typeof s === "string") {
    return s.includes(term);
  }
  return false;
};

export function filterer<T extends ExtractMatchingObject<T, FilterableValue>>(
  filterFields: FilterableKeys<T>[],
  term: string
) {
  const normalisedTerm = normaliseStringForSearch(term);

  return (item: ExtractMatchingObject<T, FilterableValue>) => {
    if (term.length === 0) {
      return true;
    }
    return filterFields.some((field) =>
      filterValue(normaliseValueForSearch(item[field]), normalisedTerm)
    );
  };
}
