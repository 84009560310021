import { makeStyles, Theme } from "@material-ui/core";

export const useUnitSelectionStyles = makeStyles((theme: Theme) => ({
  heroContent: {
    textAlign: "center",
    padding: theme.spacing(8, 0, 6),

    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(2, 0, 2),
    },
  },

  unitList: {
    overflow: "hidden",
    overflowY: "auto",
  },

  unitListContainer: {},

  unitSearchWidget: {
    paddingBottom: theme.spacing(2),
    width: "100%",
  },
}));
