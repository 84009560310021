import { createAsyncAction } from "typesafe-actions";

import { IUnit, IUnits } from "../types/units";

export const doGetUnit = createAsyncAction(
  "ESSKAY/UNITS/GET/REQUEST",
  "ESSKAY/UNITS/GET/SUCCESS",
  "ESSKAY/UNITS/GET/ERROR"
)<string, IUnit, Error>();

export const doLoadUnits = createAsyncAction(
  "ESSKAY/UNITS/LIST/REQUEST",
  "ESSKAY/UNITS/LIST/SUCCESS",
  "ESSKAY/UNITS/LIST/ERROR"
)<void, IUnits, Error>();
