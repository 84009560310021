import Grid from "@material-ui/core/Grid";
import React from "react";
import { Form } from "react-final-form";

import {
  IContactDetails,
  ITenantAddressDetails,
  ITenantTelephoneDetails,
} from "../../../types/tenants";
import { Props } from "../container";
import {
  FormField,
  FormSectionTitle,
  SubmitWidget,
} from "./page-section-widget";

interface ContactDetailsProps {
  contactDetails: IContactDetails;
}

type ContactDetailsSectionProps = ContactDetailsProps & {
  disabled: boolean;
  isSaving: boolean;
  handleSave: Props["updateContactDetails"];
  tenantCode: string;
};

type FormContext = {
  address?: ITenantAddressDetails;
  emailMain?: string;
} & ITenantTelephoneDetails;

const firstToUpper = (s: string) =>
  s[0].toUpperCase() + s.slice(1).toLowerCase();

export const ContactDetailsSection: React.FunctionComponent<ContactDetailsSectionProps> = ({
  disabled,
  contactDetails,
  handleSave,
  isSaving,
  tenantCode,
}) => {
  if (!contactDetails) {
    return null;
  }

  const initialValues = {
    address: contactDetails.address,
    emailMain: contactDetails.emailMain,
    phoneDay: contactDetails.phoneDay,
    phoneEvening: contactDetails.phoneEvening,
    phoneMobile: contactDetails.phoneMobile,
  };

  const handleSubmit = (values: FormContext) => {
    // Undefined fields must be converted to strings explicitly to ensure the
    // underlying state management layer explicitly sends an empty string in
    // its posted updates. An undefined value will be omitted and will mean a
    // field that was previously populated will not be cleared as requested by
    // the user.

    const getAddressProperty = (name: keyof ITenantAddressDetails) =>
      values.address && values.address[name] ? values.address[name] : "";

    const address = {
      line1: getAddressProperty("line1"),
      line2: getAddressProperty("line2"),
      line3: getAddressProperty("line3"),
      line4: getAddressProperty("line4"),
      postalCode: getAddressProperty("postalCode"),
    };

    handleSave({
      code: tenantCode,
      address,
      emailMain: values.emailMain || "",
      telephone: {
        phoneDay: values.phoneDay || "",
        phoneEvening: values.phoneEvening || "",
        phoneMobile: values.phoneMobile || "",
      },
    });
  };

  return (
    <Form
      initialValues={initialValues}
      onSubmit={handleSubmit}
      render={({ handleSubmit, pristine, invalid }) => (
        <form onSubmit={handleSubmit}>
          <FormSectionTitle bold upper title="Contact Information" />
          <Grid container direction="column">
            <FormField
              gridProps={{ xs: 12, md: 8 }}
              name="emailMain"
              label="Email address"
              id="emailMain"
              disabled={disabled}
            />
          </Grid>
          <Grid container direction="row" spacing={2}>
            <Grid item xs={12} md={6}>
              <FormSectionTitle title="Correspondence Address" />
              <Grid container direction="column">
                {Array.from(Array(4).keys())
                  .map((i) => i + 1)
                  .map((i) => (
                    <FormField
                      name={`address.line${i}`}
                      label={`Line ${i}`}
                      id={`addressLine${i}`}
                      key={`addressLine${i}`}
                      disabled={disabled}
                    />
                  ))}
                <FormField
                  name="address.postalCode"
                  label="Postal code"
                  id="postalCode"
                  disabled={disabled}
                />
              </Grid>
            </Grid>
            <Grid container item xs={12} md={6}>
              <Grid container item direction="column" xs>
                <Grid item>
                  <FormSectionTitle title="Telephone Numbers" />
                  <Grid container direction="column">
                    {["mobile", "day", "evening"]
                      .map(firstToUpper)
                      .map((phoneType) => (
                        <FormField
                          name={`phone${phoneType}`}
                          label={`Phone (${phoneType})`}
                          id={`phone${phoneType}`}
                          key={`phone${phoneType}`}
                          disabled={disabled}
                        />
                      ))}
                  </Grid>
                </Grid>
                <Grid item xs></Grid>
                <Grid item>
                  <SubmitWidget
                    disabled={pristine || invalid || disabled}
                    inline
                    isSaving={isSaving}
                    label="Update"
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </form>
      )}
    />
  );
};
