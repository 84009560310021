import { TableCell, TableRow } from "@material-ui/core";
import { makeStyles, Theme } from "@material-ui/core/styles";
import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { selectTenants } from "../../reducers/tenants";
import { ITenant } from "../../types/tenants";
import ComplexTable, { SearchWidget } from "../../widgets/complex-table";
import { IHeadCell } from "../../widgets/complex-table/head-cell";

const headCells: IHeadCell<ITenant>[] = [
  { id: "code", label: "Search" },
  { id: "name", label: "Name" },
];

const useStyles = makeStyles((theme: Theme) => ({
  codeCell: {
    maxWidth: 30,
    width: "5%",
  },
}));

export type Props = {
  tenantSearchQuery: string;
  onTenantSearchQueryChange: (arg1: string) => void;
};

export const AdministrationTable: React.FunctionComponent<Props> = ({
  tenantSearchQuery,
  onTenantSearchQueryChange,
}) => {
  const classes = useStyles();
  const tenantsMap = useSelector(selectTenants);
  const tenants = tenantsMap ? Array.from(tenantsMap.values()) : [];

  return (
    <>
      <SearchWidget onChange={onTenantSearchQueryChange} />
      <ComplexTable<ITenant>
        headCells={headCells}
        filterFields={["code", "name"]}
        initialSort="code"
        initialSortDirection="asc"
        items={tenants}
        searchTerm={tenantSearchQuery}
      >
        {({ items }) => (
          <>
            {items.map((tenant, idx) => (
              <TableRow key={`tenant-${idx}`}>
                <TableCell className={classes.codeCell}>
                  <Link to={`/app/admin/tenant/${tenant.code}`}>
                    {tenant.code}
                  </Link>
                </TableCell>
                <TableCell>{tenant.name}</TableCell>
              </TableRow>
            ))}
          </>
        )}
      </ComplexTable>
    </>
  );
};
