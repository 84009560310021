// source of react-nl2br
// https://github.com/yosuke-furukawa/react-nl2br

import React from "react";

var newlineRegex = /(\r\n|\r|\n)/g;

export default function nl2br(str: string) {
  if (typeof str !== "string") {
    return str;
  }

  return str.split(newlineRegex).map(function (line, index) {
    if (line.match(newlineRegex)) {
      return React.createElement("br", { key: index });
    }
    return line;
  });
}
