import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import ChatBubbleIcon from "@material-ui/icons/ChatBubble";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CloseIcon from "@material-ui/icons/Close";
import DescriptionIcon from "@material-ui/icons/Description";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import HomeIcon from "@material-ui/icons/Home";
import LockIcon from "@material-ui/icons/Lock";
import MenuIcon from "@material-ui/icons/Menu";
import PersonIcon from "@material-ui/icons/Person";
import SupervisedUserCircleIcon from "@material-ui/icons/SupervisedUserCircle";
import classNames from "classnames";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, Route, RouteComponentProps, Switch } from "react-router";
import { useHistory } from "react-router-dom";

import { doLogout } from "./actions/auth";
import {
  useLoggedInAppStyles,
  useLogoStyles,
  useMobileViewHelper,
  useNavBarStyles,
  usePageTitleBarStyles,
} from "./app.style";
import SelectedUnitContainer from "./app/logged-in/selected-unit-container";
import logo from "./assets/img/logo.svg";
import BasePathContext, { useBasePath } from "./components/BasePath";
import { DataLastUpdated } from "./components/DataVersion";
import AdministrationContainer from "./containers/administration";
import ChangePasswordContainer from "./containers/change-password";
import UnitSelectionContainer from "./containers/unit-select";
import { selectUsername } from "./reducers/auth";
import { selectSessionAdminUser } from "./reducers/session";
import { selectUnits } from "./reducers/units";
import { PropsWithRouteProps } from "./types/router";

interface INavMenuProps {
  basePath: string;
  selectedUnit?: string;
  showUnitLinks: boolean;
}

interface NavMenuItem {
  key: string;
  icon: React.ComponentType;
  label: string;
  onClick: () => void;
  hidden?: boolean;
  onlyMultipleUnits?: boolean;
  onlyAdmin?: boolean;
  showWhenUnitNotSelected?: boolean;
}

const useLinks = (basePath: string): NavMenuItem[] => {
  const dispatch = useDispatch();
  const history = useHistory();
  const isMobile = useMobileViewHelper();

  const buildPath = (path: string) => {
    let builtPath = path;
    if (builtPath[0] === "/") {
      return builtPath;
    }
    if (basePath.slice(-1) === "/") {
      basePath = basePath.slice(0, -1);
    }
    return `${basePath}/${builtPath}`;
  };

  const onClickNavigateTo = (path: string) => () => {
    history.push(path);
  };

  const links = [
    {
      key: "home",
      icon: HomeIcon,
      label: "Home",
      onClick: onClickNavigateTo(basePath),
      showWhenUnitNotSelected: true,
    },
    {
      key: "administration",
      icon: SupervisedUserCircleIcon,
      label: "Administration",
      onClick: onClickNavigateTo("/app/admin"),
      onlyAdmin: true,
      showWhenUnitNotSelected: true,
    },
    {
      key: "unitSelect",
      icon: CheckCircleIcon,
      label: "Select different property",
      onClick: onClickNavigateTo("/app"),
      onlyMultipleUnits: true,
    },
    {
      key: "profile",
      icon: AccountCircleIcon,
      label: "Manage profile",
      onClick: onClickNavigateTo(buildPath("profile")),
    },
    {
      key: "change-password",
      icon: LockIcon,
      label: "Change password",
      onClick: onClickNavigateTo(buildPath("change-password")),
      showWhenUnitNotSelected: true,
    },
    {
      key: "account",
      icon: AccountBalanceIcon,
      label: "View account",
      onClick: onClickNavigateTo(buildPath("account")),
    },
    {
      key: "documents",
      icon: DescriptionIcon,
      label: "View documents",
      onClick: onClickNavigateTo(buildPath("documents")),
    },
    {
      key: "contact",
      icon: ChatBubbleIcon,
      label: "Contact Esskay",
      onClick: onClickNavigateTo(buildPath("contact")),
    },
    {
      key: "logout",
      icon: ExitToAppIcon,
      label: "Logout",
      hidden: !isMobile,
      onClick: () => {
        dispatch(doLogout());
      },
    },
  ];

  return links.filter((link) => !link.hidden);
};

const NavMenu: React.FC<INavMenuProps> = ({ basePath, showUnitLinks }) => {
  const classes = useNavBarStyles();
  const logoClasses = useLogoStyles();

  const isAdminUser = useSelector(selectSessionAdminUser);
  const hasMultipleUnits = (useSelector(selectUnits) || []).length > 1;
  const links = useLinks(basePath);

  const navFilter = (link: NavMenuItem) =>
    (showUnitLinks || link.showWhenUnitNotSelected) &&
    (!link.onlyMultipleUnits || hasMultipleUnits) &&
    (!link.onlyAdmin || isAdminUser);
  const navItems = links
    .filter(navFilter)
    .map(({ key, label, icon: Icon, onClick }) => {
      return (
        <ListItem button key={`nav-sidebar-item-${key}`} onClick={onClick}>
          <ListItemIcon>
            <Icon />
          </ListItemIcon>
          <ListItemText
            classes={{ root: classes.navItemLabel }}
            primaryTypographyProps={{
              variant: "body2",
            }}
          >
            {label}
          </ListItemText>
        </ListItem>
      );
    });

  return (
    <Grid item xs>
      <header className={logoClasses.logoContainer}>
        <img
          src={logo}
          alt="Esskay Property Management Services (logo)"
          height={50}
        />
      </header>
      <List component="nav" aria-label="Navigation menu">
        {navItems}
      </List>
    </Grid>
  );
};

interface PageTitleBarProps {
  drawerIcon: React.ReactNode;
  username?: string;
  text: string;
}

const PageTitleBar: React.FC<PageTitleBarProps> = ({
  drawerIcon,
  text,
  username,
}) => {
  const classes = usePageTitleBarStyles();
  const dispatch = useDispatch();
  const isMobileView = useMobileViewHelper();

  const handleLogout = () => {
    dispatch(doLogout());
  };

  return (
    <div
      className={classNames(
        classes.titleBar,
        isMobileView && classes.mobileHeader
      )}
    >
      {isMobileView ? (
        <>
          <div className={classes.drawerToggleButton}>{drawerIcon}</div>
          <div className={classes.logo}>
            <img
              src={logo}
              height={50}
              alt="Esskay Property Management Services (logo)"
            />
          </div>
        </>
      ) : (
        <>
          <Typography variant="h5" classes={{ root: classes.titleBarText }}>
            {text}
          </Typography>
          <div className={classes.usernameContainer}>
            <PersonIcon className={classes.usernamePersonIcon} />
            <Typography className={classes.usernameText} display="inline">
              {username || ""}
            </Typography>
          </div>
          <Button color="primary" variant="contained" onClick={handleLogout}>
            Logout
          </Button>
        </>
      )}
    </div>
  );
};

interface ILoggedInAppComponentProps {
  selectedUnit?: string;
}

type ILoggedInAppContainerProps = ILoggedInAppComponentProps & {
  basePath: string;
  titleBarText: string;
};

const LoggedInAppContainer: React.FunctionComponent<
  React.PropsWithChildren<
    ILoggedInAppContainerProps & ILoggedInAppComponentProps
  >
> = ({ basePath, children, selectedUnit, titleBarText }) => {
  const classes = useLoggedInAppStyles();
  const [expanded, setExpanded] = React.useState<boolean>(false);
  const username = useSelector(selectUsername);

  const shouldBeCollapsible = useMobileViewHelper();
  // If the screen is resized to be too small, always close the sidebar
  // irrespective of the old state.
  useEffect(() => {
    setExpanded(false);
  }, [shouldBeCollapsible]);

  const isExpanded = expanded && shouldBeCollapsible;

  const bodyClass = classNames(
    classes.bodyContainer,
    !shouldBeCollapsible && classes.mainFixed,
    // Only apply transitions on the appropriate sizes, to avoid a strange
    // invariant when the window is resized with the drawer open.
    shouldBeCollapsible && classes.drawerLeft,
    isExpanded && classes.slidingDrawerOpenLeft
  );

  const navClass = classNames(
    classes.nav,
    shouldBeCollapsible && classes.drawerLeft,
    shouldBeCollapsible && classes.navCollapsible,
    isExpanded && classes.slidingDrawerOpenLeft
  );

  const drawerIcon = shouldBeCollapsible && (
    <IconButton
      aria-label="Toggle navigation bar"
      edge="start"
      onClick={() => {
        setExpanded(!expanded);
      }}
    >
      {expanded ? <CloseIcon /> : <MenuIcon />}
    </IconButton>
  );

  return (
    <Grid container className={classes.root}>
      <Grid item className={navClass}>
        <NavMenu basePath={basePath} showUnitLinks={!!selectedUnit} />
        <DataLastUpdated />
      </Grid>
      <Grid item xs container direction="column" className={bodyClass}>
        <Grid item className={classes.pageTitleBar} component="header">
          <PageTitleBar
            drawerIcon={drawerIcon}
            text={titleBarText}
            username={username}
          />
        </Grid>
        <main className={classes.mainBody}>{children}</main>
        {shouldBeCollapsible && <DataLastUpdated />}
      </Grid>
    </Grid>
  );
};

const wrapLoggedInComponent = (
  Component: React.ComponentType<
    PropsWithRouteProps & ILoggedInAppComponentProps
  >,
  titleBarText: string
) => ({ match, ...props }: RouteComponentProps<ILoggedInAppRouteParams>) => {
  const basePath = useBasePath();

  return (
    <LoggedInAppContainer
      basePath={basePath}
      selectedUnit={match.params.selectedUnit}
      titleBarText={titleBarText}
    >
      <Component
        match={match}
        selectedUnit={match.params.selectedUnit}
        {...props}
      />
    </LoggedInAppContainer>
  );
};

interface ILoggedInAppRouteParams {
  selectedUnit?: string;
}

const ADMIN_TITLE = "Portal Administration";
const PORTAL_TITLE = "My Portal";

const LoggedInApp: React.FunctionComponent<RouteComponentProps> = ({
  match,
}) => {
  return (
    <BasePathContext.Provider value={match.url}>
      <Switch>
        <Route
          path={`${match.url}/admin`}
          component={wrapLoggedInComponent(
            AdministrationContainer,
            ADMIN_TITLE
          )}
        />
        <Route
          path={`${match.url}/:selectedUnit([A-z0-9]+)`}
          render={({ match, ...props }) => (
            <BasePathContext.Provider value={match.url}>
              <LoggedInAppContainer
                basePath={match.url}
                selectedUnit={match.params.selectedUnit}
                titleBarText={PORTAL_TITLE}
              >
                <SelectedUnitContainer
                  match={match}
                  selectedUnit={match.params.selectedUnit}
                  {...props}
                />
              </LoggedInAppContainer>
            </BasePathContext.Provider>
          )}
        />
        <Route
          path={`${match.url}/change-password`}
          component={wrapLoggedInComponent(
            ChangePasswordContainer,
            PORTAL_TITLE
          )}
        />
        <Route
          exact
          path={match.url}
          component={wrapLoggedInComponent(
            UnitSelectionContainer,
            PORTAL_TITLE
          )}
        />
        <Redirect to={`/app`} />
      </Switch>
    </BasePathContext.Provider>
  );
};

export default LoggedInApp;
