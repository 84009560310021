import CssBaseline from "@material-ui/core/CssBaseline";
import Grid, { GridTypeMap } from "@material-ui/core/Grid";
import Typography, { TypographyTypeMap } from "@material-ui/core/Typography";
import classNames from "classnames";
import React from "react";

import logo from "../assets/img/logo.svg";
import { useLoginPageLayoutStyles } from "./styles/login-page-layout.style";

const DEFAULT_HEADER_TEXT = "Log in to your profile";

const LoginPageLayout: React.FunctionComponent<
  React.PropsWithChildren<{
    headerText?: string;
  }>
> = ({ children, headerText = DEFAULT_HEADER_TEXT }) => {
  const classes = useLoginPageLayoutStyles();

  return (
    <Grid
      container
      className={classNames(classes.root, classes.image)}
      spacing={0}
      direction="column"
      alignItems="center"
      justify="center"
    >
      <CssBaseline />
      {children}
    </Grid>
  );
};

export default LoginPageLayout;

export const LoginPageContainer: React.FunctionComponent<
  React.PropsWithChildren<{
    gridProps?: GridTypeMap["props"];
    headerText?: string;
    titleProps?: TypographyTypeMap["props"];
    transparent?: boolean;
  }>
> = ({
  children,
  gridProps,
  headerText = DEFAULT_HEADER_TEXT,
  titleProps,
  transparent = false,
}) => {
  const classes = useLoginPageLayoutStyles();

  gridProps = Object.assign(
    {},
    {
      direction: "column",
      item: true,
      justify: "center",
      xs: "auto",
      md: 6,
      lg: 4,
    },
    gridProps
  );

  titleProps = Object.assign(
    {},
    {
      variant: "h5",
    },
    titleProps
  );

  return (
    <Grid container {...gridProps}>
      <Grid container>
        <Grid
          item
          xs
          className={classNames(classes.logoContainer, classes.containerBg)}
        >
          <img
            src={logo}
            alt="Esskay Property Management Services"
            className={classes.logo}
          />
        </Grid>
      </Grid>
      <Grid
        container
        item
        xs
        className={classNames(
          classes.bodyContent,
          classes.containerBg,
          transparent && classes.containerTransparent
        )}
      >
        <Typography component="h1" gutterBottom {...titleProps}>
          {headerText}
        </Typography>

        {children}
      </Grid>
    </Grid>
  );
};
