import { push } from "connected-react-router";
import { all, fork, put, takeLatest } from "redux-saga/effects";
import { createSelector } from "reselect";
import { getType } from "typesafe-actions";

import { AnyAction } from "../actions";
import { setSelectedUnit } from "../actions/portal";
import { PortalService } from "../lib/api/portal";
import { RootState } from "./";
import { unitsSaga } from "./units";

interface IPortalState {
  selectedUnit?: string;
}

const initialState: IPortalState = {};

export const portalReducer = (
  state: IPortalState = initialState,
  action: AnyAction
): IPortalState => {
  switch (action.type) {
    case getType(setSelectedUnit):
      return Object.assign({}, state, {
        selectedUnit: action.payload,
      });
    default:
      return state;
  }
};

export const selectSelectedUnit = (state: RootState) =>
  state.portal ? state.portal.selectedUnit : undefined;
export const selectIsUnitSelected = createSelector(
  selectSelectedUnit,
  (selectedUnit) => !!selectedUnit
);

function* selectUnitSaga() {
  function* handleSelectUnit({ payload }: ReturnType<typeof setSelectedUnit>) {
    yield put(push(`/app/${payload}`));
  }

  function* watchSelectUnit() {
    yield takeLatest(getType(setSelectedUnit), handleSelectUnit);
  }

  yield fork(watchSelectUnit);
}

export function* portalSaga(portalAPI: PortalService) {
  yield all([fork(selectUnitSaga), fork(unitsSaga, portalAPI)]);
}
