import Alert from "@material-ui/lab/Alert";
import React from "react";

import { ITenant } from "../../types/tenants";
import ContentBox from "../../widgets/content-box";
import { Props as ContainerProps } from "./container";
import { ContactDetailsSection } from "./sections/contact-details-section";
import { ContactPreferencesSection } from "./sections/contact-preferences-section";
import { OwnerSection } from "./sections/owner-section";

type Props = {
  editor: boolean;
  tenant: ITenant;
  updateContactDetails: ContainerProps["updateContactDetails"];
  updatingContactDetails: ContainerProps["updatingContactDetails"];
  updateContactPreferences: ContainerProps["updateContactPreferences"];
  updatingContactPreferences: ContainerProps["updatingContactPreferences"];
};

function toContactPreferences(tenant: ITenant) {
  return {
    sendEmail: tenant.sendEmail,
    emailReceipts: tenant.emailReceipts,
    emailCC: tenant.contactDetails.emailCC,
  };
}

export const ProfileBox: React.FunctionComponent<Props> = ({
  editor,
  tenant,
  updateContactDetails,
  updatingContactDetails,
  updateContactPreferences,
  updatingContactPreferences,
}) => (
  <ContentBox title="Profile">
    {!editor && <ReadOnlyAlert />}

    <OwnerSection tenantName={tenant.name} />

    <ContactDetailsSection
      contactDetails={tenant.contactDetails}
      handleSave={updateContactDetails}
      isSaving={updatingContactDetails}
      tenantCode={tenant.code}
      disabled={!editor}
    />

    <hr />

    <ContactPreferencesSection
      preferences={toContactPreferences(tenant)}
      handleSave={updateContactPreferences}
      isSaving={updatingContactPreferences}
      tenantCode={tenant.code}
      disabled={!editor}
    />
  </ContentBox>
);

const ReadOnlyAlert: React.FunctionComponent = () => (
  <Alert severity="warning">
    This profile is only permitted to view information on this page and changes
    cannot be made.
  </Alert>
);
