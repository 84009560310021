import { all, call, fork, put, takeLeading } from "redux-saga/effects";
import { getType } from "typesafe-actions";

import { AnyAction } from "../actions";
import { doSendManagerMessage } from "../actions/contact";
import { PortalService } from "../lib/api/portal";
import { RootState } from "./";

interface IContactManagerState {
  readonly sending: boolean;
  readonly error?: Error;
}

const initialState: IContactManagerState = {
  sending: false,
};

export const contactManagerReducer = (
  state: IContactManagerState = initialState,
  action: AnyAction
): IContactManagerState => {
  switch (action.type) {
    case getType(doSendManagerMessage.request):
      return { sending: true };
    case getType(doSendManagerMessage.success):
      return { sending: false };
    case getType(doSendManagerMessage.failure):
      return { sending: false, error: action.payload };
    default:
      return state;
  }
};

export const selectContactManager = (state: RootState) => state.contactManager;

function* contactManagerSaga(portalAPI: PortalService) {
  function* handleSendManagerMessage(
    req: ReturnType<typeof doSendManagerMessage.request>
  ) {
    try {
      yield call(portalAPI.sendManagerMessage, req.payload);
      yield put(doSendManagerMessage.success());
    } catch (err) {
      yield put(doSendManagerMessage.failure(err));
    }
  }

  function* watchContactManager() {
    yield takeLeading(
      getType(doSendManagerMessage.request),
      handleSendManagerMessage
    );
  }

  yield fork(watchContactManager);
}

export function* contactSaga(portalAPI: PortalService) {
  yield all([fork(contactManagerSaga, portalAPI)]);
}
