import { Map } from "immutable";
import log from "loglevel";
import { all, call, fork, put, takeLatest } from "redux-saga/effects";
import { createSelector } from "reselect";
import { getType } from "typesafe-actions";

import { AnyAction } from "../actions";
import {
  doDownloadDocument,
  doLoadDocumentsForProperty,
} from "../actions/documents";
import { PortalService } from "../lib/api/portal";
import { IDocument } from "../types/documents";
import { RootState } from "./";

// DocumentsMap is a map from property code -> document name -> document details
type DocumentsMap = Map<string, Map<string, IDocument>>;

interface IDocumentsState {
  readonly propertyDocuments: DocumentsMap;
  readonly loading: boolean;
  readonly error?: Error;
}

const initialState: IDocumentsState = {
  propertyDocuments: Map<string, Map<string, IDocument>>(),
  loading: false,
};

interface IDocumentNameParameters {
  propertyCode: string;
  documentID: string;
}

function getDocumentNameParameters(name: string): IDocumentNameParameters {
  const parts = name.split("/");
  if (parts.length !== 4) {
    throw new Error("Unexpected number of parts in document name");
  }

  return {
    propertyCode: parts[1],
    documentID: parts[3],
  };
}

const mergeDocuments = (state: IDocumentsState, docs: Array<IDocument>) => {
  let documentMap = Map<string, Map<string, IDocument>>();

  docs.forEach((doc) => {
    const nameParams = getDocumentNameParameters(doc.name);
    documentMap = documentMap.setIn(
      [nameParams.propertyCode, nameParams.documentID],
      doc
    );
  });

  return Object.assign({}, state, {
    loading: false,
    propertyDocuments: state.propertyDocuments.merge(documentMap),
  });
};

export const documents = (
  state: IDocumentsState = initialState,
  action: AnyAction
): IDocumentsState => {
  switch (action.type) {
    case getType(doLoadDocumentsForProperty.request):
      return Object.assign({}, state, { loading: true });

    case getType(doLoadDocumentsForProperty.failure):
      return Object.assign({}, state, {
        loading: false,
        error: action.payload,
      });

    case getType(doLoadDocumentsForProperty.success):
      try {
        return mergeDocuments(state, action.payload);
      } catch (err) {
        log.error("Updating documents: " + err);
        return state;
      }

    default:
      return state;
  }
};

export const selectDocuments = (state: RootState) =>
  state.documents.propertyDocuments;
export const selectDocumentsForProperty = (propertyCode: string) =>
  createSelector(
    selectDocuments,
    (documents: Map<string, Map<string, IDocument>>) =>
      documents.get(propertyCode) || Map()
  );
export const selectLoadingDocuments = (state: RootState) =>
  state.documents.loading;

function* loadDocumentsSaga(portalAPI: PortalService) {
  function* handleLoadDocumentsForPropertyRequest(
    req: ReturnType<typeof doLoadDocumentsForProperty.request>
  ) {
    try {
      const res: Array<IDocument> = yield call(
        portalAPI.listDocumentsForProperty,
        req.payload
      );
      yield put(doLoadDocumentsForProperty.success(res));
    } catch (err) {
      yield put(doLoadDocumentsForProperty.failure(err));
    }
  }

  function* watchLoadDocumentsForProperty() {
    yield takeLatest(
      getType(doLoadDocumentsForProperty.request),
      handleLoadDocumentsForPropertyRequest
    );
  }

  yield fork(watchLoadDocumentsForProperty);
}

export function* downloadDocumentSaga(portalAPI: PortalService) {
  function* handleDownloadDocument(req: ReturnType<typeof doDownloadDocument>) {
    try {
      const documentDetails = yield call(portalAPI.getDocument, req.payload);
      if (documentDetails.downloadURL) {
        window.location.assign(documentDetails.downloadURL);
      }
    } catch (err) {
      log.error("While downloading document: " + err);
    }
  }

  function* watchDownloadDocumentRequests() {
    yield takeLatest(getType(doDownloadDocument), handleDownloadDocument);
  }

  yield fork(watchDownloadDocumentRequests);
}

export function* documentsSaga(portalAPI: PortalService) {
  yield all([
    fork(downloadDocumentSaga, portalAPI),
    fork(loadDocumentsSaga, portalAPI),
  ]);
}
