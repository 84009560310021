import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";

import {
  doDownloadDocument,
  doLoadDocumentsForProperty,
} from "../../actions/documents";
import { RootState } from "../../reducers";
import {
  selectDocumentsForProperty,
  selectLoadingDocuments,
} from "../../reducers/documents";
import { IDocument } from "../../types/documents";
import { IUnit } from "../../types/units";
import { DocumentsPage } from "./page";

export interface IOwnProps {
  unit: IUnit;
}

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      navigateDownloadDocument: (name: string) => doDownloadDocument(name),
      loadDocuments: (propertyCode: string) =>
        doLoadDocumentsForProperty.request(propertyCode),
    },
    dispatch
  );

const mapStateToProps = (state: RootState, ownProps: IOwnProps) => {
  const { unit } = ownProps;

  const selectDocuments = (state: RootState) => {
    const docs: Array<IDocument> | undefined = unit
      ? selectDocumentsForProperty(unit.propertyCode)(state).valueSeq().toJS()
      : undefined;

    if (!docs) {
      return undefined;
    }

    return docs.sort((a, b) => -a.modifyTime.diff(b.modifyTime));
  };

  return {
    documents: selectDocuments(state),
    loadingDocuments: selectLoadingDocuments(state),
  };
};

export type Props = IOwnProps &
  ReturnType<typeof mapDispatchToProps> &
  ReturnType<typeof mapStateToProps>;

export const DocumentsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(DocumentsPage);
