import {
  FormControlLabel,
  IconButton,
  InputAdornment,
  Typography,
} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import { InputProps } from "@material-ui/core/Input";
import { makeStyles, Theme } from "@material-ui/core/styles";
import ClearIcon from "@material-ui/icons/Clear";
import { FormApi, getIn } from "final-form";
import arrayMutators from "final-form-arrays";
import { Checkbox } from "final-form-material-ui";
import React from "react";
import { Form, FormSpy } from "react-final-form";
import { FieldArray } from "react-final-form-arrays";
import { Field } from "react-final-form-html5-validation";

import { ITenantContactPreferences, Props } from "../container";
import {
  FormContainer,
  FormField,
  FormSectionTitle,
  inputStyle,
  SubmitWidget,
} from "./page-section-widget";

type ContactPreferencesSectionProps = {
  disabled: boolean;
  isSaving: boolean;
  handleSave: Props["updateContactPreferences"];
  tenantCode: string;
  preferences: ITenantContactPreferences;
};

const emailCCDecorator = (form: FormApi<ITenantContactPreferences>) => {
  return form.subscribe(
    ({ values }) => {
      form.batch(() => {
        const emailCC = getIn(values, "emailCC");
        if (emailCC.length === 0 || !!emailCC[emailCC.length - 1]) {
          form.mutators.push("emailCC", "");
        }
      });
    },
    { values: true }
  );
};

const useContactPreferencesStyles = makeStyles((theme: Theme) => ({
  ccWidget: {
    marginLeft: "2em",
  },
}));

export const ContactPreferencesSection: React.FunctionComponent<ContactPreferencesSectionProps> = ({
  disabled,
  handleSave,
  isSaving,
  preferences,
  tenantCode,
}) => {
  type FormContext = ITenantContactPreferences;
  const classes = useContactPreferencesStyles();

  const initialValues = {
    sendEmail: preferences.sendEmail,
    emailReceipts: preferences.emailReceipts,
    emailCC: Array.from(preferences.emailCC || []).concat([""]),
  };

  const handleSubmit = (values: FormContext) => {
    handleSave({
      code: tenantCode,

      sendEmail: values.sendEmail,
      emailReceipts: values.sendEmail && values.emailReceipts,

      // filter the extra, blank field added to be populated with CC
      emailCC: values.emailCC ? values.emailCC.filter((v) => v) : [],
    });
  };

  return (
    <Form
      decorators={[emailCCDecorator]}
      initialValues={initialValues}
      mutators={{
        ...arrayMutators,
      }}
      onSubmit={handleSubmit}
      render={({ handleSubmit, pristine, invalid, values }) => (
        <form onSubmit={handleSubmit}>
          <FormSectionTitle bold title="Preferences" />
          <FormContainer limitWidth={false} row={false}>
            <Grid item xs>
              <Field type="checkbox" name="sendEmail">
                {(props) => (
                  <FormControlLabel
                    control={<Checkbox {...props} />}
                    label={
                      "Receive correspondence via email instead of paper, including " +
                      "invoices, accounts and general correspondence."
                    }
                    disabled={disabled}
                  />
                )}
              </Field>
            </Grid>
            <FormSpy subscription={{ values: true }}>
              {({ values }) => (
                <Box display={values.sendEmail ? "inherit" : "none"}>
                  <Grid classes={{ root: classes.ccWidget }} item xs>
                    <Field type="checkbox" name="emailReceipts">
                      {(props) => (
                        <FormControlLabel
                          control={<Checkbox {...props} />}
                          label="Receive payment confirmation by email"
                          disabled={disabled}
                        />
                      )}
                    </Field>
                  </Grid>
                </Box>
              )}
            </FormSpy>
          </FormContainer>
          <EmailCCWidget disabled={disabled} emails={values.emailCC} />
          <SubmitWidget
            disabled={pristine || invalid || disabled}
            inline
            isSaving={isSaving}
            label="Save Preferences"
          />
        </form>
      )}
    />
  );
};

const useAdditionalEmailFieldStyles = makeStyles(() => ({
  inputRoot: inputStyle,
}));

interface AdditionalEmailFieldProps {
  disabled: boolean;
  name: string;
  index: number;
  displayRemove: boolean;
  handleRemove: () => void;
}

const AdditionalEmailField: React.FC<AdditionalEmailFieldProps> = ({
  disabled,
  name,
  index,
  displayRemove,
  handleRemove,
}) => {
  const classes = useAdditionalEmailFieldStyles();

  let endAdornment: InputProps["endAdornment"];
  if (displayRemove) {
    endAdornment = (
      <InputAdornment
        classes={{
          root: classes.inputRoot,
        }}
        position="end"
      >
        <IconButton
          edge="end"
          aria-label={`Remove additional email address ${index}`}
          onClick={handleRemove}
        >
          <ClearIcon />
        </IconButton>
      </InputAdornment>
    );
  }

  return (
    <FormField
      disabled={disabled}
      name={name}
      label={`Additional email ${index}`}
      type="email"
      InputProps={{
        endAdornment,
      }}
    />
  );
};

type EmailCCWidgetProps = {
  disabled: boolean;
  emails?: string[];
};

const EmailCCWidget: React.FC<EmailCCWidgetProps> = ({ disabled, emails }) => {
  const displayAdditionalEmailField = (idx: number) =>
    !disabled || !emails || !!emails[idx];

  return (
    <Grid item xs>
      <FormSectionTitle bold title="Copies of email correspondence" />
      <Typography gutterBottom variant="body2">
        List additional email addresses you would like to be included as CC's
        for the above.
      </Typography>
      <Grid container direction="column">
        <Grid item xs md={6}>
          <FieldArray name="emailCC">
            {({ fields }) =>
              fields.map(
                (name: string, idx: number) =>
                  displayAdditionalEmailField(idx) && (
                    <AdditionalEmailField
                      key={`cc-email-${name}`}
                      name={name}
                      index={idx + 1}
                      displayRemove={
                        !disabled && idx !== (fields.length || 0) - 1
                      }
                      handleRemove={() => fields.remove(idx)}
                      disabled={disabled}
                    />
                  )
              )
            }
          </FieldArray>
        </Grid>
      </Grid>
    </Grid>
  );
};
