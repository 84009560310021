import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import Grid, { GridProps } from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { makeStyles, Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import classNames from "classnames";
import { TextField } from "final-form-material-ui";
import React from "react";
import { Field, FieldProps, FieldRenderProps } from "react-final-form";

import { SavingSpinner } from "../../../widgets/forms";

interface PageSectionProps {
  title: string;
}

const usePageSectionStyles = makeStyles((theme: Theme) => ({
  paperRoot: {
    border: "1px solid #eee",
    marginBottom: theme.spacing(4),
    padding: theme.spacing(2),
  },
  title: {
    fontVariant: "small-caps",
  },
}));

export const PageSectionWidget: React.FunctionComponent<
  React.PropsWithChildren<PageSectionProps>
> = ({ children, title }) => {
  const classes = usePageSectionStyles();

  return (
    <Paper
      elevation={0}
      square={false}
      classes={{
        root: classes.paperRoot,
      }}
    >
      <Typography classes={{ root: classes.title }} gutterBottom variant="h6">
        {title}
      </Typography>
      {children}
    </Paper>
  );
};

const useFormContainerStyles = makeStyles((theme: Theme) => ({
  formFieldsContainer: {
    marginBottom: theme.spacing(1),
  },
  formFieldsContainerLimitWidth: {
    maxWidth: 650,
  },
}));

export const FormContainer: React.FunctionComponent<
  React.PropsWithChildren<
    {
      gutterBottom?: boolean;
      row?: boolean;
      className?: string;
      limitWidth?: boolean;
    } & GridProps
  >
> = ({
  children,
  gutterBottom,
  row = true,
  className,
  limitWidth = true,
  ...gridProps
}) => {
  const classes = useFormContainerStyles();
  const direction = row ? "row" : "column";

  const mergedClassName = classNames(
    classes.formFieldsContainer,
    limitWidth && classes.formFieldsContainerLimitWidth,
    className
  );

  return (
    <Grid
      container
      direction={direction}
      wrap="wrap"
      className={mergedClassName}
      spacing={1}
      {...gridProps}
    >
      {children}
    </Grid>
  );
};

export const FormGridItem: React.SFC<React.PropsWithChildren<GridProps>> = ({
  children,
  xs,
  md,
  ...props
}) => {
  if (xs === undefined) {
    xs = true;
  } else {
    md = undefined;
  }

  return (
    <Grid item xs={xs} md={md} {...props}>
      {children}
    </Grid>
  );
};

export const inputStyle = {
  backgroundColor: "rgba(255, 255, 255)",
};

const useFieldStyles = makeStyles((theme: Theme) => ({
  fieldRoot: {
    minWidth: 300,
    width: "100%",
    backgroundColor: "rgb(255, 255, 255)",
  },

  maxWidth: {
    maxWidth: 300,
  },

  input: {
    width: "100%",
    ...inputStyle,
  },

  fieldRootPadding: {
    paddingBottom: theme.spacing(2),
  },

  fieldGridRoot: {
    width: "100%",
  },
}));

type FormFieldProps<
  S = any,
  T extends HTMLElement = HTMLElement
> = React.HTMLAttributes<T> &
  FieldProps<S, FieldRenderProps<S, T>, T> & {
    fullWidth?: boolean;
    gutterBottom?: boolean;
  } & {
    gridProps?: GridProps;
  };

export const FormField: React.FunctionComponent<FormFieldProps> = ({
  className,
  classes,
  fullWidth,
  gutterBottom,
  InputProps,
  gridProps = {},
  ...props
}) => {
  const disabled = props.disabled;
  const localClasses = useFieldStyles();

  if (gutterBottom === undefined) {
    gutterBottom = true;
  }

  const rootClassName = classNames(
    localClasses.fieldRoot,
    classes && classes.root,
    fullWidth && localClasses.maxWidth,
    className
  );

  const extraProps = {
    // Default to TextField if nothing to render was supplied
    component: !(props.children || props.component || props.render)
      ? TextField
      : undefined,
    fullWidth,
    margin: gutterBottom ? "dense" : "none",
    variant: "outlined",
  };

  gridProps = Object.assign({}, gridProps, {
    xs: fullWidth && 12,
  });

  return (
    <FormGridItem {...gridProps}>
      <Field
        classes={Object.assign({}, classes, {
          root: rootClassName,
        })}
        InputLabelProps={{
          // Force shrink on the input label when the field is disabled, because
          // otherwise on read-only profiles, it is not possible to determine the
          // difference between a populated field (contents in grey) and an
          // unpopulated field with the label showing in the body of the field (also
          // shown in grey).
          shrink: disabled || undefined,
        }}
        InputProps={{
          classes: {
            input: localClasses.input,
          },
          ...InputProps,
        }}
        {...extraProps}
        {...props}
      />
    </FormGridItem>
  );
};

const useBlockFormControlStyles = makeStyles((theme: Theme) => ({
  rootStyles: {
    display: "block",
  },
}));

export const BlockFormControl: React.FunctionComponent<
  React.PropsWithChildren<{}>
> = ({ children }) => {
  const classes = useBlockFormControlStyles();

  return (
    <FormControl
      classes={{
        root: classes.rootStyles,
      }}
    >
      {children}
    </FormControl>
  );
};

interface SubmitWidgetProps {
  disabled?: boolean;
  isSaving?: boolean;
  inline?: boolean;
  label: string;
}

const useSubmitWidgetStyles = makeStyles((theme: Theme) => ({
  submitContainer: {
    textAlign: "right",
    marginTop: "auto",
    marginBottom: theme.spacing(1),
  },
}));

export const SubmitWidget: React.SFC<
  SubmitWidgetProps & {
    gridProps?: GridProps;
  }
> = ({ disabled, inline, isSaving, label, gridProps }) => {
  const classes = useSubmitWidgetStyles();

  return (
    <FormGridItem
      classes={{
        root: classes.submitContainer,
      }}
      {...gridProps}
    >
      <SavingSpinner isSaving={isSaving || false} />
      <Button
        color="primary"
        disabled={disabled || false}
        variant="contained"
        type="submit"
      >
        {label}
      </Button>
    </FormGridItem>
  );
};

const useFormSectionTitleStyles = makeStyles((theme: Theme) => ({
  bold: {
    fontWeight: "bold",
  },
  gutterBottom: {
    marginBottom: theme.spacing(1),
  },
  inline: {
    display: "inline",
  },
  text: {
    fontVariant: "small-caps",
    fontWeight: "bold",
  },
  upper: {
    textTransform: "uppercase",
  },
}));

interface FormSectionTitleProps {
  bold?: boolean;
  gutterBottom?: boolean;
  inline?: boolean;
  title: string;
  upper?: boolean;
}

export const FormSectionTitle = ({
  bold,
  gutterBottom,
  inline,
  title,
  upper = false,
}: FormSectionTitleProps) => {
  const classes = useFormSectionTitleStyles();
  const className = classNames(
    classes.text,
    bold && classes.bold,
    gutterBottom && classes.gutterBottom,
    inline && classes.inline,
    upper && classes.upper
  );

  return (
    <Typography classes={{ root: className }} variant="subtitle1">
      {title}
    </Typography>
  );
};
