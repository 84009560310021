import { List, Map } from "immutable";
import { all, call, fork, put, takeLatest } from "redux-saga/effects";
import { createSelector } from "reselect";
import { getType } from "typesafe-actions";

import { AnyAction } from "../actions";
import { doLoadNewsForProperty } from "../actions/news";
import { PortalService } from "../lib/api/portal";
import { INews } from "../types/news";
import { RootState } from "./";

type PropertyNewsMap = Map<string, List<INews>>;

interface INewsState {
  readonly propertyNews?: PropertyNewsMap;
  readonly loading: boolean;
  readonly error?: Error;
}

const initialState: INewsState = {
  loading: false,
};

const mergeNews = (state: INewsState, news: Array<INews>) => {
  let newsMap = Map<string, List<INews>>();

  news.forEach((newsItem) => {
    newsMap = newsMap.set(
      newsItem.propertyCode,
      newsMap.get(newsItem.propertyCode, List<INews>()).push(newsItem)
    );
  });

  return Object.assign({}, state, {
    error: undefined,
    loading: false,
    propertyNews: state.propertyNews
      ? state.propertyNews.merge(newsMap)
      : newsMap,
  });
};

export const news = (
  state: INewsState = initialState,
  action: AnyAction
): INewsState => {
  switch (action.type) {
    case getType(doLoadNewsForProperty.failure):
      return { error: action.payload, loading: false };
    case getType(doLoadNewsForProperty.request):
      return { loading: true };
    case getType(doLoadNewsForProperty.success):
      return mergeNews(state, action.payload);
    default:
      return state;
  }
};

export const selectNews = (state: RootState) => state.news.propertyNews;
export const selectNewsForProperty = (propertyCode: string) =>
  createSelector(selectNews, (news) =>
    news ? news.get(propertyCode, List<INews>()) : List<INews>()
  );

function* loadNewsForPropertySaga(portalAPI: PortalService) {
  function* handleLoadNewsRequest(
    req: ReturnType<typeof doLoadNewsForProperty.request>
  ) {
    try {
      const res: Array<INews> = yield call(
        portalAPI.listNewsForProperty,
        req.payload
      );
      yield put(doLoadNewsForProperty.success(res));
    } catch (err) {
      yield put(doLoadNewsForProperty.failure(err));
    }
  }

  function* watchLoadNews() {
    yield takeLatest(
      getType(doLoadNewsForProperty.request),
      handleLoadNewsRequest
    );
  }

  yield fork(watchLoadNews);
}

export function* newsSaga(portalAPI: PortalService) {
  yield all([fork(loadNewsForPropertySaga, portalAPI)]);
}
