import { connect } from "react-redux";

import { RootState } from "../../reducers";
import { selectPropertyByUnitCode } from "../../reducers/property";
import { selectUnitByCode } from "../../reducers/units";
import { Helmet } from "./helmet";

interface IOwnProps {
  unitCode: string;
}

const mapStateToProps = (state: RootState, { unitCode }: IOwnProps) => ({
  property: selectPropertyByUnitCode(state, unitCode),
  unit: selectUnitByCode(state, unitCode),
});

export const HelmetContainer = connect(mapStateToProps)(Helmet);

type StateProps = ReturnType<typeof mapStateToProps>;

export type PropTypes = StateProps;

export type ConnectedProps = ReturnType<typeof mapStateToProps>;
