import { ConnectedRouter, routerMiddleware } from "connected-react-router";
import { createBrowserHistory } from "history";
import log from "loglevel";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { applyMiddleware, createStore } from "redux";
import { createLogger } from "redux-logger";
import createSagaMiddleware from "redux-saga";

import { doInit } from "./actions/init";
import { getAPIBaseURL } from "./lib/api";
import { AuthService } from "./lib/api/auth";
import { PortalService } from "./lib/api/portal";
import { RegistrationService } from "./lib/api/registration";
import { rootReducerFactory, rootSagaFactory } from "./reducers";
import * as serviceWorker from "./serviceWorker";

const basename = (window as any).basename || "";
const history = createBrowserHistory({ basename });

const sagaMiddleware = createSagaMiddleware();

const loggerMiddleware = createLogger({});
const store = createStore(
  rootReducerFactory(history),
  applyMiddleware(
    ...[sagaMiddleware, routerMiddleware(history), loggerMiddleware]
  )
);

const apiBase = getAPIBaseURL();
const authAPI = new AuthService(apiBase);
const portalAPI = new PortalService(apiBase);
const registrationAPI = new RegistrationService(apiBase);

const apis = {
  auth: authAPI,
  portal: portalAPI,
  registration: registrationAPI,
};

(window as any).log = log;

export const APIContext = React.createContext<typeof apis>(apis);
APIContext.displayName = "APIContext";

sagaMiddleware.run(rootSagaFactory(authAPI, portalAPI, registrationAPI));

const render = () => {
  const App = require("./App").default;

  ReactDOM.render(
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <APIContext.Provider value={apis}>
          <App />
        </APIContext.Provider>
      </ConnectedRouter>
    </Provider>,
    document.getElementById("root")
  );
};

render();

if (process.env.NODE_ENV === "development" && module.hot) {
  module.hot.accept("./App", render);
}

store.dispatch(doInit());

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
