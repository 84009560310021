import Grid from "@material-ui/core/Grid";
import { makeStyles, Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import classNames from "classnames";
import React from "react";

import Helmet from "../helmet";

type ContainerProps = {
  headerAdornment?: JSX.Element;
  title: string;
  unitCode: string;
};

const useStyles = makeStyles((theme: Theme) => ({
  pageWidth: {
    width: "100%",
  },

  helmetContainer: {
    marginBottom: theme.spacing(1),
  },

  titleContainer: {
    alignSelf: "center",
  },

  titleText: {
    textTransform: "uppercase",
  },

  rubricContainer: {
    marginBottom: theme.spacing(2),
  },

  typographyRoot: {
    display: "inline-block",
    flexGrow: 1,
  },
}));

export const UnitPageLayout: React.FC<
  React.PropsWithChildren<ContainerProps>
> = ({ headerAdornment, title, unitCode, children }) => {
  const classes = useStyles();

  return (
    <Grid container direction="column">
      <Grid
        item
        className={classNames(classes.helmetContainer, classes.pageWidth)}
      >
        <Helmet unitCode={unitCode} />
      </Grid>
      <Grid
        item
        classes={{
          root: classNames(classes.pageWidth, classes.rubricContainer),
        }}
      >
        <Grid container direction="row">
          <Grid item xs classes={{ root: classes.titleContainer }}>
            <Typography
              classes={{
                root: classes.typographyRoot,
              }}
              variant="h6"
              className={classes.titleText}
            >
              {title}
            </Typography>
          </Grid>

          {headerAdornment}
        </Grid>
      </Grid>
      <Grid item classes={{ root: classes.pageWidth }}>
        {children}
      </Grid>
    </Grid>
  );
};
