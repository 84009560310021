import { makeStyles, Theme } from "@material-ui/core/styles";

export const useTableStyles = makeStyles((theme: Theme) => ({
  tableBorder: {
    border: "1px solid #e7e7e7",
  },
  tableHead: {
    backgroundColor: "#e7e7e7",
  },
}));
