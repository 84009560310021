import { connect } from "react-redux";
import { Dispatch } from "redux";

import { doSendManagerMessage } from "../../actions/contact";
import { doLoadTenantDetails } from "../../actions/tenants";
import { RootState } from "../../reducers";
import { selectSession } from "../../reducers/auth";
import { selectContactManager } from "../../reducers/contact";
import { selectTenant } from "../../reducers/tenants";
import { ISession } from "../../types/auth";
import { ContactMessage } from "../../types/contact";
import { ITenant } from "../../types/tenants";
import { IUnit } from "../../types/units";
import { ContactPage } from "./page";

export interface IOwnProps {
  unit: IUnit;
}

interface IState {
  isSaving: boolean;
  session?: ISession;
  tenant?: ITenant;
}

const mapDispatchToProps = (dispatch: Dispatch, { unit }: IOwnProps) => ({
  loadTenantDetails: () =>
    dispatch(doLoadTenantDetails.request(unit.tenantCode)),
  sendMessage: (msg: ContactMessage) =>
    dispatch(doSendManagerMessage.request(msg)),
});

const mapStateToProps = (state: RootState, { unit }: IOwnProps): IState => {
  const base = {
    isSaving: false,
  };

  if (!unit) {
    return base;
  }

  const session = selectSession(state);
  const tenant = selectTenant(unit.tenantCode)(state);
  const isSaving = selectContactManager(state).sending;

  return Object.assign({}, base, {
    isSaving,
    session,
    tenant,
  });
};

const ContactContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ContactPage);

export type Props = IOwnProps &
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

export { ContactContainer };
