import { Button, Grid } from "@material-ui/core";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { TextField } from "final-form-material-ui";
import React from "react";
import { Form } from "react-final-form";
import { Field } from "react-final-form-html5-validation";

import { ContactMessageDetails } from "../../types/contact";
import { SavingSpinner } from "../../widgets/forms";

interface ContactFormProps {
  initialName?: string;
  initialFromAddress?: string;
  initialPhone?: string;
  isSaving: boolean;
  onSubmit: (arg0: ContactMessageDetails) => void;
}

const useClasses = makeStyles((theme: Theme) => ({
  submitButtonContainer: {
    alignSelf: "flex-end",
  },
}));

type FormContext = {
  name: string;
  email: string;
  phone?: string;
  message: string;
};

export const ContactForm: React.FC<ContactFormProps> = ({
  initialName,
  initialFromAddress,
  initialPhone,
  isSaving,
  onSubmit,
}) => {
  const classes = useClasses();
  const handleSubmit = (values: FormContext) => {
    onSubmit({
      name: values.name,
      email: values.email,
      telephone: values.phone || "",
      body: values.message,
    });
  };

  const initialValues = {
    name: initialName || "",
    email: initialFromAddress || "",
    phone: initialPhone,
    message: "",
  };

  return (
    <Form
      initialValues={initialValues}
      onSubmit={handleSubmit}
      render={({ handleSubmit, pristine, invalid }) => (
        <form onSubmit={handleSubmit}>
          <Grid container direction="column" spacing={2} alignItems="center">
            <Grid
              item
              xs
              container
              direction="row"
              justify="space-around"
              spacing={2}
            >
              <Grid item xs={12} md={4}>
                <Field
                  component={TextField}
                  variant="outlined"
                  margin="none"
                  name="name"
                  label="Name"
                  id="name"
                  fullWidth
                  required
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <Field
                  component={TextField}
                  variant="outlined"
                  margin="none"
                  name="email"
                  label="Email"
                  id="email"
                  type="email"
                  fullWidth
                  required
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <Field
                  component={TextField}
                  variant="outlined"
                  margin="none"
                  name="phone"
                  label="Phone"
                  id="phone"
                  fullWidth
                />
              </Grid>
            </Grid>
            <Grid item xs container direction="column" spacing={2}>
              <Grid item xs>
                <Field
                  component={TextField}
                  InputProps={{
                    multiline: true,
                    rows: 8,
                  }}
                  variant="outlined"
                  margin="none"
                  name="message"
                  label="Message"
                  id="message"
                  helperText="Enter your message"
                  fullWidth
                  required
                />
              </Grid>
              <Grid item xs className={classes.submitButtonContainer}>
                <SavingSpinner
                  isSaving={isSaving || false}
                  text={{
                    savingText: "Sending",
                    savedText: "Sent",
                  }}
                />
                <Button
                  color="primary"
                  disabled={pristine || invalid}
                  type="submit"
                  variant="contained"
                >
                  Send message
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </form>
      )}
    />
  );
};
