import React, { useEffect, useState } from "react";

import { usePortalAPI } from "../../lib/hooks";
import UnitPageLayout from "../../widgets/unit-pages";
import { Props } from "./container";
import { ProfileBox } from "./profile";
import ThirdPartyProfilesBox from "./third-party-profiles";

export const ProfilePage: React.FunctionComponent<Props> = ({
  loadTenantDetails,
  tenant,
  unit,
  ...props
}) => {
  const portalAPI = usePortalAPI();
  const [editor, setEditor] = useState(true);

  useEffect(() => {
    if (loadTenantDetails) {
      loadTenantDetails();
    }
  }, [loadTenantDetails]);

  useEffect(() => {
    if (!tenant) {
      return;
    }

    const fetchTenantRoles = async () => {
      const response = await portalAPI.getTenantRoles(tenant.code);
      setEditor(response.editor);
    };
    fetchTenantRoles();
  }, [portalAPI, tenant]);

  if (!tenant) {
    return <span>Loading</span>;
  }

  return (
    <UnitPageLayout title="Manage Profile" unitCode={unit.code}>
      <ProfileBox editor={editor} tenant={tenant} {...props} />
      {editor && <ThirdPartyProfilesBox tenant={tenant} />}
    </UnitPageLayout>
  );
};
