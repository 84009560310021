import { push } from "connected-react-router";
import log from "loglevel";
import queryString from "query-string";
import {
  all,
  call,
  delay,
  fork,
  put,
  select,
  take,
  takeLeading,
} from "redux-saga/effects";
import { createSelector } from "reselect";
import { getType } from "typesafe-actions";

import { AnyAction } from "../actions";
import { doLogin, doLogout, doSetUserPassword } from "../actions/auth";
import { doInit } from "../actions/init";
import { beginSession, endSession, setSessionState } from "../actions/session";
import { AuthService } from "../lib/api/auth";
import { CookieHandler } from "../lib/cookies";
import { ISession } from "../types/auth";
import { RootState } from "./";
import { SessionState } from "./session";

interface ILoginState {
  readonly error?: Error;
  readonly loggingIn: boolean;
  readonly session?: ISession;
  readonly persistentSession?: boolean;
}

export const selectLoginError = (state: RootState) => state.login.error;

const cookieHandler = new CookieHandler();

const initialState: ILoginState = {
  loggingIn: false,
};

export const login = (
  state: ILoginState = initialState,
  action: AnyAction
): ILoginState => {
  switch (action.type) {
    case getType(doLogin.failure):
      return Object.assign({}, state, {
        loggingIn: false,
        error: action.payload,
      });
    case getType(doLogin.request):
      return Object.assign({}, state, {
        loggingIn: true,
        persistentSession: action.payload.persistent,
      });
    case getType(doLogin.success):
      return {
        loggingIn: false,
        session: action.payload.session,
        persistentSession: state.persistentSession,
      };
    default:
      return state;
  }
};

export const selectSession = (state: RootState) => state.login.session;
export const selectSessionKey = createSelector(
  selectSession,
  (session) => session?.key || ""
);
export const selectIsLoggedIn = createSelector(
  selectSessionKey,
  (sessionKey) => sessionKey !== ""
);
export const selectIsLoggingIn = (state: RootState) =>
  state.login ? state.login.loggingIn : false;
export const selectUsername = createSelector(
  selectSession,
  (session) => session?.username
);
export const shouldPersist = (state: RootState) =>
  !!state.login.persistentSession;

const getNextFromQuery = (state: RootState) => {
  const parsed = queryString.parse(state.router.location.search);
  return parsed.next;
};

function* loginSaga(authAPI: AuthService) {
  function* handleLogin(req: ReturnType<typeof doLogin.request>) {
    try {
      const res: ISession = yield call(
        authAPI.login,
        req.payload.username,
        req.payload.password,
        req.payload.persistent
      );
      yield put(
        doLogin.success({
          session: res,
          redirect: true,
        })
      );
    } catch (err) {
      yield put(doLogin.failure(err));
      yield delay(1000);
    }
  }

  function* watchLoginRequest() {
    yield takeLeading(getType(doLogin.request), handleLogin);
  }

  yield fork(watchLoginRequest);
}

function* logoutSaga() {
  function* handleLogout() {
    yield call(cookieHandler.Delete);
    yield put(endSession());
    yield put(push("/login"));
    window.location.reload();
  }

  function* watchLogoutRequest() {
    yield takeLeading(getType(doLogout), handleLogout);
  }

  yield fork(watchLogoutRequest);
}

function* checkLoggedInSaga(authAPI: AuthService) {
  function* loadSession(authAPI: AuthService) {
    const sessionKey = yield call(cookieHandler.Get);
    // check whether the session key is valid
    if (sessionKey === null) {
      return null;
    }

    try {
      const session = yield call(authAPI.sessionInfo, sessionKey);
      return session;
    } catch (err) {
      log.error(
        "unable to get session info using persisted session key - maybe session expired?"
      );
      yield put(setSessionState(SessionState.INACTIVE));
      return null;
    }
  }

  const session = yield call(loadSession, authAPI);
  if (session) {
    yield put(
      doLogin.success({
        session: session,
        redirect: false,
      })
    );
  }
}

function* loginSuccessfulSaga() {
  function* handleLoginSuccess(req: ReturnType<typeof doLogin.success>) {
    const shouldPersistCookie = yield select(shouldPersist);
    const session = req.payload.session;

    yield call(
      cookieHandler.Set,
      session.key,
      shouldPersistCookie ? session.expireTime : undefined // session cookie if undefined
    );
    yield put(beginSession(session));

    if (req.payload.redirect) {
      // We can't redirect into the app until the session is marked as active
      while (true) {
        const sessionState = yield take(setSessionState);
        if (sessionState) {
          break;
        }
      }

      let next = yield select(getNextFromQuery);
      if (!next) {
        next = "/app";
      }
      yield put(push(next));
    }
  }

  function* watchLoginSuccess() {
    yield takeLeading(getType(doLogin.success), handleLoginSuccess);
  }

  yield fork(watchLoginSuccess);
}

function* onInit(authAPI: AuthService) {
  yield take(getType(doInit));
  yield all([fork(checkLoggedInSaga, authAPI)]);
}

interface IPasswordState {
  error?: Error;
  settingPassword: boolean;
}

const initialPasswordState = {
  settingPassword: false,
};

export const password = (
  state: IPasswordState = initialPasswordState,
  action: AnyAction
) => {
  switch (action.type) {
    case getType(doSetUserPassword.request):
      return { settingPassword: true };
    case getType(doSetUserPassword.success):
      return { settingPassword: false };
    case getType(doSetUserPassword.failure):
      return { settingPassword: false, error: action.payload };
    default:
      return state;
  }
};

export const selectPasswordState = (state: RootState) => state.password;
export const selectSettingPassword = createSelector(
  selectPasswordState,
  (password) => (password ? password.settingPassword : false)
);

function* setPasswordSaga(authAPI: AuthService) {
  function* handleSetPassword(
    req: ReturnType<typeof doSetUserPassword.request>
  ) {
    try {
      yield call(authAPI.setPassword, req.payload);
      yield put(doSetUserPassword.success());
    } catch (err) {
      yield put(doSetUserPassword.failure(err));
    }
  }

  function* watchSetPassword() {
    yield takeLeading(getType(doSetUserPassword.request), handleSetPassword);
  }

  yield fork(watchSetPassword);
}

export function* authSaga(authAPI: AuthService) {
  yield all([
    fork(loginSaga, authAPI),
    fork(loginSuccessfulSaga),
    fork(logoutSaga),
    fork(onInit, authAPI),
    fork(setPasswordSaga, authAPI),
  ]);
}
