import {
  Button,
  Checkbox,
  TableCell,
  TableRow,
  Tooltip,
} from "@material-ui/core";
import { DeleteForever } from "@material-ui/icons";
import React, { useState } from "react";

import { UserProfile } from "../../../types/profiles";
import ComplexTable from "../../../widgets/complex-table";
import { IHeadCell } from "../../../widgets/complex-table/head-cell";

type ThirdPartyProfilesGridProps = {
  onDelete: (arg1: { username: string }) => void;
  primaryEmailAddress?: string;
  profiles: UserProfile[];
};

const gridColumns: IHeadCell<ThirdPartyProfilesGridItemProps>[] = [
  { id: "username", label: "Username" },
  { id: "emailAddress", label: "Email address" },
  { id: "primary", label: "Primary", disableSort: true },
  { id: "readOnly", label: "Read only", disableSort: true },
  { id: "options", label: "", disableSort: true },
];

type ThirdPartyProfilesGridItemProps = {
  emailAddress: string | JSX.Element;
  options: null; // Placeholder
  primary: boolean;
  readOnly: boolean;
  username: string;
};

type GridItemActionProps = {
  onDelete: () => void;
};

const mapGridItems = (primaryEmailAddress: string, profiles: UserProfile[]) =>
  profiles.map((profile) => ({
    emailAddress: profile.emailAddress || <em>None recorded</em>,
    options: null,
    readOnly: profile.readOnly,
    primary: profile.emailAddress === primaryEmailAddress,
    username: profile.username,
  }));

export const ThirdPartyProfilesGrid: React.FunctionComponent<ThirdPartyProfilesGridProps> = ({
  onDelete,
  primaryEmailAddress,
  profiles,
}) => {
  return (
    <ComplexTable<ThirdPartyProfilesGridItemProps>
      filterFields={[]}
      headCells={gridColumns}
      initialSort="username"
      items={mapGridItems(primaryEmailAddress || "", profiles)}
      searchTerm={""}
    >
      {({ items }) => (
        <>
          {items.map((profile) => (
            <ThirdPartyProfilesGridItem
              key={`third-party-profile-${profile.username}`}
              onDelete={() => {
                onDelete({ username: profile.username });
              }}
              {...profile}
            />
          ))}
        </>
      )}
    </ComplexTable>
  );
};

const ThirdPartyProfilesGridItem: React.FunctionComponent<
  ThirdPartyProfilesGridItemProps & GridItemActionProps
> = ({ emailAddress, onDelete, primary, readOnly, username }) => {
  const [, setDeleting] = useState<boolean>(false);

  React.useEffect(() => {
    return () => {
      setDeleting(false);
    };
  }, []);

  const handleDelete = () => {
    setDeleting(true);
    try {
      onDelete();
    } finally {
      setDeleting(false);
    }
  };

  return (
    <TableRow key={`third-party-profile-${username}`}>
      <TableCell>{username}</TableCell>
      <TableCell>{emailAddress}</TableCell>
      <TableCell>
        <Tooltip title="Contact Esskay to update your primary profile">
          <span>
            {/* <span> to trigger hover events on disabled element */}
            <Checkbox
              disabled
              inputProps={{ "aria-label": "disabled checkbox" }}
              checked={primary}
            />
          </span>
        </Tooltip>
      </TableCell>
      <TableCell>
        <Tooltip
          title={
            readOnly
              ? "Additional profiles are always read-only. This cannot be changed"
              : "The primary profile is never read-only. This cannot be changed."
          }
        >
          <span>
            {/* <span> to trigger hover events on disabled element */}
            <Checkbox
              disabled
              inputProps={{ "aria-label": "disabled checkbox" }}
              checked={readOnly}
            />
          </span>
        </Tooltip>
      </TableCell>
      <TableCell>
        <Tooltip
          title={
            primary
              ? "You cannot delete the primary profile."
              : `Permanently delete ${username} and prevent access to your information.`
          }
        >
          <span>
            {/* <span> to trigger hover events on disabled element */}
            <Button
              disabled={primary}
              onClick={handleDelete}
              startIcon={<DeleteForever />}
            >
              Delete
            </Button>
          </span>
        </Tooltip>
      </TableCell>
    </TableRow>
  );
};
