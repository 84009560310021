import { Container, Typography } from "@material-ui/core";
import React from "react";

import { useUnitSelectionStyles } from "./styles";

type UnitSelectLayoutProps = React.PropsWithChildren<{}>;

const UnitSelectLayout: React.FunctionComponent<UnitSelectLayoutProps> = ({
  children,
}) => {
  const classes = useUnitSelectionStyles();

  return (
    <>
      <Container maxWidth="sm" component="main" className={classes.heroContent}>
        <Typography align="center">
          Please select the unit you wish to administer.
        </Typography>
      </Container>
      <Container
        maxWidth="sm"
        component="main"
        className={classes.unitListContainer}
      >
        <>{children}</>
      </Container>
    </>
  );
};

export { UnitSelectLayout };
