import { TextField } from "final-form-material-ui";
import React, { ComponentType } from "react";
import {
  Field,
  Html5ValidationFieldProps,
} from "react-final-form-html5-validation";

const MINIMUM_PASSWORD_LENGTH = 12;

const PASSWORD_TOO_SHORT = (minLength: number) =>
  `Passwords must be a minimum of ${minLength} characters.`;

interface PasswordFieldProps {
  classes?: { root: any };
  fieldComponent?: ComponentType<Html5ValidationFieldProps>;
  minimumPasswordLength?: number;
}

interface PasswordFieldNamingProps {
  name: string;
  id: string;
  label: string;
  validateFields: string[];
  validateLength?: boolean;
}

const PasswordField: React.FunctionComponent<
  PasswordFieldProps & PasswordFieldNamingProps
> = ({
  classes = {},
  fieldComponent: FieldComponent = Field,
  id,
  label,
  minimumPasswordLength = MINIMUM_PASSWORD_LENGTH,
  name,
  validateLength = false,
  validateFields = [],
}) => {
  const validateProps = validateLength
    ? {
        minLength: minimumPasswordLength,
        tooShort: PASSWORD_TOO_SHORT(minimumPasswordLength),
      }
    : {};

  return (
    <Field
      component={TextField}
      name={name}
      type="password"
      id={id}
      variant="outlined"
      label={label}
      required
      margin="normal"
      fullWidth
      autoComplete="new-password"
      classes={classes}
      validateFields={validateFields}
      {...validateProps}
    />
  );
};

type Props = PasswordFieldProps & {
  passwordFieldNames: PasswordFieldNamingProps;
  confirmPasswordFieldNames: PasswordFieldNamingProps;
};

const ConfirmPasswordFields: React.FunctionComponent<Props> = ({
  passwordFieldNames,
  confirmPasswordFieldNames,
  ...props
}) => {
  return (
    <>
      <PasswordField validateLength {...props} {...passwordFieldNames} />
      <PasswordField {...props} {...confirmPasswordFieldNames} />
    </>
  );
};

export default ConfirmPasswordFields;
