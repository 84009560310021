import { Button, TableCell, TableRow } from "@material-ui/core";
import { makeStyles, Theme } from "@material-ui/core/styles";
import React, { useEffect } from "react";

import { useTableStyles } from "../../app/styles/table-styles";
import PDFIcon from "../../assets/img/pdf-small.png";
import { IDocument } from "../../types/documents";
import ComplexTable, { SearchWidget } from "../../widgets/complex-table";
import { IHeadCell } from "../../widgets/complex-table/head-cell";
import { UnitPageLayout } from "../../widgets/unit-pages/unit-page-layout";
import { Props } from "./container";

const headCells: IHeadCell<IDocument>[] = [
  { id: "downloadURL", label: "", disableSort: true },
  { id: "friendlyName", label: "Property Document" },
];

const useDocumentsStyles = makeStyles((theme: Theme) => ({
  downloadCell: {
    textAlign: "center",
    width: "1%",
  },
}));

export const DocumentsPage: React.FunctionComponent<Props> = ({
  documents,
  loadDocuments,
  loadingDocuments,
  navigateDownloadDocument,
  unit,
}) => {
  const tableClasses = useTableStyles();
  const [searchTerm, setSearchTerm] = React.useState<string>("");
  const documentsClasses = useDocumentsStyles();

  useEffect(() => {
    if (unit) {
      loadDocuments(unit.propertyCode);
    }
  }, [loadDocuments, unit]);

  if (!documents) {
    return null;
  }

  return (
    <UnitPageLayout
      headerAdornment={<SearchWidget onChange={setSearchTerm} />}
      title="View Documents"
      unitCode={unit.code}
    >
      <ComplexTable<IDocument>
        filterFields={["friendlyName"]}
        headCells={headCells}
        initialSort="friendlyName"
        items={documents}
        searchTerm={searchTerm}
        classes={{
          border: tableClasses.tableBorder,
          head: tableClasses.tableHead,
        }}
      >
        {({ items }) => (
          <>
            {items.map((doc) => (
              <TableRow key={doc.name}>
                <TableCell classes={{ root: documentsClasses.downloadCell }}>
                  <Button onClick={() => navigateDownloadDocument(doc.name)}>
                    <img src={PDFIcon} alt="Load document - PDF icon" />
                  </Button>
                </TableCell>
                <TableCell id={doc.name} scope="row">
                  {doc.friendlyName}
                </TableCell>
              </TableRow>
            ))}
          </>
        )}
      </ComplexTable>
    </UnitPageLayout>
  );
};
