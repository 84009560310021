import {
  Button,
  CircularProgress,
  Container,
  Typography,
} from "@material-ui/core";
import { makeStyles, Theme } from "@material-ui/core/styles";
import React from "react";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    padding: theme.spacing(2),
    textAlign: "center",
    width: "75%",

    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
}));

type Props = {
  username: string;
};

export const RegisteredPage: React.FunctionComponent<Props> = ({
  username,
}) => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <>
      <Container className={classes.container}>
        <Typography gutterBottom variant="body1">
          You are now registered for the portal
        </Typography>
        <Typography variant="body1">
          Please make a note of your chosen username:
        </Typography>
        <Typography variant="body1">
          <b>{username}</b>
        </Typography>
      </Container>
      <Container className={classes.container}>
        <Button
          onClick={() => {
            history.push("/login");
          }}
          color="primary"
          variant="contained"
        >
          Log in now
        </Button>
      </Container>
    </>
  );
};

export const CheckingInvite: React.FunctionComponent = () => {
  const classes = useStyles();

  return (
    <>
      <Container className={classes.container}>
        <CircularProgress size={48} />
        <Typography variant="h5">
          Please wait while we load your invitation
        </Typography>
      </Container>
    </>
  );
};
