import {
  createMuiTheme,
  makeStyles,
  responsiveFontSizes,
  Theme,
} from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

// pageSpacingFactor describes the amount of padding added to all elements in
// the body of the page as a function of theme spacing.
const pageSpacingFactor = 4;
const mobilePageSpacingFactor = 2;

// titleBarHeight is the height of the top title bar in pixels. This must be consistent
// across various height styles for layout purposes.
const titleBarHeight = 90;
const mobileTitleBarHeight = 62;

const mobileMenuTogglePx = 800;
export const useMobileViewHelper = () =>
  useMediaQuery(`(max-width: ${mobileMenuTogglePx}px)`);

export const useLoggedInAppStyles = makeStyles((theme: Theme) => ({
  bodyContainer: {
    backgroundColor: "#f3f3f3",
  },

  mainBody: {
    flexGrow: 1,
    padding: theme.spacing(pageSpacingFactor),
    width: "100%",

    [theme.breakpoints.down(mobileMenuTogglePx)]: {
      padding: theme.spacing(mobilePageSpacingFactor),
    },
  },

  drawerLeft: {
    transition: "all ease-in-out 0.8s",
  },

  slidingDrawerOpenLeft: {
    transform: "translateX(250px)",
  },

  mainFixed: {
    paddingLeft: 250,
  },

  nav: {
    backgroundColor: "#E2DAC7",
    boxShadow: "-5px 0 12px rgba(0, 0, 0, 0.25)",
    height: "100%",
    position: "fixed",
    // Ensure scrolling is possible on small devices oriented landscape
    overflowY: "auto",
    width: 250,
    zIndex: 5,
    alignContent: "space-between",
    flexWrap: "nowrap",
    display: "flex",
    flexDirection: "column",
  },

  navCollapsible: {
    boxShadow: "none",
    left: -250,
  },

  page: {
    position: "absolute",
  },

  pageTitleBar: {
    backgroundColor: "#ffffff",
    boxShadow: "0 0 15px #cccccc",
    flexBasis: titleBarHeight,
    flexGrow: 0,
    flexShrink: 0,
    height: titleBarHeight,
    padding: `0 ${theme.spacing(pageSpacingFactor)}px`,
    position: "sticky",
    top: 0,
    width: "100%",
    zIndex: 10,

    [theme.breakpoints.down(mobileMenuTogglePx)]: {
      flexBasis: mobileTitleBarHeight,
      height: mobileTitleBarHeight,
    },

    "& img": {
      verticalAlign: "middle",
    },
  },

  pageBodyContainer: {
    height: "100%",
  },

  root: {
    height: "100%",
  },
}));

export const useLogoStyles = makeStyles((theme: Theme) => ({
  logoContainer: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    justifyContent: "center",
    height: 100,
    pointerEvents: "none",
    userSelect: "none",
  },

  mobileLogo: {
    height: 50,
  },
}));

export const useNavBarStyles = makeStyles((theme: Theme) => ({
  navItemLabel: {
    textTransform: "uppercase",
  },

  navListRoot: {
    padding: 0,
  },
}));

export const usePageTitleBarStyles = makeStyles((theme: Theme) => ({
  drawerToggleButton: {
    display: "inline",
    position: "absolute",
    left: 0,
  },

  logo: {
    flexGrow: 1,
    textAlign: "center",
  },

  mobileHeader: {
    display: "flex",
    position: "relative",
  },

  titleBar: {
    alignItems: "center",
    display: "flex",
    height: "100%",
  },

  titleBarText: {
    flexGrow: 3,
    textTransform: "uppercase",
  },
  usernameContainer: {
    minWidth: 150,
    paddingRight: 25,
  },
  usernamePersonIcon: {
    paddingRight: 5,
    verticalAlign: "middle",
  },
  usernameText: {
    verticalAlign: "middle",
  },
}));

export const theme = responsiveFontSizes(
  createMuiTheme({
    overrides: {
      MuiCssBaseline: {
        "@global": {
          body: {
            margin: 0,
            height: "100%",
            overflowX: "hidden",
          },
          html: {
            height: "100%",
            overflowX: "hidden",
          },
          "div#root": {
            height: "100%",
          },
        },
      },
    },
    palette: {
      background: {
        default: "#E2DAC7",
      },
      primary: {
        light: "#E2DAC7",
        main: "#998C83",
        dark: "#000000",
      },
    },
  }),
  {
    breakpoints: ["xs", "sm", "md", "lg"],
    factor: 1,
  }
);
