import { createAsyncAction, createStandardAction } from "typesafe-actions";

import { IDocument } from "../types/documents";

export const doLoadDocumentsForProperty = createAsyncAction(
  "ESSKAY/PROPERTIES/DOCUMENTS/LIST/REQUEST",
  "ESSKAY/PROPERTIES/DOCUMENTS/LIST/SUCCESS",
  "ESSKAY/PROPERTIES/DOCUMENTS/LIST/ERROR"
)<string, Array<IDocument>, Error>();

export const doDownloadDocument = createStandardAction(
  "ESSKAY/PROPERTIES/DOCUMENTS/DOWNLOAD/REQUEST"
)<string>();
