import { createAsyncAction, createStandardAction } from "typesafe-actions";

import { IAccountEntry } from "../types/account";

export const doLoadAccountsListForUnit = createAsyncAction(
  "ESSKAY/UNITS/ACCOUNTS/ENTRYLIST/REQUEST",
  "ESSKAY/UNITS/ACCOUNTS/ENTRYLIST/SUCCESS",
  "ESSKAY/UNITS/ACCOUNTS/ENTRYLIST/FAILURE"
)<string, Array<IAccountEntry>, Error>();

export const doDownloadInvoice = createStandardAction(
  "ESSKAY/UNITS/ACCOUNTS/INVOICE/DOWNLOAD"
)<string>();
