import { call, put, take } from "redux-saga/effects";
import { getType } from "typesafe-actions";

import { AnyAction } from "../actions";
import { resetApp } from "../actions/init";
import {
  beginSession,
  endSession,
  setSessionAdminUser,
  setSessionState,
} from "../actions/session";
import { AuthService } from "../lib/api/auth";
import { PortalService } from "../lib/api/portal";
import { RegistrationService } from "../lib/api/registration";
import { RootState } from "./";

export enum SessionState {
  NOT_INITIALISED = 0,
  INACTIVE = 1,
  ACTIVE = 2,
}

interface ISessionState {
  adminUser: boolean;
  state: SessionState;
}

const initialState = {
  adminUser: false,
  state: SessionState.NOT_INITIALISED,
};

export const session = (
  state: ISessionState = initialState,
  action: AnyAction
) => {
  switch (action.type) {
    case getType(setSessionState):
      return Object.assign({}, state, { state: action.payload });
    case getType(setSessionAdminUser):
      return Object.assign({}, state, { adminUser: action.payload });
    default:
      return state;
  }
};

export const selectSessionState = (state: RootState) => state.session.state;
export const selectSessionAdminUser = (state: RootState) =>
  state.session.adminUser;

function* handleSessionBegin(
  authAPI: AuthService,
  portalAPI: PortalService,
  registrationAPI: RegistrationService,
  session: ReturnType<typeof beginSession>
) {
  yield call(authAPI.setAPIKey, session.payload.key);
  yield call(portalAPI.setAPIKey, session.payload.key);
  yield call(registrationAPI.setAPIKey, session.payload.key);
  yield put(setSessionAdminUser(session.payload.adminUser));
  yield put(setSessionState(SessionState.ACTIVE));
}

function* handleSessionEnd(
  authAPI: AuthService,
  portalAPI: PortalService,
  registrationAPI: RegistrationService
) {
  yield put(setSessionState(SessionState.INACTIVE));
  yield call(authAPI.setAPIKey, "");
  yield call(portalAPI.setAPIKey, "");
  yield call(registrationAPI.setAPIKey, "");
  yield put(resetApp());
}

export function* sessionSaga(
  authAPI: AuthService,
  portalAPI: PortalService,
  registrationAPI: RegistrationService
) {
  while (true) {
    const beginRequest = yield take(getType(beginSession));
    yield call(
      handleSessionBegin,
      authAPI,
      portalAPI,
      registrationAPI,
      beginRequest
    );
    yield take(getType(endSession));
    yield call(handleSessionEnd, authAPI, portalAPI, registrationAPI);
  }
}
