import { createAsyncAction, createStandardAction } from "typesafe-actions";

import { IForgotRequest, ISession, ISetPasswordRequest } from "../types/auth";

export const doForgotSubmit = createAsyncAction(
  "AUTH/FORGOT/REQUEST",
  "AUTH/FORGOT/SUCCESS",
  "AUTH/FOROGT/ERROR"
)<IForgotRequest, undefined, Error>();

export interface ILoginRequest {
  username: string;
  password: string;
  persistent: boolean;
}

export interface ILoginSuccess {
  session: ISession;
  redirect: boolean;
}

export const doLogin = createAsyncAction(
  "AUTH/LOGIN/REQUEST",
  "AUTH/LOGIN/SUCCESS",
  "AUTH/LOGIN/ERROR"
)<ILoginRequest, ILoginSuccess, Error>();

export const doLogout = createStandardAction("AUTH/LOGOUT")<undefined>();

export const doSetUserPassword = createAsyncAction(
  "AUTH/USER/PASSWORD/SET/REQUEST",
  "AUTH/USER/PASSWORD/SET/SUCCESS",
  "AUTH/USER/PASSWORD/SET/FAILURE"
)<ISetPasswordRequest, undefined, Error>();
