import { createAsyncAction, createStandardAction } from "typesafe-actions";

import { UserProfile } from "../types/profiles";

export const listTenantUserProfiles = createAsyncAction(
  "ESSKAY/TENANTS/USER_PROFILES/LIST/REQUEST",
  "ESSKAY/TENANTS/USER_PROFILES/LIST/SUCCESS",
  "ESSKAY/TENANTS/USER_PROFILES/LIST/FAILURE"
)<string, UserProfile[], Error>();

export const setTenantUserProfile = createStandardAction(
  "ESSKAY/TENANTS/USER_PROFILES/SET"
)<UserProfile>();
