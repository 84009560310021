import { AxiosError, AxiosInstance, AxiosResponse } from "axios";
import * as HttpStatus from "http-status-codes";
import Moment from "moment";
import queryString from "query-string";

import {
  IForgotRequest,
  ISession,
  ISetPasswordRequest,
} from "../../types/auth";
import { Service } from "./";
import { GRPCError } from "./errors";

const USERNAME_VALIDATOR = new RegExp("^[a-zA-Z0-9-_.]+$");

export function isValidUsername(username: string) {
  return USERNAME_VALIDATOR.test(username);
}

const mapSessionResponse = (session: any) => ({
  key: session.token,
  username: session.username,
  adminUser: session.admin_user,
  expireTime: Moment(session.expires),
  emailAddress: session.email_address,
});

export class AuthService extends Service {
  public forgotPasswordRequest = (username: string) =>
    doForgotPasswordRequest(this.axios, username);
  public getPasswordResetContext = (id: number, token: string) =>
    doGetPasswordResetContext(this.axios, { id, reset_token: token });
  public login = (username: string, password: string, persistent: boolean) =>
    doLogin(this.axios, username, password, persistent);
  public resetPassword = (id: number, token: string, newPassword: string) =>
    doResetPassword(this.axios, {
      id,
      reset_token: token,
      new_password: newPassword,
    });
  public sessionInfo = (key: string) => doSessionInfo(this.axios, key);
  public setPassword = (data: ISetPasswordRequest) =>
    doSetPassword(this.axios, data);
}

function doForgotPasswordRequest(axios: AxiosInstance, username: string) {
  return new Promise<IForgotRequest>((resolve, reject) => {
    return axios
      .post("/v1/auth/forgot", {
        username: username,
      })
      .then(
        (response: AxiosResponse<any>) => {
          resolve();
        },
        (error: any) => {
          reject(new Error(error));
        }
      );
  });
}

interface GetPasswordResetContextRequest {
  id: number;
  reset_token: string;
}

interface GetPasswordResetContextResponse {
  username: string;
}

function mapGetPasswordResetContextResponse(data: any) {
  return {
    username: data.username ?? "",
  };
}

function doGetPasswordResetContext(
  axios: AxiosInstance,
  req: GetPasswordResetContextRequest
) {
  const qs = queryString.stringify(req);

  return new Promise<GetPasswordResetContextResponse>((resolve, reject) => {
    return axios.get(`/v1/auth/get_reset_context?${qs}`).then(
      (response: AxiosResponse<any>) => {
        resolve(mapGetPasswordResetContextResponse(response.data));
      },
      (error: AxiosError<GRPCError>) => {
        if (error.response?.status === HttpStatus.NOT_FOUND) {
          reject(
            new Error(
              "Password reset token was not found, has expired or is invalid."
            )
          );
        }
        reject(new Error(error.message));
      }
    );
  });
}

function doLogin(
  axios: AxiosInstance,
  username: string,
  password: string,
  persistent: boolean = false
) {
  return new Promise<ISession>((resolve, reject) => {
    return axios
      .post("/v1/auth/login", {
        username: username,
        password: password,
        persistent: persistent,
      })
      .then(
        (response: AxiosResponse<any>) => {
          resolve(mapSessionResponse(response.data.session));
        },
        (error: AxiosError<GRPCError>) => {
          if (error.response?.status === HttpStatus.UNAUTHORIZED) {
            reject(new Error("Invalid username or password"));
            return;
          }
          reject(new Error(error.message));
        }
      );
  });
}

export interface ResetPasswordRequest {
  id: number;
  reset_token: string;
  new_password: string;
}

export interface ResetPasswordResponse {}

function doResetPassword(axios: AxiosInstance, req: ResetPasswordRequest) {
  return axios.post("/v1/auth/reset_password", req);
}

function doSessionInfo(axios: AxiosInstance, key: string) {
  return new Promise<ISession>((resolve, reject) => {
    return axios
      .get("/v1/auth/session_info", {
        headers: {
          Authorization: "Bearer " + key,
        },
      })
      .then(
        (response: AxiosResponse<any>) => {
          resolve(mapSessionResponse(response.data));
        },
        (error: any) => {
          reject(new Error(error));
        }
      );
  });
}

function doSetPassword(
  axios: AxiosInstance,
  { oldPassword, newPassword, userID }: ISetPasswordRequest
) {
  return new Promise<ISession>((resolve, reject) => {
    const data = {
      user_id: userID,
      new_password: newPassword,
      old_password: oldPassword,
    };

    return axios.post("/v1/auth/user/password", data).then(
      (response: AxiosResponse<any>) => {
        resolve(response.data);
      },
      (error: any) => {
        reject(new Error(error));
      }
    );
  });
}
