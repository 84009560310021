import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import Alert from "@material-ui/lab/Alert";
import { TextField } from "final-form-material-ui";
import React from "react";
import { Field, Form } from "react-final-form";
import { useDispatch, useSelector } from "react-redux";
import { Link as RouterLink } from "react-router-dom";

import { doForgotSubmit } from "../actions/auth";
import {
  selectForgotFormError,
  selectForgotFormStatus,
} from "../reducers/forgot";
import { FormSubmitStatus } from "../reducers/types";
import { useForgotFormStyles } from "./styles/forgot-form.style";
import { useCommonLoginLayoutStyles } from "./styles/login-page-layout.style";

interface IForgotFormProps {
  error?: Error;
  submitStatus: FormSubmitStatus;
}

const useStyles = () =>
  Object.assign({}, useCommonLoginLayoutStyles(), useForgotFormStyles());

const ForgotForm: React.StatelessComponent<IForgotFormProps> = ({
  error,
  submitStatus,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  type FormContext = {
    username: string;
  };

  const initialValues: FormContext = {
    username: "",
  };

  const handleSubmit = (values: FormContext) => {
    dispatch(doForgotSubmit.request({ username: values.username }));
  };

  const formSubmitting = submitStatus === FormSubmitStatus.Submitting;
  const formSubmitted = submitStatus === FormSubmitStatus.Submitted;

  return (
    <Form
      initialValues={initialValues}
      onSubmit={handleSubmit}
      render={({ handleSubmit, pristine, invalid }) => (
        <form onSubmit={handleSubmit} className={classes.form}>
          <Grid container direction="column">
            <Typography>
              To recover your forgotten password, please enter your username or
              your registered email address. We will email you a link to click
              to reset your password.
            </Typography>
            <Field
              component={TextField}
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="username"
              label="Username"
              name="username"
              autoComplete="username"
              autoFocus
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submitButton}
              disabled={formSubmitting || formSubmitted || pristine || invalid}
            >
              {formSubmitting ? (
                <Container className={classes.submitButtonContainer}>
                  <CircularProgress
                    className={classes.submitButtonSpinner}
                    size={20}
                  />
                  Resetting password
                </Container>
              ) : (
                "Reset Password"
              )}
            </Button>
            {submitStatus === FormSubmitStatus.Submitted && (
              <Alert severity="info">
                Please check your email for a link to reset your password.
              </Alert>
            )}
            {error && <Alert severity="error">{error.message}</Alert>}
          </Grid>
        </form>
      )}
    />
  );
};

const ForgotFormSelector: React.FC = () => {
  const classes = useStyles();
  const submitStatus = useSelector(selectForgotFormStatus);
  const resetError = useSelector(selectForgotFormError);

  return (
    <>
      <ForgotForm submitStatus={submitStatus} error={resetError} />
      <Grid container className={classes.footerLink}>
        <Grid item xs>
          <Link component={RouterLink} to="/login" variant="body2">
            &laquo; Back to login
          </Link>
        </Grid>
      </Grid>
    </>
  );
};

export default ForgotFormSelector;
