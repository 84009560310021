import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";

import { doLoadNewsForProperty } from "../../actions/news";
import { RootState } from "../../reducers";
import { selectNewsForProperty } from "../../reducers/news";
import { selectPropertyByCode } from "../../reducers/property";
import { IUnit } from "../../types/units";
import { UnitHomePage } from "./page";

export interface IOwnProps {
  unit?: IUnit;
}

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      loadNews: (propertyCode: string) =>
        doLoadNewsForProperty.request(propertyCode),
    },
    dispatch
  );

const mapStateToProps = (state: RootState, ownProps: IOwnProps) => {
  const { unit } = ownProps;

  const selectNews = (state: RootState) => {
    const news = unit
      ? selectNewsForProperty(unit.propertyCode)(state).toJS()
      : [];
    return news.sort((a, b) => -a.date.diff(b.date));
  };

  return {
    news: selectNews(state),
    property: unit ? selectPropertyByCode(state, unit.propertyCode) : undefined,
  };
};

export type Props = IOwnProps &
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

export const UnitHomePageContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(UnitHomePage);
