import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@material-ui/core";
import { makeStyles, Theme } from "@material-ui/core/styles";
import classNames from "classnames";
import React from "react";

import { FilterableKeys, FilterableValue, filterer } from "./filter";
import { HeadCell, IHeadCell } from "./head-cell";
import { ExtractMatchingObject } from "./util";

export type Order = "asc" | "desc";

function desc<T>(a: T, b: T) {
  if (b < a) {
    return -1;
  }
  if (b > a) {
    return 1;
  }
  return 0;
}

type SortableValue = string;
export type SortableKeys<T> = keyof ExtractMatchingObject<T, SortableValue>;

function getSorting<T>(
  order: Order,
  orderBy?: SortableKeys<T> | null
): (a: T, b: T) => number {
  if (!orderBy) {
    return (a, b) => 0;
  }

  return order === "desc"
    ? (a, b) => desc(a[orderBy], b[orderBy])
    : (a, b) => -desc(a[orderBy], b[orderBy]);
}

type BodyProps<T> = {
  items: T[];
};

type ComplexTableProps<T, FilterKeys, SortKeys> = {
  children: React.ComponentType<BodyProps<T>>;
  classes?: Partial<{
    border: string;
    head: string;
  }>;
  filterFields: FilterKeys[];
  headCells: IHeadCell<T>[];
  items: T[];
  initialSort?: SortKeys;
  initialSortDirection?: Order;
  searchTerm: string;
};

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    backgroundColor: "#ffffff",
  },
}));

export function ComplexTable<
  T extends ExtractMatchingObject<T, FilterableValue>
>({
  children: Children,
  classes = {},
  filterFields,
  headCells,
  initialSort,
  initialSortDirection = "asc",
  items,
  searchTerm,
}: ComplexTableProps<T, FilterableKeys<T>, SortableKeys<T>>) {
  type SortKeys = SortableKeys<T>;

  const [order, setOrder] = React.useState<Order>(initialSortDirection);
  const [orderBy, setOrderBy] = React.useState<SortKeys | null | undefined>(
    initialSort
  );
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleRequestSort = (property: SortKeys) => {
    const isDesc = orderBy === property && order === "desc";
    setOrder(isDesc ? "asc" : "desc");
    setOrderBy(property);
    setPage(0);
  };

  const createSortHandler = (property: SortKeys) => () => {
    handleRequestSort(property);
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const sorter = (orderBy?: SortKeys | null) => getSorting<T>(order, orderBy);

  const displayItems = items
    .filter(filterer<T>(filterFields, searchTerm))
    .sort(sorter(orderBy))
    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  const localClasses = useStyles();

  return (
    <>
      <TableContainer className={localClasses.container}>
        <Table size="small" stickyHeader aria-label="documents table">
          <TableHead>
            <TableRow className={classNames(classes.head, classes.border)}>
              {headCells.map((headCell) => (
                <HeadCell
                  currentOrder={order}
                  currentOrderBy={orderBy}
                  key={`table-headcell-${headCell.id}`}
                  onSort={createSortHandler(headCell.id)}
                  {...headCell}
                />
              ))}
            </TableRow>
          </TableHead>
          <TableBody className={classNames(classes.border)}>
            <Children items={displayItems} />
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={items.length}
        rowsPerPage={rowsPerPage}
        page={page}
        backIconButtonProps={{
          "aria-label": "previous page",
        }}
        nextIconButtonProps={{
          "aria-label": "next page",
        }}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </>
  );
}
