import {
  all,
  call,
  fork,
  put,
  takeEvery,
  takeLeading,
} from "redux-saga/effects";
import { getType } from "typesafe-actions";

import { AnyAction } from "../actions";
import { doGetUnit, doLoadUnits } from "../actions/units";
import { PortalService } from "../lib/api/portal";
import { IUnit, IUnits } from "../types/units";
import { RootState } from "./";

interface IUnitsState {
  readonly units?: Map<string, IUnit>;
  readonly loading: boolean;
  readonly error?: Error;
}

const initialState: IUnitsState = {
  loading: false,
};

const toUnitMap = (payload: IUnits) =>
  new Map<string, IUnit>(
    payload ? payload.map((unit) => [unit.code, unit]) : null
  );

const setLoadingStatus = (status: boolean, state: IUnitsState) =>
  Object.assign({}, state, { loading: status });

const mergeError = (state: IUnitsState, error: Error) =>
  setLoadingStatus(false, Object.assign({}, state, { error }));

const mergeUnits = (state: IUnitsState, units: IUnits) => {
  const unitMap = toUnitMap(units);
  return {
    loading: false,
    units: unitMap,
  };
};

export const units = (
  state: IUnitsState = initialState,
  action: AnyAction
): IUnitsState => {
  switch (action.type) {
    // Request errors
    case getType(doGetUnit.failure):
      return mergeError(state, action.payload);
    case getType(doLoadUnits.failure):
      return mergeError(state, action.payload);

    // Loading
    case getType(doLoadUnits.request):
      return setLoadingStatus(true, state);
    case getType(doGetUnit.request):
      return setLoadingStatus(true, state);

    // Retrieved units
    case getType(doGetUnit.success):
      return mergeUnits(state, [action.payload]);
    case getType(doLoadUnits.success):
      return mergeUnits(state, action.payload);

    default:
      return state;
  }
};

function* getUnitSaga(portalAPI: PortalService) {
  function* handleGetUnitRequest(req: ReturnType<typeof doGetUnit.request>) {
    try {
      const res: IUnit = yield call(portalAPI.getUnit, req.payload);
      yield put(doGetUnit.success(res));
    } catch (err) {
      yield put(doGetUnit.failure(err));
    }
  }

  function* watchGetUnit() {
    yield takeEvery(getType(doGetUnit.request), handleGetUnitRequest);
  }

  yield fork(watchGetUnit);
}

function* loadUnitsSaga(portalAPI: PortalService) {
  function* handleLoadUnitsRequest(
    req: ReturnType<typeof doLoadUnits.request>
  ) {
    try {
      const res: IUnits = yield call(portalAPI.listUnits);
      yield put(doLoadUnits.success(res));
    } catch (err) {
      yield put(doLoadUnits.failure(err));
    }
  }

  function* watchLoadUnits() {
    yield takeLeading(getType(doLoadUnits.request), handleLoadUnitsRequest);
  }

  yield fork(watchLoadUnits);
}

export function* unitsSaga(portalAPI: PortalService) {
  yield all([fork(getUnitSaga, portalAPI), fork(loadUnitsSaga, portalAPI)]);
}

export const selectUnits = (state: RootState) =>
  state.units.units ? Array.from(state.units.units.values()) : [];
export const selectUnitByCode = (state: RootState, code: string) =>
  state.units.units ? state.units.units.get(code) : undefined;
