import { Grid, Typography } from "@material-ui/core";
import { makeStyles, Theme } from "@material-ui/core/styles";
import React from "react";
import { Form } from "react-final-form";
import { useDispatch, useSelector } from "react-redux";

import { doSetUserPassword } from "../../actions";
import { selectSettingPassword } from "../../reducers/auth";
import ContentBox from "../../widgets/content-box";
import {
  FormField,
  SubmitWidget,
} from "../profile/sections/page-section-widget";

const useStyles = makeStyles((theme: Theme) => ({
  helpText: {
    marginTop: theme.spacing(1),
  },
}));

export const ChangePasswordPage: React.FunctionComponent = () => {
  interface FormContext {
    oldPassword: string;
    newPassword: string;
    confirmPassword: string;
  }

  const dispatch = useDispatch();

  const onSubmit = (values: FormContext) =>
    dispatch(
      doSetUserPassword.request({
        oldPassword: values.oldPassword,
        newPassword: values.newPassword,
      })
    );
  const isSaving = useSelector(selectSettingPassword);

  const validateForm = ({
    oldPassword,
    newPassword,
    confirmPassword,
  }: FormContext) => {
    if (!oldPassword) {
      return {
        oldPassword: "This field is required",
      };
    }

    if (!newPassword || newPassword.length < 12) {
      return {
        newPassword: "Password is too short",
      };
    }

    if (newPassword !== confirmPassword) {
      return {
        confirmPassword: "Passwords do not match",
      };
    }

    return undefined;
  };

  const classes = useStyles();

  return (
    <Form
      onSubmit={onSubmit}
      validate={validateForm}
      render={({ handleSubmit, pristine, invalid }) => (
        <form onSubmit={handleSubmit}>
          <ContentBox title="Change Password">
            <Grid container direction="column" spacing={2}>
              <Grid item xs={12} md={6}>
                <FormField
                  name="oldPassword"
                  label="Old password"
                  id="oldPassword"
                  type="password"
                  required
                />
              </Grid>
            </Grid>
            <Grid container direction="row" spacing={2}>
              <Grid item xs={12} md={6}>
                <FormField
                  name="newPassword"
                  label="New password"
                  id="newPassword"
                  type="password"
                  required
                />
                <FormField
                  name="confirmPassword"
                  label="Confirm new password"
                  id="confirmPassword"
                  type="password"
                  required
                />
              </Grid>
              <Grid
                container
                item
                xs={12}
                md={6}
                justify="center"
                direction="column"
              >
                <Grid item xs>
                  <Typography
                    classes={{ root: classes.helpText }}
                    gutterBottom
                    variant="body2"
                  >
                    Use at least 12 characters. For security, avoid passwords
                    you use on other sites or anything obvious like your name,
                    address or telephone number.
                  </Typography>
                </Grid>
                <Grid item>
                  <SubmitWidget
                    disabled={pristine || invalid}
                    isSaving={isSaving}
                    label="Change password"
                  />
                </Grid>
              </Grid>
            </Grid>
          </ContentBox>
        </form>
      )}
    />
  );
};
