import { useContext } from "react";

import { APIContext } from "../";

export const usePortalAPI = () => {
  const apis = useContext(APIContext);
  return apis.portal;
};

export const useAuthAPI = () => {
  const apis = useContext(APIContext);
  return apis.auth;
};

export const useRegistrationAPI = () => {
  const apis = useContext(APIContext);
  return apis.registration;
};
