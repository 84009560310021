import { Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import loglevel from "loglevel";
import React from "react";

import { DataVersion as IDataVersion } from "../lib/api/portal";
import { usePortalAPI } from "../lib/hooks";

export const DataVersion: React.FC<{
  children(arg0: { version: IDataVersion }): React.ReactElement;
}> = ({ children }) => {
  const portalAPI = usePortalAPI();
  const [dataVersion, setDataVersion] = React.useState<IDataVersion | null>(
    null
  );

  React.useEffect(() => {
    const getDataVersion = async () => {
      try {
        const dataVersion = await portalAPI.getDataVersion();
        setDataVersion(dataVersion);
      } catch (err) {
        loglevel.error("unable to get data version: " + err);
        setDataVersion(null);
      }
    };

    getDataVersion();
  }, [portalAPI, setDataVersion]);

  if (!dataVersion) {
    return null;
  }

  return children({ version: dataVersion });
};

const useStyles = makeStyles((theme: Theme) => ({
  dataUpdateTime: {
    textAlign: "center",
    fontSize: "x-small",
    color: theme.palette.primary.dark,
    flexGrow: 0,
    marginBottom: theme.spacing(1),
  },
}));

export const DataLastUpdated: React.FC = () => {
  const classes = useStyles();

  return (
    <div className={classes.dataUpdateTime}>
      <DataVersion>
        {({ version }) => (
          <span>
            Information last updated on{" "}
            {version.dataVersion.format("YYYY-MM-DD")}
          </span>
        )}
      </DataVersion>
    </div>
  );
};
