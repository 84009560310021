import { createAsyncAction, createStandardAction } from "typesafe-actions";

import {
  CompleteRegistrationRequest,
  InviteAuthDetails,
} from "../lib/api/registration";
import { RegistrationStatus } from "../reducers/registration";
import { TenantInvite } from "../types/registration";

export const doCompleteRegistration = createAsyncAction(
  "ESSKAY/TENANTS/INVITE/REGISTER/REQUEST",
  "ESSKAY/TENANTS/INVITE/REGISTER/SUCCESS",
  "ESSKAY/TENANTS/INVITE/REGISTER/FAILURE"
)<CompleteRegistrationRequest, null, Error>();

export const doResolveTenantInviteDetails = createAsyncAction(
  "ESSKAY/TENANTS/INVITE/RESOLVE/REQUEST",
  "ESSKAY/TENANTS/INVITE/RESOLVE/SUCCESS",
  "ESSKAY/TENANTS/INVITE/RESOLVE/FAILURE"
)<InviteAuthDetails, TenantInvite, Error>();

export const doSetRegistrationStatus = createStandardAction(
  "ESSKAY/TENANTS/INVITE/STATUS"
)<RegistrationStatus>();
