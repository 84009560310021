import { createStandardAction } from "typesafe-actions";

import { SessionState } from "../reducers/session";
import { ISession } from "../types/auth";

export const beginSession = createStandardAction(
  "ESSKAY/SESSION/BEGIN"
)<ISession>();

export const endSession = createStandardAction(
  "ESSKAY/SESSION/END"
)<undefined>();

export const setSessionState = createStandardAction(
  "ESSKAY/SESSION/ACTIVESTATE"
)<SessionState>();

export const setSessionAdminUser = createStandardAction(
  "ESSKAY/SESSION/ADMIN_USER"
)<boolean>();
