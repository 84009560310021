import {
  Button,
  CircularProgress,
  Grid,
  Link,
  Typography,
} from "@material-ui/core";
import { green } from "@material-ui/core/colors";
import { makeStyles, Theme } from "@material-ui/core/styles";
import Alert from "@material-ui/lab/Alert";
import { TextField } from "final-form-material-ui";
import React from "react";
import { Form } from "react-final-form";
import { Field } from "react-final-form-html5-validation";
import { Link as RouterLink } from "react-router-dom";

import { isValidUsername } from "../../lib/api/auth";
import { CompleteRegistrationRequest } from "../../lib/api/registration";
import { RegistrationStatus } from "../../reducers/registration";
import { Invite } from "../../types/registration";
import { ITenant } from "../../types/tenants";
import { timeout } from "../../utils/sleep";
import ConfirmPasswordFields from "../../widgets/password-form/confirm-password-fields";
import { CheckUsernameStatus } from "./page";

export type Props = {
  handleCheckUsername: (arg1: string) => Promise<CheckUsernameStatus>;
  invite?: Invite;
  onSubmit: (arg1: Partial<CompleteRegistrationRequest>) => void;
  status?: RegistrationStatus;
  tenant?: ITenant;
};

const useFormStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    position: "relative",
    margin: theme.spacing(1),
  },

  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },

  passwordContainer: {
    textAlign: "center",
  },

  formTextField: {
    marginBottom: theme.spacing(3),
    maxWidth: 400,
  },

  tenantName: {
    fontWeight: "bold",
    textAlign: "center",
  },
}));

function getDefaultUsername(email?: string) {
  if (email) {
    return email.substring(0, email.indexOf("@"));
  }

  return "";
}

export const RegisterForm: React.FunctionComponent<Props> = ({
  handleCheckUsername,
  invite,
  onSubmit,
  status = RegistrationStatus.UNKNOWN,
  tenant,
}) => {
  const classes = useFormStyles();

  type FormContext = {
    emailAddress?: string;
    username?: string;
    password?: string;
    confirmPassword?: string;
  };

  if (!tenant) {
    return null;
  }

  const defaultUsername = getDefaultUsername(tenant.contactDetails.emailMain);
  const disableEmailInForm = invite?.emailAddress != null;

  const initialValues = {
    emailAddress: invite?.emailAddress,
    username: defaultUsername,
    password: "",
    confirmPassword: "",
  };

  const handleSubmit = (values: FormContext) => {
    onSubmit({
      emailAddress: values.emailAddress,
      username: values.username,
      password: values.password,
    });
  };

  const registering = status === RegistrationStatus.REGISTERING;
  const registered = status === RegistrationStatus.REGISTERED;

  const validateForm = async function (values: FormContext) {
    const errors: Partial<Record<keyof FormContext, string>> = {};

    if (values.password !== values.confirmPassword) {
      errors.confirmPassword =
        "The password and confirm passwords do not match.";
    }

    return errors;
  };

  const validateUsername = async function (username: string) {
    await timeout(1000);

    if (!username || !isValidUsername(username)) {
      return "The username is invalid. Please use only the characters a-z, 0-9, - and _.";
    } else {
      const result = await handleCheckUsername(username);
      if (result === CheckUsernameStatus.UNAVAILABLE) {
        return "Sorry, that username is not available.";
      }
    }

    return undefined;
  };

  return (
    <Form
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validate={validateForm}
      render={({ handleSubmit, pristine, invalid, values }) => (
        <form onSubmit={handleSubmit}>
          <Grid container direction="column">
            <Grid item xs>
              <Typography gutterBottom>
                This profile will be used to access, edit and manage properties
                owned by:
              </Typography>
              <Typography
                classes={{ root: classes.tenantName }}
                component="p"
                gutterBottom
                paragraph
                variant="h6"
              >
                {tenant.name}
              </Typography>
            </Grid>

            <Grid item xs></Grid>

            <Grid item xs={12}>
              <Grid
                container
                direction="column"
                classes={{ root: classes.passwordContainer }}
              >
                <Grid item md>
                  <Typography>
                    Please select a username. This should be any combination of
                    uppercase and lowercase characters or numbers. For technical
                    reasons, it cannot currently be your email address.
                  </Typography>
                  <Field
                    component={TextField}
                    name="username"
                    type="text"
                    id="username"
                    variant="outlined"
                    label="Username"
                    required
                    margin="normal"
                    fullWidth
                    autoComplete="username"
                    classes={{ root: classes.formTextField }}
                    validate={validateUsername}
                    validateFields={["username"]}
                  />
                </Grid>
                <Grid item md>
                  <Field
                    component={TextField}
                    name="emailAddress"
                    type="email"
                    id="email_address"
                    variant="outlined"
                    label="Email address"
                    required
                    margin="normal"
                    fullWidth
                    autoComplete={disableEmailInForm ? "on" : "off"}
                    classes={{ root: classes.formTextField }}
                    disabled={disableEmailInForm}
                    validateFields={["emailAddress"]}
                  />
                </Grid>
                <Grid item md>
                  <Typography>
                    Your password may be any combination of letters, numbers or
                    symbols. We recommend passwords of at least 12 characters.
                  </Typography>
                  <ConfirmPasswordFields
                    classes={{ root: classes.formTextField }}
                    passwordFieldNames={{
                      id: "password",
                      label: "Password",
                      name: "password",
                      validateFields: ["password", "confirmPassword"],
                    }}
                    confirmPasswordFieldNames={{
                      id: "confirmPassword",
                      label: "Confirm Password",
                      name: "confirmPassword",
                      validateFields: ["password", "confirmPassword"],
                    }}
                  />
                </Grid>

                <Grid item md classes={{ root: classes.wrapper }}>
                  <Button
                    color="primary"
                    disabled={pristine || invalid || registering}
                    type="submit"
                    variant="contained"
                  >
                    Get started
                  </Button>
                  {registering && (
                    <CircularProgress
                      size={24}
                      className={classes.buttonProgress}
                    />
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs>
              {registered && (
                <>
                  <Alert severity="info">
                    Registration complete! You may now log in.
                  </Alert>
                  <Link component={RouterLink} to="/app" variant="body2">
                    Go to login page
                  </Link>
                </>
              )}
            </Grid>
          </Grid>
        </form>
      )}
    />
  );
};
