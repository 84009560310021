import {
  Button,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import green from "@material-ui/core/colors/green";
import grey from "@material-ui/core/colors/grey";
import red from "@material-ui/core/colors/red";
import { makeStyles, Theme, useTheme } from "@material-ui/core/styles";
import { Close, DeleteForever, Done, FileCopy } from "@material-ui/icons";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import copy from "copy-to-clipboard";
import { TextField } from "final-form-material-ui";
import React, { PropsWithChildren, useEffect, useState } from "react";
import { Field, Form } from "react-final-form";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import {
  listTenantUserProfiles,
  setTenantUserProfile,
} from "../../actions/profiles";
import { doLoadTenantDetails } from "../../actions/tenants";
import { useAuthAPI, usePortalAPI, useRegistrationAPI } from "../../lib/hooks";
import {
  selectUserProfilesError,
  selectUserProfilesForTenant,
  selectUserProfilesLoading,
} from "../../reducers/profiles";
import { selectTenant } from "../../reducers/tenants";
import { UserProfile } from "../../types/profiles";
import { ITenant } from "../../types/tenants";

interface InviteAuthDetails {
  inviteID: string;
  inviteToken: string;
}

type Props = {
  id: string;
};

const useStyles = makeStyles((theme: Theme) => ({
  backButton: {
    marginRight: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },
  container: {
    marginTop: theme.spacing(2),
    padding: `${theme.spacing(2)}px ${theme.spacing(8)}px`,

    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(2),
    },
  },
  field: {
    fontWeight: "bold",
  },
  noData: {
    color: grey[600],
    textDecoration: "italic",
  },
  divider: {
    backgroundColor: theme.palette.text.hint,
    margin: theme.spacing(1),
  },
}));

type TenantInfoFieldProps = {
  nameField?: boolean;
};

const TenantInfoField: React.FunctionComponent<
  PropsWithChildren<TenantInfoFieldProps>
> = ({ nameField, children }) => {
  const classes = useStyles();

  const props = {
    className: nameField ? classes.field : undefined,
  };

  return (
    <Grid item xs={12} sm={12} md={nameField ? 2 : 10} {...props}>
      {children}
    </Grid>
  );
};

type ListItemCheckCrossProps = {
  value: boolean;
};

const ListItemCheckCross: React.FunctionComponent<ListItemCheckCrossProps> = ({
  value,
}) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));

  if (!matches) {
    return null;
  }

  return <ListItemIcon>{value ? <Done /> : <Close />}</ListItemIcon>;
};

const useProfilesStyles = makeStyles((theme: Theme) => ({
  buttonMargin: {
    marginRight: theme.spacing(1),
  },
  deleteButton: {
    backgroundColor: red[600],
  },
}));

type UserProfileRowProps = {
  profile: UserProfile;
};

enum ProfileDeletionStatus {
  NOT_ACTIVE = 0,
  CONFIRM = 1,
  DELETING = 2,
  DELETED = 3,
}

enum PasswordResettingStatus {
  NOT_ACTIVE = 0,
  CONFIRM = 1,
  RESETTING = 2,
  RESET = 3,
}

const UserProfileRow: React.FunctionComponent<UserProfileRowProps> = ({
  profile,
}) => {
  const classes = useProfilesStyles();
  const dispatch = useDispatch();

  const [deleteState, setDeleteState] = useState<ProfileDeletionStatus>(
    ProfileDeletionStatus.NOT_ACTIVE
  );
  const [deleteError, setDeleteError] = useState<Error | undefined>(undefined);

  const [resettingState, setResettingState] = useState<PasswordResettingStatus>(
    PasswordResettingStatus.NOT_ACTIVE
  );
  const [resetError, setResetError] = useState<Error | undefined>(undefined);

  const authAPI = useAuthAPI();
  const portalAPI = usePortalAPI();

  const handleDelete = () => {
    setDeleteState(ProfileDeletionStatus.DELETING);
    portalAPI
      .deleteTenantUserProfile(profile.tenantCode, profile.username)
      .then((profile) => {
        dispatch(setTenantUserProfile(profile));
        setDeleteState(ProfileDeletionStatus.DELETED);
      })
      .catch((err) => setDeleteError(err));
  };
  const handleResetPassword = () => {
    setResettingState(PasswordResettingStatus.RESETTING);
    authAPI
      .forgotPasswordRequest(profile.username)
      .then(() => {
        setResettingState(PasswordResettingStatus.RESET);
        setTimeout(() => {
          setResettingState(PasswordResettingStatus.NOT_ACTIVE);
        }, 2000);
      })
      .catch((err) => setResetError(err));
  };

  const cancelDeletion = () => {
    setDeleteState(ProfileDeletionStatus.NOT_ACTIVE);
  };
  const cancelResetPassword = () => {
    setResettingState(PasswordResettingStatus.NOT_ACTIVE);
  };

  return (
    <>
      <TableRow key={profile.username}>
        <TableCell component="th" scope="row">
          {profile.username}
        </TableCell>
        <TableCell>
          <Button
            classes={{ root: classes.buttonMargin }}
            onClick={() => {
              setResettingState(PasswordResettingStatus.CONFIRM);
            }}
            variant="contained"
          >
            Reset Password
          </Button>
          <Button
            onClick={() => {
              setDeleteState(ProfileDeletionStatus.CONFIRM);
            }}
            startIcon={<DeleteForever />}
          >
            Delete
          </Button>
          {deleteError && deleteError.message}
          {resetError && resetError.message}
        </TableCell>
      </TableRow>

      {/* Deletion dialog */}
      <Dialog
        open={deleteState !== ProfileDeletionStatus.NOT_ACTIVE}
        onClose={cancelDeletion}
        aria-labelledby="confirm-deletion-title"
        aria-describedby="confirm-deletion-body"
      >
        <DialogTitle id="confirm-deletion-title">{`Confirm deletion of ${profile.username}`}</DialogTitle>
        <DialogContent>
          <DialogContentText id="confirm-deletion-body">
            This will delete the user profile for this tenant with username{" "}
            <strong>{profile.username}</strong>. Are you sure?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDelete} startIcon={<DeleteForever />}>
            Confirm
          </Button>
          <Button variant="contained" onClick={cancelDeletion}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      {/* Reset Password dialog */}
      <Dialog
        open={resettingState !== PasswordResettingStatus.NOT_ACTIVE}
        onClose={cancelResetPassword}
        aria-labelledby="reset-password-title"
        aria-describedby="reset-password-body"
      >
        <DialogTitle id="reset-password-title">{`Reset password for ${profile.username}`}</DialogTitle>
        <DialogContent>
          <DialogContentText id="reset-password-body">
            This will issue a password reset notice by email to the tenant at{" "}
            their registered email address, inviting them to set a new password{" "}
            for their profile. Are you sure?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleResetPassword}>Confirm</Button>
          <Button variant="contained" onClick={cancelResetPassword}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const buildInviteURL = (inviteID: string, inviteToken: string) => {
  const search = {
    invite_token: inviteToken,
  };
  const searchParams = new URLSearchParams(search);
  return `${
    window.location.origin
  }/register/${inviteID}?${searchParams.toString()}`;
};

type DialogProps = {
  onClose: () => void;
  open: boolean;
};

type InvitationDialogProps = DialogProps & {
  handleNotifyTenant: () => void;
  inviting: boolean;
  inviteResultText: string;
  showInviteCopyButton: boolean;
};

type NotificationDialogProps = DialogProps & {
  onSubmit: (arg1: string) => void;
  notifying: boolean;
  notifyResultText?: string;
  tenant: ITenant;
};

const useInvitationDialogStyles = makeStyles((theme: Theme) => ({
  urlTextField: {
    backgroundColor: grey[200],
    overflowY: "scroll",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    fontFamily: "monospace",
  },

  copied: {
    backgroundColor: green[400],
  },
}));

const NotificationDialog: React.FunctionComponent<NotificationDialogProps> = ({
  onSubmit,
  notifyResultText,
  open,
  onClose,
  tenant,
}) => {
  type FormContext = {
    recipient?: string;
  };
  const handleSubmit = (values: FormContext) => {
    if (values.recipient) {
      onSubmit(values.recipient);
    }
  };

  const emailAddresses: string[] = [
    tenant.contactDetails.emailMain || undefined,
  ]
    .concat(tenant.contactDetails.emailCC)
    .filter((v) => v !== undefined) as string[];

  const initialValues = {};

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="notify-dialog-title"
      aria-describedby="notify-dialog-body"
    >
      <DialogTitle id="notify-dialog-title">
        Send email notification
      </DialogTitle>
      <DialogContent>
        <Form
          initialValues={initialValues}
          onSubmit={handleSubmit}
          render={({ handleSubmit, pristine, invalid }) => (
            <form onSubmit={handleSubmit}>
              <Grid
                alignItems="center"
                container
                direction="column"
                spacing={2}
              >
                <Grid item xs>
                  <Typography gutterBottom>
                    Select the email address to which the notification should be
                    dispatched.
                  </Typography>
                </Grid>
                <Grid container item spacing={1} direction="row">
                  <Grid item xs={10}>
                    <Field
                      component={TextField}
                      fullWidth
                      id="recipient"
                      label="Recipient email address"
                      margin="none"
                      name="recipient"
                      required
                      select
                      variant="outlined"
                    >
                      {emailAddresses.map((option) => (
                        <MenuItem key={option} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </Field>
                  </Grid>
                  <Grid item xs={2}>
                    <Button
                      color="primary"
                      disabled={pristine || invalid}
                      type="submit"
                      variant="contained"
                    >
                      Send
                    </Button>
                  </Grid>
                </Grid>
                <Grid item xs>
                  {notifyResultText}
                </Grid>
              </Grid>
            </form>
          )}
        />
      </DialogContent>
    </Dialog>
  );
};

const InvitationDialog: React.FunctionComponent<InvitationDialogProps> = ({
  open,
  onClose,
  inviting,
  inviteResultText,
  handleNotifyTenant,
  showInviteCopyButton,
}) => {
  const classes = useInvitationDialogStyles();
  const [copyClipboardState, setCopyClipboardState] = useState<boolean>(false);

  const copyInviteURLToClipboard = () => {
    copy(inviteResultText || "", {
      debug: true,
      format: "text/plain",
      onCopy: () => {
        setCopyClipboardState(true);
      },
    });
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="invite-dialog-title"
      aria-describedby="invite-dialog-body"
    >
      <DialogTitle id="reset-password-title">Portal invitation</DialogTitle>
      <DialogContent>
        {inviting ? (
          <Container>
            <CircularProgress size={24} />
            <Typography variant="body1">Creating invitation</Typography>
          </Container>
        ) : (
          <Grid container direction="column" spacing={1}>
            <Grid item xs container alignItems="center" spacing={1}>
              <Grid item xs={10} classes={{ root: classes.urlTextField }}>
                {inviteResultText}
              </Grid>
              <Grid item xs={2}>
                {showInviteCopyButton && (
                  <Button
                    startIcon={<FileCopy />}
                    onClick={copyInviteURLToClipboard}
                  >
                    {copyClipboardState ? "Copied" : "Copy"}
                  </Button>
                )}
              </Grid>
            </Grid>
          </Grid>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleNotifyTenant}>Send Email</Button>
        <Button onClick={onClose}>Done</Button>
      </DialogActions>
    </Dialog>
  );
};

type UserProfilesProps = {
  tenant: ITenant;
};

type InviteResult = InviteAuthDetails | Error | undefined;

const getInviteResultText = (inviteResult: InviteResult) => {
  if (inviteResult instanceof Error) {
    return inviteResult.message;
  } else if (!inviteResult) {
    return "";
  } else {
    return buildInviteURL(inviteResult.inviteID, inviteResult.inviteToken);
  }
};

const UserProfiles: React.FunctionComponent<UserProfilesProps> = ({
  tenant,
}) => {
  const profiles = Array.from(
    useSelector(selectUserProfilesForTenant(tenant.code))
  );
  const profilesError = useSelector(selectUserProfilesError);
  const profilesLoading = useSelector(selectUserProfilesLoading);

  const [openInviteDialog, setOpenInviteDialog] = useState<boolean>();
  const [inviteResult, setInviteResult] = useState<InviteResult>();
  const [inviting, setInviting] = useState<boolean>(false);

  const [openNotificationDialog, setOpenNotificationDialog] = useState<boolean>(
    false
  );
  const [notifying, setNotifying] = useState<boolean>(false);
  const [notifyResult, setNotifyResult] = useState<
    string | Error | undefined
  >();

  const registrationAPI = useRegistrationAPI();

  const closeInviteDialog = () => {
    setOpenInviteDialog(false);
  };

  const closeNotificationDialog = () => {
    closeInviteDialog();
    setOpenNotificationDialog(false);
    setNotifying(false);
    setNotifyResult(undefined);
  };

  if (profilesError) {
    return <Container>There was an error loading the user profiles.</Container>;
  }

  if (profilesLoading) {
    return (
      <Container>
        <CircularProgress size={24} /> Loading...
      </Container>
    );
  }

  const handleNewInvite = () => {
    setNotifying(false);
    setOpenInviteDialog(true);
    setInviting(true);
    registrationAPI
      .createInviteForTenant(tenant.code, false)
      .then((invite) => {
        setInviting(false);
        setInviteResult({
          inviteID: invite.inviteID,
          inviteToken: invite.inviteToken,
        });
      })
      .catch((err) => setInviteResult(err));
  };

  const handleRequestNotificationToTenant = () => {
    if (inviting || !inviteResult || inviteResult instanceof Error) {
      throw new Error("Attempted to notify before invite details available");
    } else if (notifying || openNotificationDialog) {
      return;
    }

    setOpenInviteDialog(false);
    setOpenNotificationDialog(true);
  };

  const handleSubmitSendEmailNotification = (emailAddress: string) => {
    if (inviting || !inviteResult || inviteResult instanceof Error) {
      throw new Error("Attempted to notify before invite details available");
    }

    setNotifying(true);
    registrationAPI
      .sendInviteNotificationToTenant(inviteResult, emailAddress)
      .then((resp) => {
        setNotifying(false);
        setNotifyResult(
          `Email notification dispatched to: ${resp.emailAddresses.join(", ")}`
        );
      })
      .catch((err) => setNotifyResult(err));
  };

  const inviteResultText = getInviteResultText(inviteResult);
  const notifyResultText =
    notifyResult instanceof Error ? notifyResult.message : notifyResult;
  const showInviteCopyButton = !(inviteResult instanceof Error);

  const hasProfiles = profiles.length > 0;

  return (
    <>
      <Grid container direction="column" spacing={2}>
        <Grid item xs>
          <TableContainer>
            {hasProfiles ? (
              <Table aria-label="user profiles">
                <TableBody>
                  {profiles.map((profile) => (
                    <UserProfileRow
                      key={`profile-row-${profile.tenantCode}-${profile.username}`}
                      profile={profile}
                    />
                  ))}
                </TableBody>
              </Table>
            ) : (
              <Typography>
                No profiles currently exist for this tenant.
              </Typography>
            )}
          </TableContainer>
        </Grid>
        <Grid item xs>
          <Button onClick={() => handleNewInvite()} variant="outlined">
            New invite
          </Button>
        </Grid>
      </Grid>
      <InvitationDialog
        open={openInviteDialog || false}
        onClose={closeInviteDialog}
        inviting={inviting}
        inviteResultText={inviteResultText}
        handleNotifyTenant={handleRequestNotificationToTenant}
        showInviteCopyButton={showInviteCopyButton}
      />
      <NotificationDialog
        onSubmit={handleSubmitSendEmailNotification}
        open={openNotificationDialog}
        onClose={closeNotificationDialog}
        notifying={notifying}
        notifyResultText={notifyResultText}
        tenant={tenant}
      />
    </>
  );
};

export const TenantAdmin: React.FunctionComponent<Props> = ({ id }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();

  const tenant = useSelector(selectTenant(id));

  useEffect(() => {
    dispatch(doLoadTenantDetails.request(id));
    dispatch(listTenantUserProfiles.request(id));
  }, [dispatch, id]);

  if (!tenant) {
    return null;
  }

  const NoneRecorded = () => (
    <span className={classes.noData}>None recorded</span>
  );

  return (
    <>
      <Grid container>
        <Grid item xs={12} md="auto">
          <Button
            classes={{ root: classes.backButton }}
            onClick={() => history.push("/app/admin")}
            startIcon={<ArrowBackIcon />}
            variant="contained"
          >
            Back
          </Button>
        </Grid>
        <Grid item>
          <Typography variant="h6" component="h1" gutterBottom>
            {tenant.name}
          </Typography>
        </Grid>
      </Grid>

      <Container
        maxWidth="md"
        component={Paper}
        classes={{ root: classes.container }}
      >
        <Grid container direction="column" spacing={2}>
          <Grid container item xs>
            <TenantInfoField nameField>Name</TenantInfoField>
            <TenantInfoField>{tenant.name}</TenantInfoField>
          </Grid>
          <Grid container item xs>
            <TenantInfoField nameField>Telephones</TenantInfoField>
            <TenantInfoField>
              <Grid container>
                {[
                  {
                    name: "Day",
                    data: tenant.contactDetails.phoneDay,
                  },
                  {
                    name: "Evening",
                    data: tenant.contactDetails.phoneEvening,
                  },
                  {
                    name: "Mobile",
                    data: tenant.contactDetails.phoneMobile,
                  },
                ].map((number) =>
                  number.data ? (
                    <Grid key={`telephone-${number.name}`} item xs={12}>
                      {number.name}: {number.data}
                    </Grid>
                  ) : null
                )}
              </Grid>
            </TenantInfoField>
          </Grid>
          <Grid container item xs>
            <TenantInfoField nameField>Email address</TenantInfoField>
            <TenantInfoField>
              {tenant.contactDetails.emailMain || <NoneRecorded />}
            </TenantInfoField>
          </Grid>
          <Grid container item xs>
            <TenantInfoField nameField>Email CCs</TenantInfoField>
            <TenantInfoField>
              {tenant.contactDetails.emailCC &&
              tenant.contactDetails.emailCC.length > 0 ? (
                <List dense disablePadding>
                  {tenant.contactDetails.emailCC.map((address) => (
                    <ListItem key={`cc-${address}`}>
                      <ListItemText primary={address} />
                    </ListItem>
                  ))}
                </List>
              ) : (
                <NoneRecorded />
              )}
            </TenantInfoField>
          </Grid>
          <Grid container item xs>
            <TenantInfoField nameField>Preferences</TenantInfoField>
            <TenantInfoField>
              <List dense disablePadding>
                <ListItem>
                  <ListItemCheckCross value={tenant.sendEmail} />
                  <ListItemText>
                    Prefers{tenant.sendEmail || <b> not</b>} to receive
                    communication by email
                  </ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemCheckCross value={tenant.emailReceipts} />
                  <ListItemText>
                    Prefers{tenant.sendEmail || <b> not</b>} to receive payment
                    receipts by email
                  </ListItemText>
                </ListItem>
              </List>
            </TenantInfoField>
          </Grid>

          <Divider light variant="middle" classes={{ root: classes.divider }} />

          <Grid item xs>
            <Typography variant="h5" gutterBottom>
              User profiles
            </Typography>
            <UserProfiles tenant={tenant} />
          </Grid>
        </Grid>
      </Container>
    </>
  );
};
