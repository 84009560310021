import React, { useEffect } from "react";

import UnitPageLayout from "../../widgets/unit-pages";
import { Props } from "./container";
import { UnitNewsWidget } from "./news-widget";

export const UnitHomePage: React.FunctionComponent<Props> = ({
  loadNews,
  news,
  property,
  unit,
}) => {
  useEffect(() => {
    if (unit) {
      loadNews(unit.propertyCode);
    }
  }, [loadNews, unit]);

  if (!unit || !property) {
    return null;
  }

  return (
    <UnitPageLayout title="Welcome" unitCode={unit.code}>
      <UnitNewsWidget news={news} propertyName={property.name} />
    </UnitPageLayout>
  );
};
