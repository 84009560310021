import { Moment } from "moment";
import { all, call, fork, put, takeLatest } from "redux-saga/effects";
import { getType } from "typesafe-actions";

import { AnyAction } from "../actions";
import { doLoadLastUpdate } from "../actions/metadata";
import { PortalService } from "../lib/api/portal";
import { RootState } from "./";

interface MetadataState {
  readonly lastUpdate?: Moment;
}

export const metadataReducer = (
  state: MetadataState = {},
  action: AnyAction
) => {
  switch (action.type) {
    case getType(doLoadLastUpdate.success):
      return { lastUpdate: action.payload };
    default:
      return state;
  }
};

export const selectLastUpdate = (state: RootState) => state.metadata.lastUpdate;

function* loadMetadataSaga(portalAPI: PortalService) {
  function* handleLoadMetadata(
    req: ReturnType<typeof doLoadLastUpdate.request>
  ) {
    try {
      const res = yield call(portalAPI.getDataVersion);
      yield put(doLoadLastUpdate.success(res));
    } catch (err) {
      yield put(doLoadLastUpdate.failure(err));
    }
  }

  function* watchLoadMetadata() {
    yield takeLatest(getType(doLoadLastUpdate.request), handleLoadMetadata);
  }

  yield fork(watchLoadMetadata);
}

export function* metadataSaga(portalAPI: PortalService) {
  yield all([fork(loadMetadataSaga, portalAPI)]);
}
