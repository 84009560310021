import CssBaseline from "@material-ui/core/CssBaseline";
import { ThemeProvider } from "@material-ui/core/styles";
import React from "react";
import { useSelector } from "react-redux";
import { Redirect, Route, RouteComponentProps, Switch } from "react-router";
import { withRouter } from "react-router-dom";

import { theme } from "./app.style";
import RegistrationPage from "./containers/registration";
import ResetPasswordContainer from "./containers/reset-password";
import LoggedInApp from "./logged-in-app";
import ForgotForm from "./pages/forgot-form";
import LoginForm from "./pages/login-form";
import LoginPageLayout, { LoginPageContainer } from "./pages/login-page-layout";
import { selectSessionState, SessionState } from "./reducers/session";

const App: React.FunctionComponent<RouteComponentProps> = ({ location }) => {
  const basePath = "/app";
  const sessionState = useSelector(selectSessionState);

  if (sessionState === SessionState.NOT_INITIALISED) {
    // Wait for the session to be initialised before redirecting
    return null;
  }

  const sessionIsActive = sessionState === SessionState.ACTIVE;

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Switch>
        {sessionIsActive ? (
          <Route
            path={basePath}
            render={(props) => <LoggedInApp {...props} />}
          />
        ) : (
          <Redirect from={basePath} to={`/login?next=${location.pathname}`} />
        )}

        <Route
          exact
          path={["/register", "/register/:id"]}
          render={({
            match: {
              params: { id: inviteID },
            },
          }: RouteComponentProps<{
            id?: string;
          }>) => <RegistrationPage inviteID={inviteID} />}
        />

        <Route exact path="/reset" render={() => <ResetPasswordContainer />} />

        <Redirect from="/" exact to="/login" />

        <LoginPageLayout>
          <LoginPageContainer transparent>
            <Route path="/forgot" render={() => <ForgotForm />} />
            <Route
              path="/login"
              render={() =>
                sessionIsActive ? <Redirect to={basePath} /> : <LoginForm />
              }
            />
          </LoginPageContainer>
        </LoginPageLayout>
      </Switch>
    </ThemeProvider>
  );
};

export default withRouter(App);
