import { Map } from "immutable";
import { all, call, fork, put, take, takeLeading } from "redux-saga/effects";
import { createSelector } from "reselect";
import { AsyncActionCreator, getType, PayloadAction } from "typesafe-actions";

import { AnyAction } from "../actions";
import {
  doListTenantDetails,
  doLoadTenantDetails,
  doUpdateAddressDetails,
  doUpdateContactDetails,
  doUpdateEmailDetails,
  doUpdateTelephoneDetails,
} from "../actions/tenants";
import { PortalService } from "../lib/api/portal";
import { ITenant, ITenantCode } from "../types/tenants";
import { RootState } from "./";

type TenantsMap = Map<string, ITenant>;

interface ITenantsState {
  readonly tenants?: TenantsMap;
  readonly loading: boolean;
  readonly error?: Error;
}

const initialState: ITenantsState = {
  loading: false,
};

const toTenantMap = (payload: ITenant[]) =>
  Map<string, ITenant>(
    payload ? payload.map((tenant) => [tenant.code, tenant]) : Map()
  );

const mergeTenant = ({ tenants, ...state }: ITenantsState, tenant: ITenant) => {
  if (!tenants) {
    tenants = Map<string, ITenant>();
  }

  return {
    tenants: tenants.set(tenant.code, tenant),
    ...state,
  };
};

const mergeTenants = (state: ITenantsState, tenants: ITenant[]) => {
  const tenantMap = toTenantMap(tenants);
  return {
    loading: false,
    tenants: state.tenants ? state.tenants.merge(tenantMap) : tenantMap,
  };
};

const setError = (state: ITenantsState, payload: Error) => ({
  loading: false,
  error: payload,
});
const setLoading = (state: ITenantsState, payload: boolean) =>
  Object.assign({}, state, { loading: payload });

export const tenantsReducer = (
  state: ITenantsState = initialState,
  action: AnyAction
): ITenantsState => {
  switch (action.type) {
    case getType(doListTenantDetails.request):
      return setLoading(state, true);
    case getType(doLoadTenantDetails.request):
      return setLoading(state, true);
    case getType(doListTenantDetails.success):
      return mergeTenants(state, action.payload);
    case getType(doLoadTenantDetails.success):
      return mergeTenant(state, action.payload);
    case getType(doListTenantDetails.failure):
      return setError(state, action.payload);
    case getType(doLoadTenantDetails.failure):
      return setError(state, action.payload);
    default:
      return state;
  }
};

export const selectTenants = (state: RootState) => state.tenants.tenants;
export const selectTenant = (code: string) =>
  createSelector(selectTenants, (tenants) =>
    tenants ? tenants.get(code) : undefined
  );

interface ITenantUpdatesState {
  addressUpdating: boolean;
  contactDetailsUpdating: boolean;
  emailsUpdating: boolean;
  telephonesUpdating: boolean;
}

type UpdatingStateKeys = keyof ITenantUpdatesState;

const initialUpdatesState = {
  addressUpdating: false,
  contactDetailsUpdating: false,
  emailsUpdating: false,
  telephonesUpdating: false,
};

export const tenantUpdates = (
  state: ITenantUpdatesState = initialUpdatesState,
  action: AnyAction
) => {
  function updatingStateUpdater<
    TRequestType extends string,
    TSuccessType extends string,
    TFailureType extends string
  >(
    stateKey: UpdatingStateKeys,
    actionType: AsyncActionCreator<
      [TRequestType, any],
      [TSuccessType, ITenant],
      [TFailureType, Error]
    >
  ) {
    return (state: ITenantUpdatesState, action: AnyAction) => {
      let newState;

      switch (action.type) {
        case getType(actionType.request):
          newState = true;
          break;
        case getType(actionType.success) || getType(actionType.failure):
          newState = false;
          break;
        default:
          newState = state[stateKey];
      }

      return Object.assign({}, state, { [stateKey]: newState });
    };
  }

  const compose = (
    ...stateUpdaters: Array<ReturnType<typeof updatingStateUpdater>>
  ) => (state: ITenantUpdatesState, action: AnyAction) =>
    stateUpdaters.reduce(
      (accumulator, currentValue) => currentValue(accumulator, action),
      state
    );

  return compose(
    updatingStateUpdater("addressUpdating", doUpdateAddressDetails),
    updatingStateUpdater("contactDetailsUpdating", doUpdateContactDetails),
    updatingStateUpdater("emailsUpdating", doUpdateEmailDetails),
    updatingStateUpdater("telephonesUpdating", doUpdateTelephoneDetails)
  )(state, action);
};

export const selectTenantUpdates = (state: RootState) =>
  state.tenantUpdates || initialUpdatesState;

function* getTenantSaga(portalAPI: PortalService) {
  function* handleLoadTenantDetailsRequest(
    req: ReturnType<typeof doLoadTenantDetails.request>
  ) {
    try {
      const res: ITenant = yield call(portalAPI.getTenant, req.payload);
      if (res) {
        yield put(doLoadTenantDetails.success(res));
      }
    } catch (err) {
      yield put(doLoadTenantDetails.failure(err));
    }
  }

  function* handleListTenantDetailsRequest(
    req: ReturnType<typeof doListTenantDetails.request>
  ) {
    try {
      const res: ITenant[] = yield call(portalAPI.listTenants);
      if (res) {
        yield put(doListTenantDetails.success(res));
      }
    } catch (err) {
      yield put(doListTenantDetails.failure(err));
    }
  }

  function* watchLoadTenantDetails() {
    yield all([
      takeLeading(
        getType(doLoadTenantDetails.request),
        handleLoadTenantDetailsRequest
      ),
      takeLeading(
        getType(doListTenantDetails.request),
        handleListTenantDetailsRequest
      ),
    ]);
  }

  yield fork(watchLoadTenantDetails);
}

function* updateTenantDetailsSaga(portalAPI: PortalService) {
  function createUpdateSaga<
    Details extends ITenantCode,
    TRequestType extends string,
    TSuccessType extends string,
    TFailureType
  >(
    actionType: AsyncActionCreator<
      [TRequestType, Details],
      [TSuccessType, ITenant],
      [TFailureType, Error]
    >,
    apiCall: (code: string, updateContext: Details) => Promise<ITenant>
  ) {
    function* handler(req: PayloadAction<TRequestType, Details>) {
      try {
        const res: ITenant = yield call(apiCall, req.payload.code, req.payload);
        yield put(actionType.success(res));
      } catch (err) {
        yield put(actionType.failure(err));
      }
    }

    function* watchSuccess() {
      while (true) {
        const res: ReturnType<typeof actionType.success> = yield take(
          getType(actionType.success)
        );
        if (res) {
          yield put(doLoadTenantDetails.success(res.payload));
        }
      }
    }

    function* watcher() {
      yield takeLeading(getType(actionType.request), handler);
    }

    function* yieldAll() {
      yield all([fork(watcher), fork(watchSuccess)]);
    }

    return yieldAll;
  }

  yield all([
    fork(
      createUpdateSaga(doUpdateAddressDetails, portalAPI.updateTenantAddress)
    ),
    fork(
      createUpdateSaga(
        doUpdateContactDetails,
        portalAPI.updateTenantContactDetails
      )
    ),
    fork(createUpdateSaga(doUpdateEmailDetails, portalAPI.updateTenantEmail)),
    fork(
      createUpdateSaga(
        doUpdateTelephoneDetails,
        portalAPI.updateTenantTelephones
      )
    ),
  ]);
}

export function* tenantsSaga(portalAPI: PortalService) {
  yield all([
    fork(getTenantSaga, portalAPI),
    fork(updateTenantDetailsSaga, portalAPI),
  ]);
}
