import Typography from "@material-ui/core/Typography";
import React from "react";

type OwnerSectionProps = {
  tenantName: string;
};

export const OwnerSection: React.FunctionComponent<OwnerSectionProps> = ({
  tenantName,
}) => {
  return (
    <div>
      <Typography variant="subtitle1">
        <b>Owner:&nbsp;</b>
        {tenantName}
      </Typography>
    </div>
  );
};
