import { IconButton, InputAdornment, TextField } from "@material-ui/core";
import { makeStyles, Theme } from "@material-ui/core/styles";
import ClearIcon from "@material-ui/icons/Clear";
import SearchIcon from "@material-ui/icons/Search";
import React from "react";

type SearchWidgetProps = {
  onChange: (arg1: string) => void;
};

const useSearchWidgetClasses = makeStyles((theme: Theme) => ({
  root: {
    display: "inline-block",
  },
}));

export const SearchWidget: React.FunctionComponent<SearchWidgetProps> = ({
  onChange,
}) => {
  const classes = useSearchWidgetClasses();

  const WrappedTextField: React.SFC<{
    iconWhenEmpty: React.ComponentType;
    iconWhenNotEmpty: React.ComponentType;
    onChange: (arg0: string) => void;
    value: string;
  }> = React.useCallback(
    ({
      iconWhenEmpty: IconWhenEmpty,
      iconWhenNotEmpty: IconWhenNotEmpty,
      onChange,
      value,
    }) => {
      const handleChange = (
        event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
      ) => {
        onChange(event.target.value);
      };
      const handleButtonClick = (
        event: React.MouseEvent<HTMLButtonElement>
      ) => {
        event.preventDefault();
        if (value.length > 0) {
          onChange("");
        }
      };

      const adornment = (
        <InputAdornment position="end">
          <IconButton
            edge="end"
            size="small"
            aria-label="clear search query"
            onClick={handleButtonClick}
            onMouseDown={(event) => {
              event.preventDefault();
            }}
          >
            {value.length > 0 ? <IconWhenNotEmpty /> : <IconWhenEmpty />}
          </IconButton>
        </InputAdornment>
      );

      const inputProps = {
        endAdornment: adornment,
      };

      return (
        <TextField
          classes={{
            root: classes.root,
          }}
          InputProps={inputProps}
          label="Search"
          margin="dense"
          onChange={handleChange}
          variant="outlined"
        />
      );
    },
    [classes]
  );

  const [searchTerm, setSearchTerm] = React.useState("");
  const handleChange = React.useCallback(
    (s: string) => {
      setSearchTerm(s);
      onChange(s);
    },
    [setSearchTerm, onChange]
  );

  return (
    <WrappedTextField
      iconWhenEmpty={SearchIcon}
      iconWhenNotEmpty={ClearIcon}
      onChange={handleChange}
      value={searchTerm}
    />
  );
};
