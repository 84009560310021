import { makeStyles, Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import React from "react";

const useWidgetStyles = makeStyles((theme: Theme) => ({
  container: {
    backgroundColor: "#e7e7e7",
    borderRadius: 5,
    marginBottom: theme.spacing(2),
    maxWidth: 1024,
    overflow: "hidden",
  },
  content: {
    padding: 15,
  },
  title: {
    backgroundColor: "#e2dac7", // theme.palette.primary.light,
  },
  titleText: {
    lineHeight: "2.5em",
    paddingLeft: 15,
    textTransform: "uppercase",
  },
}));

type ContentBoxProps = {
  title: string;
};

export const ContentBox: React.FunctionComponent<
  React.PropsWithChildren<ContentBoxProps>
> = ({ title, children }) => {
  const classes = useWidgetStyles();

  return (
    <div className={classes.container}>
      <div className={classes.title}>
        <Typography className={classes.titleText}>{title}</Typography>
      </div>

      <div className={classes.content}>{children}</div>
    </div>
  );
};
