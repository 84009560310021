import { CircularProgress } from "@material-ui/core";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Switch } from "react-router-dom";

import { doListTenantDetails } from "../../actions/tenants";
import { selectSessionAdminUser } from "../../reducers/session";
import { selectTenants } from "../../reducers/tenants";
import { AdminIndex } from "./root";
import { TenantAdmin } from "./tenant";

export const AdministrationContainer: React.FunctionComponent = () => {
  const dispatch = useDispatch();
  const isAdminUser = useSelector(selectSessionAdminUser);
  const tenants = useSelector(selectTenants);

  const [
    rootTenantSearchQuery,
    setRootTenantSearchQuery,
  ] = React.useState<string>("");

  React.useEffect(() => {
    dispatch(doListTenantDetails.request());
  }, [dispatch]);

  if (!isAdminUser) {
    return <p>Access to this portal is for administrative users only.</p>;
  }

  if (!tenants) {
    return <CircularProgress size={24} />;
  }

  return (
    <>
      <Switch>
        <Route path="/app/admin/tenant/:id">
          {({ match }) => (match ? <TenantAdmin id={match.params.id} /> : null)}
        </Route>
        <Route
          path="/app/admin"
          exact
          render={() => (
            <AdminIndex
              tenantSearchQuery={rootTenantSearchQuery}
              onTenantSearchQueryChange={setRootTenantSearchQuery}
            />
          )}
        />
      </Switch>
    </>
  );
};
