import { AxiosInstance } from "axios";
import Moment from "moment";

import { Invite, TenantInvite } from "../../types/registration";
import { handlePostRequest, Service } from "./";
import { mapTenant } from "./portal";

export interface InviteAuthDetails {
  inviteID: string;
  inviteToken: string;
}

const mapInvite = (invite: any): Invite => {
  if (!invite || !invite.auth_details) {
    throw new Error("Invalid Invite server response");
  }

  const authDetails = invite.auth_details;

  return {
    inviteID: authDetails.invite_id,
    inviteToken: authDetails.invite_token,
    emailAddress: invite.email_address || null,
    expireTime: Moment(authDetails.expire_time),
  };
};

const mapTenantInvite = (tenantInvite: any): TenantInvite => {
  if (!tenantInvite) {
    throw new Error("Invalid input");
  }

  return {
    invite: mapInvite(tenantInvite.invite),
    tenant: mapTenant(tenantInvite.tenant),
  };
};

const mapInviteAuthDetailsToServer = (req: InviteAuthDetails) => ({
  invite_id: req.inviteID,
  invite_token: req.inviteToken,
});

export class RegistrationService extends Service {
  public checkUsername = (authDetails: InviteAuthDetails, username: string) =>
    doCheckUsername(this.axios, {
      authDetails,
      username,
    });

  public createInviteForTenant = (tenantCode: string, readOnly: boolean) =>
    doCreateInviteForTenant(this.axios, { tenantCode, readOnly });

  public completeRegistration = (req: CompleteRegistrationRequest) =>
    doCompleteRegistration(this.axios, req);

  public resolveTenantInviteDetails = (authDetails: InviteAuthDetails) =>
    doResolveTenantInviteDetails(this.axios, {
      authDetails,
    });

  public sendInviteNotificationToTenant = (
    authDetails: InviteAuthDetails,
    emailAddress: string
  ) =>
    doSendInviteNotificationToTenant(this.axios, { authDetails, emailAddress });
}

export interface CheckUsernameRequest {
  authDetails: InviteAuthDetails;
  username: string;
}

const mapCheckUsernameRequestToServer = (req: CheckUsernameRequest) => ({
  auth_details: mapInviteAuthDetailsToServer(req.authDetails),
  username: req.username,
});

const doCheckUsername = (axios: AxiosInstance, req: CheckUsernameRequest) =>
  handlePostRequest(
    axios,
    `/v1/tenants/-/invites/${req.authDetails.inviteID}:checkUsername`,
    mapCheckUsernameRequestToServer(req)
  );

export interface CreateInviteForTenantRequest {
  readOnly: boolean;
  tenantCode: string;
}

const mapCreateInviteForTenantRequestToServer = (
  req: CreateInviteForTenantRequest
) => ({
  read_only: req.readOnly,
  tenant_code: req.tenantCode,
});

const doCreateInviteForTenant = (
  axios: AxiosInstance,
  req: CreateInviteForTenantRequest
) =>
  handlePostRequest(
    axios,
    `/v1/tenants/${req.tenantCode}/invites`,
    mapCreateInviteForTenantRequestToServer(req),
    mapInvite
  );

export interface CompleteRegistrationRequest {
  authDetails: InviteAuthDetails;
  username: string;
  password: string;
  emailAddress: string;
}

const mapCompleteRegistrationRequestToServer = (
  req: CompleteRegistrationRequest
) => ({
  auth_details: mapInviteAuthDetailsToServer(req.authDetails),
  username: req.username,
  password: req.password,
  email_address: req.emailAddress,
});

const doCompleteRegistration = (
  axios: AxiosInstance,
  req: CompleteRegistrationRequest
) =>
  handlePostRequest(
    axios,
    `/v1/tenants/-/invites/${req.authDetails.inviteID}:completeRegistration`,
    mapCompleteRegistrationRequestToServer(req)
  );

export interface ResolveTenantInviteDetailsRequest {
  authDetails: InviteAuthDetails;
}

const mapResolveTenantInviteDetailsRequestToServer = (
  req: ResolveTenantInviteDetailsRequest
) => mapInviteAuthDetailsToServer(req.authDetails);

const doResolveTenantInviteDetails = (
  axios: AxiosInstance,
  req: ResolveTenantInviteDetailsRequest
) =>
  handlePostRequest(
    axios,
    `/v1/tenants/-/invites/${req.authDetails.inviteID}:resolveTenantInviteDetails`,
    mapResolveTenantInviteDetailsRequestToServer(req),
    (tenantInvite) => mapTenantInvite(tenantInvite)
  );

export interface SendInviteNotificationToTenantRequest {
  authDetails: InviteAuthDetails;
  emailAddress: string;
}

export interface SendInviteNotificationToTenantResponse {
  emailAddresses: string[];
}

const mapSendInviteNotificationToTenantResponse = (
  resp: any
): SendInviteNotificationToTenantResponse => ({
  emailAddresses: resp.email_addresses || [],
});

const mapSendInviteNotificationToTenantRequestToServer = (
  req: SendInviteNotificationToTenantRequest
) => ({
  auth_details: mapInviteAuthDetailsToServer(req.authDetails),
  email_address: req.emailAddress,
});

const doSendInviteNotificationToTenant = (
  axios: AxiosInstance,
  req: SendInviteNotificationToTenantRequest
) =>
  handlePostRequest(
    axios,
    `/v1/tenants/-/invites/${req.authDetails.inviteID}:sendInviteNotificationToTenant`,
    mapSendInviteNotificationToTenantRequestToServer(req),
    (resp) => {
      if (!resp) {
        throw new Error("Expected a response, but none was received");
      }
      return mapSendInviteNotificationToTenantResponse(resp);
    }
  );
