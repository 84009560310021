import {
  Button,
  Grid,
  Link,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle";
import queryString from "query-string";
import React from "react";
import { Form } from "react-final-form";
import { Link as RouterLink, useLocation } from "react-router-dom";

import { ResetPasswordResponse } from "../../lib/api/auth";
import { useAuthAPI } from "../../lib/hooks";
import LoginPageLayout, {
  LoginPageContainer,
} from "../../pages/login-page-layout";
import { returnFirst } from "../../utils/query-string";
import ConfirmPasswordFields from "../../widgets/password-form/confirm-password-fields";

interface Props {
  id?: string | null;
  token?: string | null;
}

const ERROR_SAVING_PASSWORD = "Saving password failed";
const INVALID_RESET_LINK_TITLE = "Invalid reset link";
const INVALID_RESET_LINK_TEXT =
  "The reset link is not valid. Please ensure your email application has not truncated the link.";
const PASSWORD_RESET_SUCCESS_TITLE = "Password reset successfully";
const PASSWORD_RESET_SUCCESS_TEXT =
  "Your password was successfully reset. You may now return to the login page.";
const PLEASE_WAIT = "Please wait";
const PLEASE_WAIT_CHECKING_TOKEN =
  "We are resolving your profile from your reset token. Please bear with us";
const RETURN_TO_LOGIN_PAGE = "Return to login page";

function ResetNotice({
  error,
  title,
  text,
}: {
  error?: boolean;
  title: string;
  text: string;
}) {
  return (
    <Alert severity={error ? "error" : "info"}>
      <AlertTitle>{title}</AlertTitle>
      {text}
    </Alert>
  );
}

interface ResetPasswordFormProps {
  onSubmit: (args: { password: string }) => Promise<ResetPasswordResponse>;
  username: string;
}

const useFormStyles = makeStyles((theme: Theme) => ({
  formTextField: {
    marginBottom: theme.spacing(3),
  },
}));

enum FormState {
  NOT_SAVED = 0,
  SAVING = 1,
  SAVED = 2,
}

const ResetPasswordForm: React.FunctionComponent<ResetPasswordFormProps> = ({
  onSubmit,
  username,
}) => {
  const classes = useFormStyles();
  const [formState, setFormState] = React.useState(FormState.NOT_SAVED);
  const [saveError, setSaveError] = React.useState<Error | null>();

  interface FormContext {
    newPassword: string;
    confirmNewPassword: string;
  }

  const initialValues = {
    newPassword: "",
    confirmNewPassword: "",
  };

  const handleSubmit = (values: FormContext) => {
    setFormState(FormState.SAVING);
    onSubmit({
      password: values.newPassword,
    }).then(
      (response) => {
        setFormState(FormState.SAVED);
      },
      (error: Error) => {
        setFormState(FormState.NOT_SAVED);
        setSaveError(error);
      }
    );
  };

  return (
    <Form
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validate={(values) => {
        const errors: Partial<Record<keyof FormContext, string>> = {};
        if (values.newPassword !== values.confirmNewPassword) {
          errors.confirmNewPassword =
            "The new and confirm passwords do not match.";
        }

        return errors;
      }}
      render={({ handleSubmit, pristine, invalid }) => (
        <form onSubmit={handleSubmit}>
          <Grid container direction="column">
            <Grid item xs>
              <Typography gutterBottom>
                Username: <b>{username}</b>
              </Typography>
            </Grid>
            <Grid item xs>
              <Typography gutterBottom>
                Please enter and confirm a new password for your profile below.
              </Typography>
            </Grid>
            <Grid item md>
              <ConfirmPasswordFields
                classes={{ root: classes.formTextField }}
                passwordFieldNames={{
                  id: "newPassword",
                  label: "New Password",
                  name: "newPassword",
                  validateFields: ["newPassword", "confirmNewPassword"],
                }}
                confirmPasswordFieldNames={{
                  id: "confirmNewPassword",
                  label: "Confirm New Password",
                  name: "confirmNewPassword",
                  validateFields: ["newPassword", "confirmNewPassword"],
                }}
              />
            </Grid>
            <Grid item md>
              <Button
                color="primary"
                disabled={
                  pristine || invalid || formState !== FormState.NOT_SAVED
                }
                type="submit"
                variant="contained"
              >
                Reset Password
              </Button>
            </Grid>
            {formState === FormState.SAVED && (
              <>
                <ResetNotice
                  title={PASSWORD_RESET_SUCCESS_TITLE}
                  text={PASSWORD_RESET_SUCCESS_TEXT}
                />
                <Link component={RouterLink} to="/app" variant="body2">
                  {RETURN_TO_LOGIN_PAGE}
                </Link>
              </>
            )}
            {saveError && (
              <ResetNotice
                error
                title={ERROR_SAVING_PASSWORD}
                text={saveError.message}
              />
            )}
          </Grid>
        </form>
      )}
    />
  );
};

const Selector: React.FunctionComponent<Props> = ({ id, token }) => {
  const authAPI = useAuthAPI();

  const [validToken, setValidToken] = React.useState(false);
  const [networkError, setNetworkError] = React.useState<Error | undefined>();
  const [username, setUsername] = React.useState("");

  const parsedID = Number(id);
  const validDetails =
    id &&
    token &&
    id.length > 0 &&
    token.length > 0 &&
    Number.isInteger(parsedID);

  React.useEffect(() => {
    // Get the reset context to verify validity
    if (!validDetails) {
      return;
    }

    authAPI
      .getPasswordResetContext(parsedID, token ?? "")
      .then((response) => {
        setValidToken(true);
        setUsername(response.username);
      })
      .catch((err) => setNetworkError(err));
  }, [authAPI, parsedID, token, validDetails]);

  if (!validDetails) {
    return (
      <ResetNotice
        error
        title={INVALID_RESET_LINK_TITLE}
        text={INVALID_RESET_LINK_TEXT}
      />
    );
  }

  if (!!networkError) {
    return (
      <ResetNotice
        error
        title={INVALID_RESET_LINK_TITLE}
        text={networkError.message}
      />
    );
  } else if (!validToken) {
    return (
      <ResetNotice title={PLEASE_WAIT} text={PLEASE_WAIT_CHECKING_TOKEN} />
    );
  }

  const handleResetPassword = ({ password }: { password: string }) => {
    return authAPI.resetPassword(parsedID, token ?? "", password);
  };

  // The reset token is valid. Present the reset form for the user.
  return (
    <ResetPasswordForm onSubmit={handleResetPassword} username={username} />
  );
};

export const ResetPasswordPage: React.FunctionComponent = () => {
  const location = useLocation();
  const qs = queryString.parse(location.search);
  const id = returnFirst(qs.id);
  const token = returnFirst(qs.token);

  return (
    <LoginPageLayout headerText="Reset your password">
      <LoginPageContainer headerText="Reset your password">
        <Selector id={id} token={token} />
      </LoginPageContainer>
    </LoginPageLayout>
  );
};
