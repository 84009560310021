import React, { useEffect } from "react";

import { SearchWidget } from "../../widgets/complex-table";
import { UnitPageLayout } from "../../widgets/unit-pages/unit-page-layout";
import { Props } from "./container";
import { AccountsDetail } from "./detail";

export type ViewProps = Props & {
  searchTerm: string;
};

const AccountPage: React.FunctionComponent<Props> = ({ unit, ...props }) => {
  const [searchTerm, setSearchTerm] = React.useState("");
  const { loadAccounts } = props;

  useEffect(() => {
    if (unit) {
      loadAccounts(unit.code);
    }
  }, [loadAccounts, unit]);

  if (!unit) {
    return null;
  }

  return (
    <UnitPageLayout
      headerAdornment={<SearchWidget onChange={setSearchTerm} />}
      title="View Account"
      unitCode={unit.code}
    >
      <AccountsDetail searchTerm={searchTerm} {...props} />
    </UnitPageLayout>
  );
};

export { AccountPage };
