import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Route, Switch } from "react-router";
import { Dispatch } from "redux";

import { doGetProperty } from "../../actions/property";
import { doLoadUnits } from "../../actions/units";
import AccountContainer from "../../containers/account";
import ChangePasswordContainer from "../../containers/change-password";
import ContactContainer from "../../containers/contact";
import DocumentsContainer from "../../containers/documents";
import ProfileContainer from "../../containers/profile";
import UnitHomePageContainer from "../../containers/unit-index";
import { RootState } from "../../reducers";
import { selectPropertyByCode } from "../../reducers/property";
import { selectUnitByCode } from "../../reducers/units";
import { IProperty } from "../../types/property";
import { PropsWithRouteProps } from "../../types/router";
import { IUnit } from "../../types/units";

type IOwnProps = PropsWithRouteProps<{
  selectedUnit?: string;
}>;

type IProps = IOwnProps & StateProps & DispatchProps;

const SelectedUnitContainer: React.StatelessComponent<
  PropsWithRouteProps<IProps, {}>
> = ({
  loadPropertyDetails,
  loadUnits,
  match,
  selectedUnit,
  property,
  unit,
}) => {
  useEffect(() => {
    loadUnits();
  }, [loadUnits, selectedUnit]);

  useEffect(() => {
    if (unit && !property) {
      loadPropertyDetails(unit.propertyCode);
    }
  }, [loadPropertyDetails, property, unit]);

  if (!unit) {
    return null;
  }

  return (
    <Switch>
      <Route
        exact
        path={[`${match.url}`, `${match.url}/unit`]}
        render={() => <UnitHomePageContainer unit={unit} />}
      />
      <Route
        path={`${match.url}/account`}
        render={() => <AccountContainer unit={unit} />}
      />
      <Route
        path={`${match.url}/change-password`}
        render={() => <ChangePasswordContainer />}
      />
      <Route
        path={`${match.url}/documents`}
        render={() => <DocumentsContainer unit={unit} />}
      />
      <Route
        path={`${match.url}/profile`}
        render={() => <ProfileContainer unit={unit} />}
      />
      <Route
        path={`${match.url}/contact`}
        render={() => <ContactContainer unit={unit} />}
      />
    </Switch>
  );
};

const mapStateToProps = (
  state: RootState,
  ownProps: IOwnProps
): {
  property?: IProperty;
  unit?: IUnit;
} => {
  const { selectedUnit: unitCode } = ownProps;

  const unit = unitCode ? selectUnitByCode(state, unitCode) : undefined;
  if (!unit) {
    return {};
  }

  const property = selectPropertyByCode(state, unit.propertyCode);

  return {
    property,
    unit,
  };
};

const mapDispatchToProps = (dispatch: Dispatch, ownProps: IOwnProps) => {
  const { selectedUnit: unitCode } = ownProps;

  return {
    loadPropertyDetails: (propertyCode: string) =>
      dispatch(doGetProperty.request(propertyCode)),

    // We need to load all unit details in order to properly populate the
    // "Select different property" option in the sidebar. It's easier to
    // load every unit here than load just this unit's details and later do a
    // bulk call.
    loadUnits: () => (unitCode ? dispatch(doLoadUnits.request()) : null),
  };
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

const ConnectedSelectedUnitApp = connect<
  StateProps,
  DispatchProps,
  IOwnProps,
  RootState
>(
  mapStateToProps,
  mapDispatchToProps
)(SelectedUnitContainer);

export default ConnectedSelectedUnitApp;
