import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";

import {
  doDownloadInvoice,
  doLoadAccountsListForUnit,
} from "../../actions/account";
import { RootState } from "../../reducers";
import { selectAccountEntriesForUnit } from "../../reducers/account";
import { IAccountEntry } from "../../types/account";
import { IUnit } from "../../types/units";
import { AccountPage } from "./page";

interface IOwnProps {
  unit?: IUnit;
}

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      loadAccounts: (unitCode: string) =>
        doLoadAccountsListForUnit.request(unitCode),
      navigateDownloadInvoice: (id: string) => doDownloadInvoice(id),
    },
    dispatch
  );

const mapStateToProps = (state: RootState, ownProps: IOwnProps) => {
  const { unit } = ownProps;

  const selectAccountEntries = (state: RootState) => {
    const entries: Array<IAccountEntry> | undefined = unit
      ? selectAccountEntriesForUnit(unit.code)(state).toJS()
      : [];

    return entries.sort((a, b) => -a.date.diff(b.date));
  };

  return {
    accountEntries: selectAccountEntries(state),
  };
};

export type Props = IOwnProps &
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

export const AccountContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(AccountPage);
