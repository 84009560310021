import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";

export const getAPIBaseURL = () => {
  if (window.location.hostname === "localhost") {
    // local development mode
    return "http://localhost:8008";
  }

  return `${window.location.protocol}//${window.location.host}/api`;
};

export class Service {
  protected axios: AxiosInstance;

  protected host: string;
  private apiKey: string | undefined;

  constructor(host: string) {
    this.host = host;
    this.axios = axios.create({
      baseURL: host,
      timeout: 5000,
    });
  }

  public setAPIKey = (key: string) => {
    this.axios.defaults.headers["Authorization"] = "bearer " + key;
  };
}

export function handleGetRequest<ResponseType>(
  axios: AxiosInstance,
  url: string,
  onSuccess: (arg0: any) => ResponseType = (x) => x
) {
  return new Promise<ResponseType>((resolve, reject) => {
    return axios.get(url).then(
      (response: AxiosResponse<any>) => {
        response.data
          ? resolve(onSuccess(response?.data))
          : reject(new Error("Empty response"));
      },
      (error: any) => {
        if (error instanceof Error) {
          reject(error);
        } else {
          reject(new Error(error));
        }
      }
    );
  });
}

export function handlePatchRequest<InputType, ResponseType>(
  axios: AxiosInstance,
  url: string,
  data: InputType,
  onSuccess: (arg0: any) => ResponseType = (x) => x
) {
  return new Promise<ResponseType>((resolve, reject) => {
    return axios.patch(url, data).then(
      (response: AxiosResponse<any>) => {
        response.data
          ? resolve(onSuccess(response?.data))
          : reject(new Error("Empty response"));
      },
      (error: any) => {
        if (error instanceof Error) {
          reject(error);
        } else {
          reject(new Error(error));
        }
      }
    );
  });
}

export function handlePostRequest<InputType, ResponseType = {}>(
  axios: AxiosInstance,
  url: string,
  data: InputType,
  onSuccess: (arg0: any) => ResponseType = (x) => x,
  config?: AxiosRequestConfig
) {
  return new Promise<ResponseType>((resolve, reject) => {
    return axios.post(url, data, config).then(
      (response: AxiosResponse<any>) => {
        response.data
          ? resolve(onSuccess(response.data))
          : reject(new Error("Empty response"));
      },
      (error: any) => {
        if (error instanceof Error) {
          reject(error);
        } else {
          reject(new Error(error));
        }
      }
    );
  });
}

export function handleDeleteRequest<InputType, ResponseType = {}>(
  axios: AxiosInstance,
  url: string,
  data: InputType,
  onSuccess: (arg0: any) => ResponseType = (x) => x
) {
  return new Promise<ResponseType>((resolve, reject) => {
    return axios.delete(url, data).then(
      (response: AxiosResponse<any>) => {
        response.data
          ? resolve(onSuccess(response.data))
          : reject(new Error("Empty response"));
      },
      (error: any) => {
        if (error instanceof Error) {
          reject(error);
        } else {
          reject(new Error(error));
        }
      }
    );
  });
}
