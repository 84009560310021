import { makeStyles, TableCell, TableSortLabel } from "@material-ui/core";
import { Theme } from "@material-ui/core/styles";
import React from "react";

import { Order, SortableKeys } from "./complex-table";

export interface IHeadCell<T> {
  id: keyof T;
  label: string;
  align?: "left" | "right";
  disableSort?: boolean;
}

type HeadCellProps<T> = {
  currentOrder: Order;
  currentOrderBy: SortableKeys<T> | null | undefined;
  onSort: () => void;
} & IHeadCell<T>;

const useHeadCellClasses = makeStyles((theme: Theme) => ({
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
}));

export function HeadCell<T>(
  props: HeadCellProps<T>
): React.ReactElement<HeadCellProps<T>> {
  const {
    align,
    currentOrder,
    currentOrderBy,
    disableSort,
    id,
    label,
    onSort,
  } = props;
  const classes = useHeadCellClasses();

  const formattedLabel = label.toUpperCase();
  const wrappedLabel = disableSort ? (
    formattedLabel
  ) : (
    <TableSortLabel
      active={currentOrderBy === id}
      direction={currentOrder}
      onClick={onSort}
    >
      {formattedLabel}
      {currentOrderBy === id ? (
        <span className={classes.visuallyHidden}>
          {currentOrder === "desc" ? "sorted descending" : "sorted ascending"}
        </span>
      ) : null}
    </TableSortLabel>
  );

  return (
    <TableCell
      align={align}
      sortDirection={currentOrderBy === id ? currentOrder : false}
    >
      {wrappedLabel}
    </TableCell>
  );
}
