import Cookies from "js-cookie";
import log from "loglevel";
import { Moment } from "moment";

const SESSION_COOKIE_NAME = "SESSION_KEY";

class Cookie {
  public sessionKey: string;
  public expires?: Moment;

  constructor(sessionKey: string, expires?: Moment, persistent = false) {
    this.sessionKey = sessionKey;
    this.expires = expires;
  }

  isEqual(cookie: Cookie) {
    return [
      this.sessionKey === cookie.sessionKey,
      this.expires === cookie.expires,
    ].every((p) => p);
  }

  toJSON() {
    return JSON.stringify({
      sessionKey: this.sessionKey,
      expires: this.expires?.toDate(),
    });
  }

  static fromJSON(body: string) {
    try {
      var obj = JSON.parse(body);
    } catch (e) {
      log.warn("Decoding cookie JSON: " + e);
      return;
    }
    return new Cookie(obj.sessionKey, obj.persistent);
  }
}

export const getSessionCookie = () => {
  const cookieData = Cookies.get(SESSION_COOKIE_NAME);
  if (!cookieData) {
    return;
  }

  return Cookie.fromJSON(Buffer.from(cookieData, "base64").toString("utf-8"));
};

function deleteSessionCookie() {
  Cookies.remove(SESSION_COOKIE_NAME);
}

function setSessionCookie(token: string, expires?: Moment) {
  const cookie = new Cookie(token, expires);

  // If there is an existing cookie for the same session and expiry, don't
  // overwrite it - it's just a subsequent auth!
  const currentCookie = getSessionCookie();
  if (currentCookie?.isEqual(cookie)) {
    log.debug("Skipping setting cookie as pre-existing cookie exists");
    return;
  }

  const v = Buffer.from(cookie.toJSON()).toString("base64");
  Cookies.set(SESSION_COOKIE_NAME, v, {
    expires: expires?.toDate(),
    secure: window.location.protocol === "https:",
    sameSite: "strict",
  });
}

export class CookieHandler {
  Delete() {
    return deleteSessionCookie();
  }

  Get() {
    console.log(getSessionCookie());
    return getSessionCookie()?.sessionKey;
  }

  Set(token: string, expires?: Moment) {
    setSessionCookie(token, expires);
  }
}
