import { createAsyncAction } from "typesafe-actions";

import {
  ITenant,
  ITenantAddressDetailsUpdate,
  ITenantContactDetailsUpdate,
  ITenantEmailDetailsUpdate,
  ITenantTelephoneDetailsUpdate,
} from "../types/tenants";

export const doListTenantDetails = createAsyncAction(
  "ESSKAY/TENANTS/LIST/REQUEST",
  "ESSKAY/TENANTS/LIST/SUCCESS",
  "ESSKAY/TENANTS/LIST/ERROR"
)<undefined, ITenant[], Error>();

export const doLoadTenantDetails = createAsyncAction(
  "ESSKAY/TENANTS/LOAD_DETAILS/REQUEST",
  "ESSKAY/TENANTS/LOAD_DETAILS/SUCCESS",
  "ESSKAY/TENANTS/LOAD_DETAILS/ERROR"
)<string, ITenant, Error>();

export const doUpdateAddressDetails = createAsyncAction(
  "ESSKAY/TENANTS/UPDATE/ADDRESS/REQUEST",
  "ESSKAY/TENANTS/UPDATE/ADDRESS/SUCCESS",
  "ESSKAY/TENANTS/UPDATE/ADDRESS/FAILURE"
)<ITenantAddressDetailsUpdate, ITenant, Error>();

export const doUpdateContactDetails = createAsyncAction(
  "ESSKAY/TENANTS/UPDATE/CONTACT/REQUEST",
  "ESSKAY/TENANTS/UPDATE/CONTACT/SUCCESS",
  "ESSKAY/TENANTS/UPDATE/CONTACT/FAILURE"
)<ITenantContactDetailsUpdate, ITenant, Error>();

export const doUpdateEmailDetails = createAsyncAction(
  "ESSKAY/TENANTS/UPDATE/EMAIL/REQUEST",
  "ESSKAY/TENANTS/UPDATE/EMAIL/SUCCESS",
  "ESSKAY/TENANTS/UPDATE/EMAIL/FAILURE"
)<ITenantEmailDetailsUpdate, ITenant, Error>();

export const doUpdateTelephoneDetails = createAsyncAction(
  "ESSKAY/TENANTS/UPDATE/TELEPHONE/REQUEST",
  "ESSKAY/TENANTS/UPDATE/TELEPHONE/SUCCESS",
  "ESSKAY/TENANTS/UPDATE/TELEPHONE/FAILURE"
)<ITenantTelephoneDetailsUpdate, ITenant, Error>();
