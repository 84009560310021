import { makeStyles, Theme } from "@material-ui/core/styles";

import backgroundImage from "../../assets/img/bg-cover.jpg";

export const useLoginPageLayoutStyles = makeStyles((theme: Theme) => ({
  root: {
    height: "100%",
  },
  bodyContent: {
    padding: theme.spacing(4),
  },
  containerBg: {
    backgroundColor: "white",
  },
  containerTransparent: {
    opacity: 0.9,
  },
  image: {
    backgroundImage: `url(${backgroundImage})`,
    backgroundPosition: "left bottom",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    filter: "saturate(4) grayscale(0.4)",
    [theme.breakpoints.down("md")]: {
      backgroundPosition: "center",
    },
  },
  imageOverlay: {
    backgroundColor: "white",
    opacity: 0.3,
    height: "100%",
  },
  logo: {
    width: "200px",
  },
  logoContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    minHeight: 120,
  },
}));

export const useCommonLoginLayoutStyles = makeStyles((theme: Theme) => ({
  footerLink: {
    marginTop: theme.spacing(2),
  },
  form: {
    width: "100%",
  },
  submitButton: {
    margin: theme.spacing(3, 0, 2),
  },
  submitButtonContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  submitButtonSpinner: {
    marginRight: theme.spacing(1),
  },
}));
