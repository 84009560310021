import React from "react";

import { AdministrationTable, Props as TableProps } from "./table";

export const AdminIndex: React.FunctionComponent<TableProps> = ({
  ...tableProps
}) => (
  <>
    <AdministrationTable {...tableProps} />
  </>
);
