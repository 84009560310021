import { Map } from "immutable";
import { all, call, fork, put, takeLatest } from "redux-saga/effects";
import { createSelector } from "reselect";
import { getType } from "typesafe-actions";

import { AnyAction } from "../actions";
import {
  listTenantUserProfiles,
  setTenantUserProfile,
} from "../actions/profiles";
import { PortalService } from "../lib/api/portal";
import { UserProfile } from "../types/profiles";
import { RootState } from "./";

type UserProfileMap = Map<string, Map<string, UserProfile>>;

const mapProfiles = (profiles: UserProfile[]) => {
  let profilesMap = Map<string, Map<string, UserProfile>>();

  profiles.forEach((profile) => {
    profilesMap = profilesMap.setIn(
      [profile.tenantCode, profile.username],
      profile
    );
  });

  return profilesMap;
};

interface ProfilesState {
  readonly error?: Error;
  readonly loading: boolean;
  readonly profiles?: UserProfileMap;
}

const initialState: ProfilesState = {
  loading: false,
};

export const profilesReducer = (
  state: ProfilesState = initialState,
  action: AnyAction
): ProfilesState => {
  switch (action.type) {
    case getType(listTenantUserProfiles.request):
      return { loading: true };
    case getType(listTenantUserProfiles.success):
      const profilesMap = mapProfiles(action.payload);

      return {
        loading: false,
        profiles: state.profiles
          ? state.profiles.mergeDeep(profilesMap)
          : profilesMap,
      };
    case getType(listTenantUserProfiles.failure):
      return {
        loading: false,
        error: action.payload,
      };
    case getType(setTenantUserProfile):
      return {
        loading: false,
        profiles: state.profiles
          ? state.profiles.setIn(
              [action.payload.tenantCode, action.payload.username],
              action.payload
            )
          : mapProfiles([action.payload]),
      };
    default:
      return state;
  }
};

const selectProfilesState = (state: RootState) => state.profiles;
export const selectUserProfilesError = createSelector(
  selectProfilesState,
  (profiles) => profiles.error
);
export const selectUserProfilesLoading = createSelector(
  selectProfilesState,
  (profiles) => profiles.loading
);
export const selectUserProfilesForTenant = (tenantCode: string) =>
  createSelector(selectProfilesState, (profiles) =>
    profiles.profiles
      ? (profiles.profiles.get(tenantCode) || Map())
          .filter(
            (profile) =>
              !profile.deleteTime || profile.deleteTime.isAfter(Date.now())
          )
          .sortBy((profile) => profile.username)
          .values()
      : []
  );

function* listUserProfilesSaga(portalAPI: PortalService) {
  function* handleListUserProfilesRequest(
    req: ReturnType<typeof listTenantUserProfiles.request>
  ) {
    try {
      const res = yield call(portalAPI.listTenantUserProfiles, req.payload);
      yield put(listTenantUserProfiles.success(res));
    } catch (err) {
      yield put(listTenantUserProfiles.failure(err));
    }
  }

  function* watchListUserProfiles() {
    yield takeLatest(
      getType(listTenantUserProfiles.request),
      handleListUserProfilesRequest
    );
  }

  yield fork(watchListUserProfiles);
}

export function* userProfilesSaga(portalAPI: PortalService) {
  yield all([fork(listUserProfilesSaga, portalAPI)]);
}
