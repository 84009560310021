import { makeStyles, Theme } from "@material-ui/core/styles";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import React from "react";

import { useTableStyles } from "../../app/styles/table-styles";
import { IAccountEntry } from "../../types/account";
import ComplexTable from "../../widgets/complex-table";
import { IHeadCell } from "../../widgets/complex-table/head-cell";
import { InvoiceButton } from "./invoice-button";
import { ViewProps } from "./page";

const headCells: IHeadCell<IAccountEntry>[] = [
  { id: "date", label: "Date" },
  { id: "detail", label: "Detail" },
  { id: "amountInvoiced", label: "Invoiced (£)", align: "right" },
  { id: "amountPaid", label: "Paid (£)", align: "right" },
  { id: "accountBalance", label: "Balance (£)", align: "right" },
];

const useAccountStyles = makeStyles((theme: Theme) => ({
  dateCell: {
    whiteSpace: "nowrap",
  },
  row: {
    height: 49,
  },
}));

const AccountsTable: React.FunctionComponent<ViewProps> = ({
  accountEntries,
  navigateDownloadInvoice,
  searchTerm,
}) => {
  const classes = useAccountStyles();
  const tableClasses = useTableStyles();

  return (
    <ComplexTable<IAccountEntry>
      filterFields={["detail"]}
      headCells={headCells}
      initialSort="date"
      initialSortDirection="desc"
      items={accountEntries}
      searchTerm={searchTerm}
      classes={{
        border: tableClasses.tableBorder,
        head: tableClasses.tableHead,
      }}
    >
      {({ items }) => (
        <>
          {items.map(({ invoiceID, ...entry }, idx) => (
            <TableRow className={classes.row} key={`account-entry-${idx}`}>
              <TableCell classes={{ root: classes.dateCell }}>
                {entry.date.format("DD/MM/YYYY")}
              </TableCell>
              <TableCell>
                {entry.detail}
                {invoiceID ? (
                  <InvoiceButton
                    id={invoiceID}
                    navigateDownloadInvoice={navigateDownloadInvoice}
                  />
                ) : null}
              </TableCell>
              <TableCell align="right">
                {entry.amountInvoiced?.toFixed(2)}
              </TableCell>
              <TableCell align="right">
                {entry.amountPaid?.toFixed(2)}
              </TableCell>
              <TableCell align="right">
                {entry.accountBalance?.toFixed(2)}
              </TableCell>
            </TableRow>
          ))}
        </>
      )}
    </ComplexTable>
  );
};

export const AccountsDetail: React.FunctionComponent<ViewProps> = (props) => {
  return <AccountsTable {...props} />;
};
