interface IUnitAddress {
  line1: string;
  line2: string;
  line3: string;
  line4: string;
  line5: string;
}

export interface IUnit {
  code: string;
  tenantCode: string;
  propertyCode: string;
  search: string;
  address: IUnitAddress;
  enabled: boolean;
}

export interface IUnits extends Array<IUnit> {}

export const buildUnitAddress = (addr: IUnitAddress) =>
  [addr.line1, addr.line2, addr.line3, addr.line4, addr.line5]
    .filter((x) => x)
    .join(", ");
