import { connectRouter } from "connected-react-router";
import { History } from "history";
import { combineReducers } from "redux";
import { all, fork } from "redux-saga/effects";
import { StateType } from "typesafe-actions";

import { AuthService } from "../lib/api/auth";
import { PortalService } from "../lib/api/portal";
import { RegistrationService } from "../lib/api/registration";
import { account, accountsSaga } from "./account";
import { authSaga, login, password } from "./auth";
import { contactManagerReducer, contactSaga } from "./contact";
import { documents, documentsSaga } from "./documents";
import { forgotPasswordSaga, forgotReducer } from "./forgot";
import { metadataReducer, metadataSaga } from "./metadata";
import { news, newsSaga } from "./news";
import { portalReducer, portalSaga } from "./portal";
import { profilesReducer, userProfilesSaga } from "./profiles";
import { propertiesReducer, propertiesSaga } from "./property";
import { registrationReducer, registrationSaga } from "./registration";
import { session, sessionSaga } from "./session";
import { tenantsReducer, tenantsSaga, tenantUpdates } from "./tenants";
import { units } from "./units";

const appStateReducers = (history: History) =>
  combineReducers({
    account,
    contactManager: contactManagerReducer,
    documents,
    forgot: forgotReducer,
    login,
    metadata: metadataReducer,
    news,
    password,
    portal: portalReducer,
    profiles: profilesReducer,
    properties: propertiesReducer,
    registration: registrationReducer,
    router: connectRouter(history),
    session,
    tenants: tenantsReducer,
    tenantUpdates,
    units,
  });

export type RootState = ReturnType<StateType<typeof appStateReducers>>;

// TODO reset state on logout
export const rootReducerFactory = appStateReducers;

export function rootSagaFactory(
  authAPI: AuthService,
  portalAPI: PortalService,
  registrationAPI: RegistrationService
) {
  return function* () {
    yield all([
      fork(accountsSaga, portalAPI),
      fork(authSaga, authAPI),
      fork(contactSaga, portalAPI),
      fork(documentsSaga, portalAPI),
      fork(forgotPasswordSaga, authAPI),
      fork(metadataSaga, portalAPI),
      fork(newsSaga, portalAPI),
      fork(portalSaga, portalAPI),
      fork(propertiesSaga, portalAPI),
      fork(registrationSaga, registrationAPI),
      fork(sessionSaga, authAPI, portalAPI, registrationAPI),
      fork(tenantsSaga, portalAPI),
      fork(userProfilesSaga, portalAPI),
    ]);
  };
}
