import { call, fork, put, takeLeading } from "redux-saga/effects";
import { getType } from "typesafe-actions";

import { AnyAction } from "../actions";
import { doForgotSubmit } from "../actions/auth";
import { AuthService } from "../lib/api/auth";
import { RootState } from "./";
import { FormSubmitStatus } from "./types";

interface IForgotState {
  error?: Error;
  status: FormSubmitStatus;
  username?: string;
}

const initialState: IForgotState = {
  status: FormSubmitStatus.NotSubmitted,
};

export const forgotReducer = (
  state: IForgotState = initialState,
  action: AnyAction
): IForgotState => {
  switch (action.type) {
    case getType(doForgotSubmit.request):
      return {
        status: FormSubmitStatus.Submitting,
        username: action.payload.username,
      };
    case getType(doForgotSubmit.success):
      return Object.assign({}, state, { status: FormSubmitStatus.Submitted });
    case getType(doForgotSubmit.failure):
      return Object.assign({}, state, {
        error: action.payload,
        status: FormSubmitStatus.Failure,
      });
    default:
      return state;
  }
};

export const selectForgotFormError = (state: RootState) =>
  state.forgot ? state.forgot.error : undefined;
export const selectForgotFormStatus = (state: RootState) =>
  state.forgot ? state.forgot.status : FormSubmitStatus.NotSubmitted;
export const selectForgotFormUsername = (state: RootState) =>
  state.forgot ? state.forgot.username : undefined;

export function* forgotPasswordSaga(authAPI: AuthService) {
  function* handleForgotPassword(
    req: ReturnType<typeof doForgotSubmit.request>
  ) {
    try {
      yield call(authAPI.forgotPasswordRequest, req.payload.username);
      yield put(doForgotSubmit.success());
    } catch (err) {
      yield put(doForgotSubmit.failure(err));
    }
  }

  function* watchForgotPasswordRequest() {
    yield takeLeading(getType(doForgotSubmit.request), handleForgotPassword);
  }

  yield fork(watchForgotPasswordRequest);
}
