import grey from "@material-ui/core/colors/grey";
import Grid, { GridProps } from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { makeStyles, Theme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import React from "react";

import { buildUnitAddress } from "../../types/units";
import nl2br from "../../utils/nl2br";
import { PropTypes } from "./container";

const useHelmetStyles = makeStyles((theme: Theme) => ({
  item: {
    backgroundColor: "#fff",
    border: "1px solid #ccc",
    padding: theme.spacing(1),
    margin: `8px -${theme.spacing(1)}px`,
  },
  noneAvailable: {
    color: grey[700],
  },
}));

export const Helmet: React.FC<PropTypes> = ({ property, unit }) => {
  const classes = useHelmetStyles();
  const showHelmetVertically = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );

  if (!unit || !property) {
    return null;
  }

  const Item: React.FunctionComponent<
    React.PropsWithChildren<{ title: string } & GridProps>
  > = ({ title, children, ...props }) => (
    <Paper
      variant="outlined"
      component={Grid}
      item
      className={classes.item}
      {...props}
    >
      <strong>{title}:</strong>
      <br />
      {children}
    </Paper>
  );

  const siteContacts = property.siteInfo ? (
    nl2br(property.siteInfo)
  ) : (
    <span className={classes.noneAvailable}></span>
  );

  const containerDirection = showHelmetVertically ? "column" : "row";

  return (
    <Grid
      container
      justify="space-evenly"
      spacing={showHelmetVertically ? 0 : 2}
      direction={containerDirection}
    >
      <Grid container item direction="column" xs={12} md={6}>
        <Item xs title="Property name">
          {buildUnitAddress(unit.address)}
        </Item>
        <Item xs title="Property manager">
          {property.managerName}
          <br />
          {property.managerPhone}
        </Item>
      </Grid>
      <Grid container item direction="column" xs={12} md={6}>
        <Item xs title="Site contacts">
          {siteContacts}
        </Item>
      </Grid>
    </Grid>
  );
};
